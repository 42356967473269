import {
  SizeRestriction,
  ComparisonRestriction,
  Orientation,
  DeviceRestriction,
  Devices,
} from 'config-type'
import { IBreakpoints } from 'utils/breakpoints'

export function screenSizeIsAllowed(
  restrictedScreens: SizeRestriction[] | undefined,
  windowStoreHeight: number,
  windowStoreWidth: number
) {
  if (restrictedScreens && restrictedScreens.length > 0) {
    return restrictedScreens?.every((size) => {
      if (size.restriction === ComparisonRestriction.greaterThan) {
        const { height = 9999, width = 9999 } = size
        if (windowStoreHeight > height || windowStoreWidth > width) {
          return false
        }
      }

      if (size.restriction === ComparisonRestriction.smallerThan) {
        const { height = 0, width = 0 } = size
        if (windowStoreHeight <= height || windowStoreWidth <= width) {
          return false
        }
      }
      return true
    })
  }
  return true
}

function _orientationIsAllowed(
  orientation: Orientation | undefined,
  windowStoreHeight: number,
  windowStoreWidth: number
) {
  if (!orientation) {
    return false
  }

  if (orientation === Orientation.portrait) {
    if (windowStoreWidth < windowStoreHeight) {
      return false
    }
  }

  if (orientation === Orientation.landscape) {
    if (windowStoreWidth > windowStoreHeight) {
      return false
    }
  }

  return true
}

export function deviceOrientationIsAllowed(
  devices: DeviceRestriction[] | undefined,
  breakpoints: IBreakpoints,
  windowStoreHeight: number,
  windowStoreWidth: number
) {
  if (devices && devices.length > 0) {
    return devices.every(({ device, orientation }) => {
      if (breakpoints.phone && device === Devices.phone) {
        if (
          _orientationIsAllowed(
            orientation,
            windowStoreHeight,
            windowStoreWidth
          )
        ) {
          return true
        }
        return false
      }

      if (breakpoints.tablet && device === Devices.tablet) {
        if (
          _orientationIsAllowed(
            orientation,
            windowStoreHeight,
            windowStoreWidth
          )
        ) {
          return true
        }
        return false
      }

      if (breakpoints.desktop && device === Devices.desktop) {
        if (
          _orientationIsAllowed(
            orientation,
            windowStoreHeight,
            windowStoreWidth
          )
        ) {
          return true
        }
        return false
      }
      return true
    })
  }

  return true
}
