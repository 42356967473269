import InteractiveBackground from '../../Subcontent/InteractiveBackground'
import { ImageContainer } from './ImageContainer'
import { ContentItemGalleryDocument } from '../../../generated/types/eschertemplate.types'
import BottomBar from '../../Subcontent/BottomBar'
import React from 'react'
import useAppStateStore from '../../../hooks/useAppStateStore'
import { getResponsiveImage } from '../../../utils/imageUtils'

interface GalleryBackgroundProps {
  contentItemGallery: ContentItemGalleryDocument
  isTextPositionLeft?: boolean
}

const GalleryBackground = ({
  contentItemGallery,
  isTextPositionLeft,
}: GalleryBackgroundProps) => {
  const { updateState } = useAppStateStore()

  const activateFullScreen = () => {
    updateState('openedModalUid', contentItemGallery.uid)
    updateState('openedModalType', contentItemGallery.type)
  }

  let content = null

  const responsiveImage = getResponsiveImage(
    contentItemGallery.data.body[0]?.items[0].image
  )

  if (
    contentItemGallery?.data.body &&
    contentItemGallery?.data.body.length > 0
  ) {
    content = <ImageContainer img={responsiveImage}></ImageContainer>
  }

  return (
    <InteractiveBackground
      activateCallback={activateFullScreen}
      showBackgroundOverlay={true}
    >
      {content}
      <BottomBar
        desktopTextPositionLeft={isTextPositionLeft}
        contentItem={contentItemGallery}
        handleClick={activateFullScreen}
      ></BottomBar>
    </InteractiveBackground>
  )
}

export default GalleryBackground
