import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { ContentItemVideoDocument } from '../../../generated/types/eschertemplate.types'
import { FilledLinkToWebField } from '@prismicio/types'
import VideoPlayer from '../../video/VideoPlayer'
import { breakpoints } from '../../../utils/breakpoints'

interface VideoLoopInlineProps {
  contentItemVideo: ContentItemVideoDocument
}

const VideoLoopInline = ({ contentItemVideo }: VideoLoopInlineProps) => {
  const videoUrl = breakpoints.phone
    ? (contentItemVideo.data.mobile_url_video as FilledLinkToWebField).url
    : (contentItemVideo.data.desktop_url_video as FilledLinkToWebField).url

  const [inView, setInView] = useState(false)

  return (
    <InView threshold={0.01} onChange={(view) => setInView(view)}>
      <VideoPlayer
        url={videoUrl}
        alt={`video-${contentItemVideo.data.title ?? ''}}`}
        playing={inView}
        objectFit={'cover'}
        loop={true}
        hideControls={true}
      />
    </InView>
  )
}

export default VideoLoopInline
