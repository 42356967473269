import DetailArea from './components/ModalDetailArea'
import { ModalMainArea } from './components/ModalMainArea'
import ButtonBar from './components/ModalButtons'
import { useRef } from 'react'
import { FilledLinkToWebField } from '@prismicio/types'
import { ContentItemVideoDocument } from '../../generated/types/eschertemplate.types'
import { breakpoints } from '../../utils/breakpoints'
import VideoPlayer from '../video/VideoPlayer'

interface VideoModalProps {
  contentItem: ContentItemVideoDocument
  onClose: () => void
}

const VideoModal = ({ contentItem, onClose }: VideoModalProps) => {
  const modalMainRef = useRef<HTMLDivElement>(null)
  const videoUrl = breakpoints.phone
    ? (contentItem.data.mobile_url_video as FilledLinkToWebField).url
    : (contentItem.data.desktop_url_video as FilledLinkToWebField).url
  return (
    <ModalMainArea variant="row">
      <div style={{ height: 1 }} ref={modalMainRef}></div>
      <ButtonBar
        title={contentItem.data.title ?? ''}
        onClose={onClose}
      ></ButtonBar>
      <VideoPlayer
        url={videoUrl}
        alt={`${contentItem.data.title}-video`}
        muted={false}
        playing={true}
        objectFit={'contain'}
      />
      <DetailArea
        leadText={contentItem.data.lead_text ?? ''}
        title={contentItem.data.title ?? ''}
        subtitle={contentItem.data.subtitle ?? ''}
        paragraphs={contentItem.data.text ?? []}
        caption={contentItem.data.caption ?? ''}
      />
    </ModalMainArea>
  )
}

export default VideoModal
