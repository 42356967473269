import ReactPlayer, { ReactPlayerProps } from 'react-player'
import screenfull from 'screenfull'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { OnProgressProps } from 'react-player/base'
import { devices } from '../../utils/styles'
import { isTouchDevice } from '../../utils/breakpoints'

interface VideoPlayerProps extends ReactPlayerProps {
  videoLoadedCallback?: () => void
  icons?: {
    play?: string
    stop?: string
    volume?: string
    mutedVolume?: string
    fullscreen?: string
  }
}

const formatVideoTime = (seconds: number): string => {
  const formatAsTwoDigits = (num: number) => num.toString().padStart(2, '0')
  const totalSeconds = Math.round(seconds)
  const minutes = Math.floor(totalSeconds / 60)
  const secs = totalSeconds % 60
  return `${formatAsTwoDigits(minutes)}:${formatAsTwoDigits(secs)}`
}

const TIMEOUT_TIME = 3000

export default function CustomReactPlayer(props: VideoPlayerProps) {
  // Main reason for spread is that we dont want to pass all the props to the ReactPlayer to avoid errors/warnings in console
  const { hideControls, videoLoadedCallback, objectFit, ...restProps } = props

  const [playing, setPlaying] = useState<boolean>(restProps.playing ?? false)
  const [played, setPlayed] = useState<number>(0)
  const [elapsedTime, setElapsedTime] = useState<number>(0)
  const [totalDuration, setTotalDuration] = useState<number>(0)
  const [showControls, setShowControls] = useState(false)

  const playerRef = useRef<ReactPlayer>(null)
  const playerWrapperRef = useRef<HTMLDivElement>(null)

  const [playerVolume, setVolume] = useState<number>(
    restProps.muted === false ? 1 : 0
  )
  // Most of the browsers doesnt start autoplay without user interaction if the video is not muted
  const [isMuted, setIsMuted] = useState<boolean>(restProps.muted ?? true)
  const [previousVolume, setPreviousVolume] = useState<number>(1)
  const [controlTimeout, setControlTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const [
    fullscreenScrollPosition,
    setFullscrrenScrollPosition,
  ] = useState<number>(0)

  useEffect(() => {
    return () => {
      if (controlTimeout) {
        clearTimeout(controlTimeout)
      }
    }
  }, [controlTimeout])

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.onchange(scrollToSavedPosition)
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', scrollToSavedPosition)
      }
    }

    //Recommended deps not really necessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullscreenScrollPosition])

  const scrollToSavedPosition = () => {
    window.scrollTo({
      top: fullscreenScrollPosition,
      behavior: 'auto',
    })
  }

  useEffect(() => {
    const playerElement = playerWrapperRef.current

    if (playerElement) {
      playerElement.addEventListener(
        'mousemove',
        toggleControlsVisibilityWithDelay
      )
    }

    return () => {
      if (playerElement) {
        playerElement.removeEventListener(
          'mousemove',
          toggleControlsVisibilityWithDelay
        )
      }
      if (controlTimeout) {
        clearTimeout(controlTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerWrapperRef])

  useEffect(() => {
    if (screenfull.isFullscreen) {
      return
    }

    setPlaying(!!restProps.playing)
  }, [restProps.playing])

  const handleMouseEnter = () => {
    setShowControls(true)
  }

  const handleMouseLeave = () => {
    setShowControls(false)
  }

  const handleClickOnPlayer = () => {
    if (isTouchDevice) {
      toggleControlsVisibilityWithDelay()
      return
    }

    setPlaying((prevPlaying) => !prevPlaying)
  }

  const handleProgress = (state: OnProgressProps) => {
    setPlayed(state.played)
    setElapsedTime(state.playedSeconds)
  }

  const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value)
    setVolume(newVolume)
    toggleControlsVisibilityWithDelay()

    if (isMuted && newVolume > 0) {
      setIsMuted(false)
    } else if (!isMuted && newVolume === 0) {
      setIsMuted(true)
    }
  }

  const toggleMute = () => {
    setIsMuted(!isMuted)

    if (!isMuted) {
      setPreviousVolume(playerVolume)
      setVolume(0)
    } else {
      setVolume(previousVolume)
    }
  }

  const togglePlayPause = () => {
    toggleControlsVisibilityWithDelay()
    setPlaying((prevPlaying) => !prevPlaying)
  }

  const toggleFullscreen = () => {
    const isIOS = /iPhone/.test(window.navigator.userAgent)

    if (isIOS) {
      playerRef?.current?.getInternalPlayer()?.webkitEnterFullScreen()
      return
    }

    if (screenfull.isEnabled) {
      if (!screenfull.isFullscreen) {
        setFullscrrenScrollPosition(window.scrollY)
      }

      if (!playerWrapperRef.current) {
        return
      }

      void screenfull.toggle(playerWrapperRef.current)
    }
  }
  const volumeIconUrl =
    isMuted || playerVolume === 0
      ? restProps?.icons?.mutedVolume ?? ''
      : restProps?.icons?.volume ?? ''

  const toggleControlsVisibilityWithDelay = () => {
    setShowControls(true)
    if (controlTimeout) {
      clearTimeout(controlTimeout)
    }
    const newTimeout = setTimeout(() => {
      setShowControls(false)
    }, TIMEOUT_TIME)
    setControlTimeout(newTimeout)
  }

  return (
    <CustomPlayerWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={playerWrapperRef}
      data-cy={playing ? 'videoPlaying' : 'videoPaused'}
    >
      <ReactPlayer
        {...restProps}
        playsinline
        className="video-player"
        ref={playerRef}
        playing={playing}
        muted={isMuted}
        volume={playerVolume}
        onProgress={handleProgress}
        onDuration={(duration) => setTotalDuration(duration)}
        onBufferEnd={() => (videoLoadedCallback ? videoLoadedCallback() : null)}
        onClick={handleClickOnPlayer}
        width="100%"
        height={objectFit === 'cover' ? '100vh' : '100%'}
        controls={false}
      />
      {!hideControls && (
        <Controls
          $showControls={showControls}
          $objectFit={objectFit}
          $isFullscreen={screenfull.isFullscreen}
        >
          <RangeInput
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onChange={(e) =>
              playerRef.current?.seekTo(parseFloat(e.target.value))
            }
            $played={`${played * 100}%`}
          />
          <ControlButtons>
            <LeftControls>
              <PlayButton onClick={togglePlayPause}>
                <img
                  src={
                    playing
                      ? restProps?.icons?.stop ?? '⏹'
                      : restProps?.icons?.play ?? '▶'
                  }
                  alt={playing ? 'stop-button' : 'play-button'}
                  width={24}
                  height={24}
                />
              </PlayButton>
              <Time>
                <span>{formatVideoTime(elapsedTime)}</span>
                {'\u00A0'}/{'\u00A0'}
                <span>{formatVideoTime(totalDuration)}</span>
              </Time>
            </LeftControls>

            <RightControls>
              <VolumeControl>
                <VolumeInput
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={playerVolume}
                  onChange={handleVolumeChange}
                  $played={`${playerVolume * 100}%`}
                />
                <img
                  src={volumeIconUrl}
                  onClick={toggleMute}
                  height={24}
                  width={24}
                  alt={isMuted ? 'muted-icon' : 'not-muted-icon'}
                />
              </VolumeControl>
              <Button
                onClick={toggleFullscreen}
                onTouchStart={toggleFullscreen}
              >
                <img
                  src={restProps?.icons?.fullscreen ?? '⛶'}
                  alt={'fullscreen-toggle'}
                />
              </Button>
            </RightControls>
          </ControlButtons>
        </Controls>
      )}
    </CustomPlayerWrapper>
  )
}

const CustomPlayerWrapper = styled.div`
  position: relative;
  width: 100%;

  &:focus {
    outline: none;
  }

  video:focus {
    outline: none;
  }

  -webkit-tap-highlight-color: transparent;
`

const Controls = styled.div<{
  $showControls: boolean
  $objectFit: 'cover' | 'contain' | undefined
  $isFullscreen: boolean
}>`
  position: ${({ $objectFit, $isFullscreen }) =>
    $isFullscreen
      ? 'absolute'
      : $objectFit === 'cover'
      ? 'absolute'
      : 'sticky'};
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  height: 64px;
  opacity: ${({ $showControls }) => ($showControls ? 1 : 0)};
  transition: opacity 0.5s ease;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
  background: none;
  border: none;
`
const RangeInput = styled.input.attrs({ type: 'range' })<{ $played: string }>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.palette.contrast} ${({ $played }) => $played},
      #a3a3a3 ${({ $played }) => $played}
    );

    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    width: 0;
    height: 0;
    cursor: pointer;
  }

  &::-moz-range-track {
    width: 100%;
    height: 7px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.palette.contrast} ${({ $played }) => $played},
      #a3a3a3 ${({ $played }) => $played}
    );
    cursor: pointer;
  }

  &::-moz-range-thumb {
    background: transparent;
    border: 0;
  }
`

const VolumeInput = styled(RangeInput)`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media ${devices.mobile} {
    display: none;
  }
`

const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  padding: 16px;

  &:hover {
    ${VolumeInput} {
      opacity: 1;
      visibility: visible;
    }
  }
`

const ControlButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const LeftControls = styled(FlexCenter)`
  gap: 16px;
`

const RightControls = styled(FlexCenter)``

const PlayButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`

const Time = styled.div`
  display: flex;
`
