import styled, { keyframes } from 'styled-components/macro'
import { IconTypes } from '../../../data/prismic-enums'
import { OverlayIcon } from '../../Modals/components/ModalButtons'
import { devices } from 'utils/styles'
import usePrismicData from '../../../data/usePrismicData'

interface Props {
  onClick: () => void
}

export const IconDown = ({ onClick }: Props) => {
  const { useIcons } = usePrismicData()
  const icons = useIcons()

  return (
    <StyledOverlayIcon>
      <img
        src={icons.data?.data[IconTypes.ARROW_DOWN]?.url ?? ''}
        alt="scroll-down"
        onClick={onClick}
      />
    </StyledOverlayIcon>
  )
}

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0)
  }
  50% {
    transform: translateY(-10px)
  }
  
`

const StyledOverlayIcon = styled(OverlayIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  position: fixed;
  bottom: 15vh;
  right: 48px;
  animation: ${bounce} 2s ease-in-out infinite;

  img {
    width: 40px;
    height: 40px;
  }

  @media ${devices.mobile} {
    right: 16px;
    bottom: 15vh;
  }
`
