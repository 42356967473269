import { darken } from '@mui/material'
import styled from 'styled-components/macro'

const CircleButton = styled.button`
  width: 40px !important;
  height: 40px !important;
  min-width: 40px;
  border-radius: 50%;
  background: ${(p) => p.theme.palette.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  outline: none;

  &:hover {
    background: ${(p) => darken(p.theme.palette.primary.dark, 0.1)};
  }
`

export default CircleButton
