import styled from 'styled-components/macro'
import { devices } from 'utils/styles'

export const ModalMainArea = styled.div<{
  variant: 'column' | 'row'
  overflow?: 'scroll' | 'hidden'
}>`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: ${(p) => p.variant};
  overflow-y: ${(p) => p.overflow ?? 'hidden'} ;
  width: 100vw;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${devices.mobile} {
    flex-direction: column;
    overflow: scroll;
  },
`
