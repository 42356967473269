import { useState, useEffect, RefObject } from 'react'

/**
 * How it works:
 * - When the user scrolls the page, the hook checks the position of the element (referred by `ref`)
 *   relative to the viewport.
 * - It then calculates a value between 0 and 1, which represents where the element is in the
 *   viewport. A value of 0 means the element just appeared at the bottom of the viewport, and 1 means
 *   it's about to exit from the top.
 * - This value is calculated in a way that it adjusts to the size of the element and the viewport,
 *
 * @returns {number} interpolatedScrollPosition - A number between 0 and 1 representing the
 *                                                normalized scroll position of the element.
 */
const useInterpolatedScrollPosition = (
  ref: RefObject<HTMLDivElement>,
  acceptanceCondition: boolean = true
): number => {
  const [interpolatedScrollPosition, setInterpolatedScrollPosition] = useState(
    0
  )

  useEffect(() => {
    if (!acceptanceCondition) {
      return
    }

    const handleScroll = () => {
      if (ref.current) {
        // height of the container from the top of the page
        const rect = ref.current.getBoundingClientRect()

        /**
         * height of the container itself without the viewport height.
         * viewport height is deducted because otherwise we would get 1 in interpolation after the whole element leaves the viewport
         * we want to end up lottie animation when the element is at the bottom of the viewport
         */
        const containerHeight = ref.current.offsetHeight - window.innerHeight
        const interpolatedValue = linearInterpolation(
          rect.top,
          0,
          -containerHeight
        )
        setInterpolatedScrollPosition(interpolatedValue)
      }
    }

    /**
     * Performs linear interpolation to map a value from one range to another.
     *
     * This function is used to normalize a value `x` to a range between 0 and 1,
     * based on its position within a given range [xMin, xMax].
     *
     * @param {number} x - The current value to be normalized. In the context of scrolling,
     *                     this represents the position of an element relative to the viewport.
     * @param {number} xMin - The minimum value of the input range. For scrolling effects,
     *                        this is the start point of the transition (e.g., when an element
     *                        enters the viewport).
     * @param {number} xMax - The maximum value of the input range. This is the end point of
     *                        the transition in scrolling effects (e.g., when an element leaves
     *                        the viewport).
     *
     * The formula `(x - xMin) / (xMax - xMin)` maps the value `x` from the range [xMin, xMax]
     * to [0, 1]. It adjusts the scale of `x` such that `xMin` aligns to 0 and `xMax` aligns to 1.
     *
     * The result is then "clamped" to ensure it stays within the 0 to 1 range:
     * - `Math.max(value, 0)`: Ensures the result is not less than 0.
     * - `Math.min(value, 1)`: Ensures the result does not exceed 1.
     */
    const linearInterpolation = (x: number, xMin: number, xMax: number) => {
      return Math.min(Math.max((x - xMin) / (xMax - xMin), 0), 1)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [ref, acceptanceCondition])

  return interpolatedScrollPosition
}

export default useInterpolatedScrollPosition
