import React from 'react'
import { Helmet } from 'react-helmet'
import usePrismicData from '../../data/usePrismicData'
import { useLang } from '../../providers/LanguageProvider'
import { SeoDocumentDataSeoKeywordsItem } from '../../generated/types/eschertemplate.types'

const SeoFavicons = () => {
  const { useSEO } = usePrismicData()
  const { lang } = useLang()
  const SEO = useSEO()
  const projectName = process.env.REACT_APP_PROJECT_NAME

  // Use static paths instead of dynamic imports
  const favIconPath = `/favicons/${projectName}/favicon.ico`
  const webManifestPath = `/favicons/${projectName}/site.webmanifest`

  const keywords = (SEO.data?.data
    .seo_keywords as SeoDocumentDataSeoKeywordsItem[])
    ?.map((keyword) => keyword.keyword)
    .join(', ')

  return (
    <Helmet>
      <link rel="icon" href={favIconPath} />
      <link rel="manifest" href={webManifestPath} />
      <title>{SEO.data?.data.seo_title ?? 'Docmine videobook'}</title>
      <meta
        name="description"
        content={SEO.data?.data.seo_description ?? 'Docmine videobook'}
      />
      <meta http-equiv="content-language" content={lang} />
      {keywords?.length > 0 && <meta name="keywords" content={keywords} />}
    </Helmet>
  )
}

export default SeoFavicons
