import React from 'react'
import usePrismicData from '../../data/usePrismicData'
import { LegalMainArea } from './components/LegalMainArea'

const TermsOfUse = () => {
  const { useTermsOfUseQuery } = usePrismicData()
  const termsOfUse = useTermsOfUseQuery()

  if (termsOfUse.isLoading || !termsOfUse.data) return null
  return <LegalMainArea legalData={termsOfUse.data} />
}

export default TermsOfUse
