import styled from 'styled-components/macro'
import { appConfig } from '../../config'
import fallBackIcon from './FallbackIcon.svg'
interface Props {
  thumbnail?: string
  iconAlt?: string | null
  iconUrl?: string | null
  padding?: string | number
}
export default function ThumbnailsWithIcon({
  thumbnail,
  iconAlt,
  iconUrl,
  padding = 25,
}: Props) {
  return (
    <ThumbnailWithIconWrapper>
      {thumbnail && appConfig.showThumbnailUnderIcon && (
        <Thumbnail src={thumbnail} alt={iconAlt ?? ''}></Thumbnail>
      )}
      <Icon
        style={{ padding: padding }}
        alt={iconAlt ?? ''}
        src={iconUrl ?? fallBackIcon}
      ></Icon>
    </ThumbnailWithIconWrapper>
  )
}

const ThumbnailWithIconWrapper = styled.div`
  position: relative;
  height: 100%;
  aspect-ratio: 1/1;
`

const Thumbnail = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`
const Icon = styled.img`
  position: absolute;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
