import React, { Suspense } from 'react'
import { IToggleItem } from 'components/atoms/Toggle'
import { appConfig } from 'config'
import { useLang } from '../../providers/LanguageProvider'
import LoadingSpinner from '../atoms/loadingSpinner'
import usePrismicData from '../../data/usePrismicData'
import { getLinkFieldUrl } from '../../data/prismic-helper'

const CenterAlignedIntroComponent = React.lazy(
  async () => import('./CenterAlignedIntroComponent')
)
const RightAlignIntroComponent = React.lazy(
  async () => import('./RightAlignIntroComponent')
)

const Intro = () => {
  const { lang, setLang } = useLang()
  const { useIntroQuery } = usePrismicData()
  const introData = useIntroQuery()

  const onSwitchLanguage = (selectedItem: IToggleItem) => {
    setLang(selectedItem.value)
  }

  if (introData.isLoading) {
    return <LoadingSpinner />
  }

  const {
    background_video_loop_desktop,
    background_video_loop_mobile,
    language_selection,
    secondary_title,
    title,
    start_button_text,
    background_image,
    top_title,
    marketing_icons,
    intro_fullscreen_video_desktop,
    intro_fullscreen_video_mobile,
    image_effect_type,
  } = introData.data?.data ?? {}

  const IntroComponent =
    appConfig.templateConfig?.Intro?.version === 'RightAligned'
      ? RightAlignIntroComponent
      : CenterAlignedIntroComponent

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <IntroComponent
        languageSelectionPhrase={language_selection ?? ''}
        topTitle={top_title ?? ''}
        marketingIcons={marketing_icons ?? []}
        secondaryTitle={secondary_title ?? ''}
        title={title ?? ''}
        startButtonText={start_button_text ?? ''}
        backgroundImage={background_image?.url ?? ''}
        languages={appConfig.languages}
        language={lang}
        onSwitchLanguage={onSwitchLanguage}
        backgroundVideoLoopDesktop={getLinkFieldUrl(
          background_video_loop_desktop
        )}
        backgroundVideoLoopMobile={getLinkFieldUrl(
          background_video_loop_mobile
        )}
        introFullscreenVideoDesktop={getLinkFieldUrl(
          intro_fullscreen_video_desktop
        )}
        introFullscreenVideoMobile={getLinkFieldUrl(
          intro_fullscreen_video_mobile
        )}
        imageEffectType={image_effect_type ?? 'none'}
      />
    </Suspense>
  )
}

export default Intro
