import styled from 'styled-components/macro'
import DetailArea from './components/ModalDetailArea'
import { ModalMainArea } from './components/ModalMainArea'
import { devices } from 'utils/styles'
import ButtonBar from './components/ModalButtons'
import React, { useRef } from 'react'
import { ContentItemTextDocument } from '../../generated/types/eschertemplate.types'
import { ImageContainer } from '../Subchapter/SubchapterBackgrounds/ImageContainer'
import { ImageField, ImageFieldImage } from '@prismicio/types'

interface TextModalProps {
  contentItem: ContentItemTextDocument
  onClose: () => void
}

const TextModal = ({ contentItem, onClose }: TextModalProps) => {
  const modalMainRef = useRef<HTMLDivElement>(null)

  function getImage(
    image: ImageField<'modal_cover'> | undefined
  ): ImageFieldImage | undefined {
    if (image?.modal_cover?.url) {
      return image.modal_cover
    } else {
      return image
    }
  }

  return (
    <ModalMainArea variant="column" overflow="scroll">
      <div style={{ height: 1 }} ref={modalMainRef}></div>
      <ButtonBar
        title={contentItem.data.title ?? ''}
        onClose={onClose}
        hideDescriptionAndDetails={true}
      ></ButtonBar>
      {contentItem.data.background_image.url && (
        <ImageArea data-cy="textModalImageArea">
          <ImageContainer
            img={getImage(contentItem.data.background_image)}
          ></ImageContainer>
        </ImageArea>
      )}
      <TextArea>
        <DetailArea
          modalMainRef={modalMainRef}
          leadText={contentItem.data.lead_text ?? ''}
          title={contentItem.data.title ?? ''}
          subtitle={contentItem.data.subtitle ?? ''}
          paragraphs={contentItem.data.text ?? []}
          caption={contentItem.data.caption ?? ''}
          alwaysopen={true}
          extraPadding={!contentItem.data.background_image.url}
        ></DetailArea>
      </TextArea>
    </ModalMainArea>
  )
}

export default TextModal

const ImageArea = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 auto;
  -webkit-transform: translateZ(1);
  -webkit-backface-visibility: hidden;
  height: 30%;
  width: 100%;
  background-color: ${(p) => p.theme.palette.background};

  @media ${devices.mobile} {
    height: 75%;
  },
`

const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  padding-top: 50px;
  background-color: ${(p) => p.theme.palette.background};
  justify-content: center;

  overflow-y: visible;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${devices.mobile} {
    padding-top: 0px;
    overflow: visible;
  }

  ,
  > * {
    width: 100%;
    max-width: 640px;
  }
`
