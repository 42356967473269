import styled from 'styled-components/macro'
import { devices } from '../../../utils/styles'
import Typography from 'components/atoms/Typography'

interface Props {
  chapterCoverText: string
}
export const ChapterCoverText = ({ chapterCoverText }: Props) => {
  return (
    <Wrapper data-cy="chapterCoverText">
      <Typography variant="lead">{chapterCoverText}</Typography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 65vh auto;

  @media ${devices.mobile} {
    max-width: 90%;
    text-align: center;
  }
`
