import styled from 'styled-components/macro'
import { IconTypes } from '../../../data/prismic-enums'
import Typography from '../../atoms/Typography'
import { devices } from '../../../utils/styles'
import { Skeleton } from '@mui/material'
import usePrismicData from '../../../data/usePrismicData'
import {
  ContentItemResponseTypes,
  ContentItemResponseTypesWithoutScrollymationAndScrollyStory,
  ContentItemTypes,
} from '../../../data/prismic-types'
import { QueryObserverResult } from '@tanstack/react-query'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../../data/prismic-helper'
import { replaceFilename } from '../../../utils/string'
import { FilledLinkToMediaField } from '@prismicio/types'
import useAppStateStore from '../../../hooks/useAppStateStore'
import ImageLoader from '../../common/imageLoader'

interface KbContentGalleryProps {
  contentItems: QueryObserverResult<
    ContentItemResponseTypesWithoutScrollymationAndScrollyStory[]
  >
  activeCategory?: string | null
  allStories?: string | null
}

const KbContentGallery = ({
  contentItems,
  activeCategory,
  allStories,
}: KbContentGalleryProps) => {
  const { updateState } = useAppStateStore()
  const { useIcons, useUIelements } = usePrismicData()
  const uiElements = useUIelements()
  const icons = useIcons()
  const types = getUiElementsSliceData(
    SliceTypeEnum.CONTENT_ITEM_NAMES,
    uiElements.data
  )

  const activateFullScreen = (uid: string, type: ContentItemTypes) => {
    updateState('detailsOpen', true)
    updateState('openedModalUid', uid)
    updateState('openedModalType', type)
  }

  function filterByCategory(item: any) {
    // If category is all stories and item has a category name, return true
    if (activeCategory === allStories && item.data.category.data?.name) {
      return true
    }
    // otherwise show only items with the same category as the active category.
    return item.data.category.data?.name === activeCategory
  }
  function thumbnailImage(item: ContentItemResponseTypes) {
    if (item.data.thumbnail_image?.url) {
      return (
        <Thumbnail
          data-cy="contentGalleryThumbnail"
          src={item.data.thumbnail_image?.url}
          alt={item.data.thumbnail_image?.alt ?? 'Image thumbnail'}
          size={'md'}
          loading={'lazy'}
        ></Thumbnail>
      )
    }

    if (item.type === 'content_item_video') {
      if ((item.data.desktop_url_video as FilledLinkToMediaField).url) {
        return (
          <Thumbnail
            data-cy="contentGalleryThumbnail"
            src={replaceFilename(
              (item.data.desktop_url_video as FilledLinkToMediaField).url,
              'firstframe.jpg'
            )}
          ></Thumbnail>
        )
      }
    }

    return (
      <img
        src={icons.data?.data[IconTypes.KNOWLEDGE]?.url ?? ''}
        alt="knowledge"
      />
    )
  }

  return (
    <ContentItemsContainer data-cy="contentItemsContainer">
      {contentItems.isLoading
        ? skeletonLoader()
        : contentItems.data
            ?.filter(filterByCategory)
            ?.map(
              (
                item: ContentItemResponseTypesWithoutScrollymationAndScrollyStory
              ) => (
                <SingleContentItem
                  data-cy={item.type}
                  key={item.id}
                  onClick={() => activateFullScreen(item.uid, item.type)}
                >
                  <ImageContainer data-cy="imageContainer">
                    {thumbnailImage(item)}
                  </ImageContainer>
                  <TextPart data-cy="textPart">
                    <ContentTypeText variant={'caption'} data-cy="caption">
                      {types?.primary[item.type] ?? item.type}{' '}
                      {item.data?.link_caption &&
                        `· ${item?.data.link_caption}`}
                    </ContentTypeText>
                    <ContentTitleText
                      variant={'head4'}
                      data-cy="contentTitleText"
                    >
                      {item.data.title}
                    </ContentTitleText>
                    <ContentDescriptionText variant={'body'}>
                      {item?.data.subtitle}
                    </ContentDescriptionText>
                  </TextPart>
                  <CircleButton>
                    <img
                      src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
                      alt="arrow"
                      loading="lazy"
                      height={
                        icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions
                          ?.height ?? 20
                      }
                      width={
                        icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions
                          ?.width ?? 20
                      }
                    />
                  </CircleButton>
                </SingleContentItem>
              )
            )}
    </ContentItemsContainer>
  )
}

const skeletonLoader = () => (
  <>
    <SingleContentItem style={{ border: 'none' }}>
      <ImageContainer>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </ImageContainer>
      <TextPart>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="100%" />
      </TextPart>
    </SingleContentItem>
    <SingleContentItem style={{ border: 'none' }}>
      <ImageContainer>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </ImageContainer>
      <TextPart>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="100%" />
      </TextPart>
    </SingleContentItem>
    <SingleContentItem style={{ border: 'none' }}>
      <ImageContainer>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </ImageContainer>
      <TextPart>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="100%" />
      </TextPart>
    </SingleContentItem>
  </>
)

const ContentItemsContainer = styled.div`
  width: 90%;
  max-width: 1500px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: auto;
  padding: 24px 0 200px 0;
`
const CircleButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 25px;
  right: 25px;
  background: ${(p) => p.theme.palette.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentTitleText = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';
  min-height: 60px;
  transition: margin-left 0.3s ease-in-out;
`

const SingleContentItem = styled.div`
  display: flex;
  flex: 0 0 350px;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: rgba(164, 191, 187, 0.05);
  height: 100%;
  opacity: 0;
  animation: fadeIn 1s forwards;
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  margin: auto;
  &:hover {
    transform: scale(1.02);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const ImageContainer = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Thumbnail = styled(ImageLoader)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
`

const TextPart = styled.div`
  position: relative;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  border: solid 1px #a4bfbb;
  border-top: none;
  border-radius: 0 0 4px 4px;
  height: 300px;
`

const ContentTypeText = styled(Typography)`
  margin-top: 24px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ContentDescriptionText = styled(Typography)`
  padding-top: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';

  @media ${devices.mobile} {
    -webkit-line-clamp: 4;
  }
`

export default KbContentGallery
