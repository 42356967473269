import { ButtonProps } from '@mui/material/Button'
import { darken } from '@mui/material/styles'
import styled from 'styled-components'
import Typography from './Typography'
import { devices } from 'utils/styles'

interface IButton extends ButtonProps {
  size: 'large' | 'medium' | 'small'
  variant?: 'contained' | 'outlined'
  $scaleOnHover?: boolean
}

// TODO
// allow icons
// disabled styling

const Button = (props: IButton) => {
  const { children } = props

  return (
    <StyledButton data-cy="button" {...props}>
      <Typography variant="body" component="span">
        {children}
      </Typography>
    </StyledButton>
  )
}

const StyledButton = styled.button<IButton>`
  color: #ffffff;
  padding: 0 22px;
  cursor: pointer;
  border-radius: ${(p) => p.theme.templates?.button?.radius ?? '2px'};
  font-size: 14px;
  pointer-events: initial;
  display: flex;
  align-items: center;
  background-color: ${(p) => {
    switch (p.variant) {
      case 'outlined':
        return 'transparent'
      case 'contained':
        return p.theme.palette.primary.dark
      default:
        return 'transparent'
    }
  }};

  border: ${(p) => {
    switch (p.variant) {
      case 'outlined':
        return '1px solid white'
      case 'contained':
        return `1px solid ${p.theme.palette.primary.dark}`
      default:
        return 'none'
    }
  }};

  min-height: ${(p) => {
    if (p.size === 'small') {
      return 32
    }
    if (p.size === 'medium') {
      return 41
    }
    if (p.size === 'large') {
      return 48
    }
  }}px;
  transition: 0.3s background, scale 150ms ease-in-out;

  &:hover {
    background-color: ${(p) => {
      switch (p.variant) {
        case 'outlined':
          return 'rgba(230, 191, 0, 0.16)'
        case 'contained':
          return darken(p.theme.palette.primary.dark, 0.1)
        default:
          return 'transparent'
      }
    }};
    scale: ${(p) => (p.$scaleOnHover ? '1.1' : '1')};
    transition: scale 150ms ease-in-out;
  }

  @media ${devices.mobile} {
    justify-content: center !important;
  }
`

export default Button
