import { usePrismicPreviewResolver } from '@prismicio/react'
import { useHistory, withRouter } from 'react-router-dom'
import { usePrismic } from '../../providers/PrismicClientProvider'

function Preview() {
  const history = useHistory()
  const prismicClient = usePrismic()

  const navigate = (url: string) => history.push(url)
  usePrismicPreviewResolver({
    navigate,
    // @ts-ignore
    client: prismicClient,
  })

  return null
}

export default withRouter(Preview)
