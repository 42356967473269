export interface CookieScriptInstance {
  show: () => void
}

declare global {
  interface Window {
    CookieScript: string
  }
}

declare const CookieScript: {
  instance: CookieScriptInstance
}

export function openCookieBanner() {
  if (process.env.REACT_APP_TRACKER_INJECTION && window.CookieScript) {
    CookieScript.instance.show()
  }
}
