import React, { useEffect, useState } from 'react'
import { ContentItemScrollystoryDocument } from '../../../generated/types/eschertemplate.types'
import { Helmet } from 'react-helmet'

interface ScrollyStoryBackgroundProps {
  contentItemScrollystory: ContentItemScrollystoryDocument
}

const ScrollyStoryBackground = ({
  contentItemScrollystory,
}: ScrollyStoryBackgroundProps) => {
  const [
    scrollyStoryElementAvailable,
    setScrollyStoryElementAvailable,
  ] = useState(false)

  useEffect(() => {
    if (contentItemScrollystory.data.element_name) {
      void customElements
        .whenDefined(contentItemScrollystory.data.element_name)
        .then(() => {
          console.log('resolved')
          setScrollyStoryElementAvailable(true)
        })
    }
  }, [contentItemScrollystory.data.element_name])

  if (!contentItemScrollystory.data.scrolly_story_url) {
    return null
  }

  return (
    <>
      <Helmet>
        <script
          type="module"
          async
          defer
          src={contentItemScrollystory.data.scrolly_story_url}
        />
      </Helmet>
      {scrollyStoryElementAvailable && (
        <div
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            __html: `<${contentItemScrollystory.data.element_name} scrollstoryid="${contentItemScrollystory.data.scrollystory_id}" lang="de-ch" />`,
          }}
        />
      )}
    </>
  )
}

export default ScrollyStoryBackground
