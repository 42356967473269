import React from 'react'
import VideoBackgroundInline from 'components/Subchapter/SubchapterBackgrounds/VideoBackgroundInline'
import ImageBackground from 'components/Subchapter/SubchapterBackgrounds/ImageBackground'
import GalleryBackground from 'components/Subchapter/SubchapterBackgrounds/GalleryBackground'
import TextBackground from 'components/Subchapter/SubchapterBackgrounds/TextBackground'
import ScrollytellingBackgroundNEW from './SubchapterBackgrounds/ScrollytellingBackgroundNew'
import {
  ContentItemGalleryDocument,
  ContentItemImageDocument,
  ContentItemScrollymationDocument,
  ContentItemScrollystoryDocument,
  ContentItemTextDocument,
  ContentItemVideoDocument,
  SubchapterDocument,
} from '../../generated/types/eschertemplate.types'
import { QueryObserverResult } from '@tanstack/react-query'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'
import ScrollyStoryBackground from './SubchapterBackgrounds/ScrollyStoryBackground'
import { VideoMode } from '../../data/prismic-enums'
import VideoStillBackground from './SubchapterBackgrounds/VideoStillBackground'
import VideoLoopInline from './SubchapterBackgrounds/VideoLoopInline'

interface SectionProps {
  subchapterQuery?: QueryObserverResult<SubchapterDocument<string>, unknown>
  contentItem?:
    | ContentItemImageDocument
    | ContentItemTextDocument
    | ContentItemGalleryDocument
    | ContentItemVideoDocument
    | ContentItemScrollymationDocument
    | ContentItemScrollystoryDocument
  interpolatedScrollPosition: number
}

const SectionStickyBackground = ({
  subchapterQuery,
  contentItem,
  interpolatedScrollPosition,
}: SectionProps) => {
  const isTextPositionLeft =
    subchapterQuery?.data?.data.text_position_variant === 'left'

  const getCorrectVideoVersion = (
    videoMode: VideoMode | undefined,
    contentItemVideo: ContentItemVideoDocument
  ) => {
    switch (videoMode) {
      case VideoMode.SUBCHAPTER_VIDEO_STILL:
        return (
          <VideoStillBackground
            isTextPositionLeft={isTextPositionLeft}
            contentItemVideo={contentItemVideo}
          />
        )
      case VideoMode.SUBCHAPTER_VIDEO_ENDLESS_MUTED_LOOP:
        return <VideoLoopInline contentItemVideo={contentItemVideo} />
      case VideoMode.SUBCHAPTER_VIDEO_FULLSCREEN_VIDEO:
        return <VideoBackgroundInline contentItemVideo={contentItemVideo} />
      default:
        return (
          <VideoStillBackground
            isTextPositionLeft={isTextPositionLeft}
            contentItemVideo={contentItemVideo}
          />
        )
    }
  }

  if (!contentItem || !subchapterQuery) {
    return null
  }

  if (contentItem.type === 'content_item_scrollymation') {
    return (
      <ScrollyBgContainer>
        <ScrollytellingBackgroundNEW
          contentItemScrollymation={contentItem}
          interpolatedScrollPosition={interpolatedScrollPosition}
        />
      </ScrollyBgContainer>
    )
  }

  if (contentItem.type === 'content_item_scrollystory') {
    return <ScrollyStoryBackground contentItemScrollystory={contentItem} />
  }

  return (
    <BgContainer>
      {contentItem.type === 'content_item_image' && (
        <ImageBackground
          isTextPositionLeft={isTextPositionLeft}
          contentItemImage={contentItem}
        />
      )}
      {contentItem.type === 'content_item_gallery' && (
        <GalleryBackground
          isTextPositionLeft={isTextPositionLeft}
          contentItemGallery={contentItem}
        />
      )}
      {contentItem.type === 'content_item_text' && (
        <TextBackground
          isTextPositionLeft={isTextPositionLeft}
          contentItemText={contentItem}
        />
      )}
      {contentItem.type === 'content_item_video' && (
        <>
          {getCorrectVideoVersion(
            subchapterQuery.data?.data.video_mode as VideoMode,
            contentItem
          )}
        </>
      )}
    </BgContainer>
  )
}

export default SectionStickyBackground

const BgContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  background-size: cover;
  background-position: center;
  will-change: scroll-position;
  width: 100%;
  height: 100vh;

  @media ${devices.mobile}, ${devices.tablet} {
    position: static;
    height: 100vh;
  }
`

const ScrollyBgContainer = styled(BgContainer)`
  @media ${devices.mobile} {
    position: sticky;
  }
`
