import styled from 'styled-components/macro'
import Typography from 'components/atoms/Typography'
import { devices } from '../../../../utils/styles'
import { EndOfVideobookDocumentDataBodyVideobooksSlice } from '../../../../generated/types/eschertemplate.types'
import { FilledLinkToWebField } from '@prismicio/types'
import usePrismicData from '../../../../data/usePrismicData'

interface Props {
  videobooksTitle: string
  videobooks: EndOfVideobookDocumentDataBodyVideobooksSlice[]
  readMore: string
}

export function VideoBooksList({
  videobooksTitle,
  videobooks,
  readMore,
}: Props) {
  const { useIcons } = usePrismicData()
  const icons = useIcons()

  return (
    <VideoBooksContainer>
      <Typography variant={'lead'} style={{ padding: '50px 0' }}>
        {videobooksTitle}
      </Typography>
      {videobooks.map((book) => {
        const bookUrl = (book.primary.link_to_videobook as FilledLinkToWebField)
          .url

        return (
          <VideoBook>
            <a href={bookUrl} target="__blank">
              <VideoBookPoster
                src={book.primary.thumbnail_videobook.url ?? ''}
                alt={book.primary.thumbnail_videobook.alt ?? ''}
                width={
                  book.primary.thumbnail_videobook.dimensions?.width ?? 250
                }
                height={
                  book.primary.thumbnail_videobook.dimensions?.height ?? 180
                }
                loading="lazy"
              />
            </a>
            <VideoBookTextContent className="text-content">
              <StyledTitle variant={'lead'}>
                {book.primary.videobook_title}
              </StyledTitle>
              <VideoBookDescription variant={'body'}>
                {book.primary.videobook_description}
              </VideoBookDescription>
              <a href={bookUrl} target="__blank">
                <VideoBookButton arrow={icons.data?.data.rightArrow.url ?? ''}>
                  <Typography variant={'interaction'}>{readMore}</Typography>
                </VideoBookButton>
              </a>
            </VideoBookTextContent>
          </VideoBook>
        )
      })}
    </VideoBooksContainer>
  )
}

const VideoBooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VideoBook = styled.div`
  display: flex;
  margin: 20px 0;

  .text-content {
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(0px);
  }

  &:hover .text-content {
    transform: translateX(5px);
  }

  @media ${devices.mobile} {
    flex-direction: column;
    margin: auto;
  }
`

const StyledTitle = styled(Typography)`
  @media ${devices.mobile} {
    margin: 16px 0 !important;
  }
`

const VideoBookPoster = styled.img`
  width: 250px;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  object-fit: contain;

  @media ${devices.mobile} {
    width: 100%;
    height: auto;
  }
`

const VideoBookTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 30px;
  max-width: 250px;

  @media ${devices.mobile} {
    padding-left: 2px;
    max-width: unset;
  }
`

const VideoBookButton = styled.div<{ arrow: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    content: url(${({ arrow }) => arrow});
    padding-right: 10px;
  }

  &:hover {
    border-bottom: 2px solid white;
    @media ${devices.mobile} {
      border-bottom: none;
    }
  }

  @media ${devices.mobile} {
    padding-top: 16px;
    padding-bottom: 32px;
  }
`

const VideoBookDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';
`
