import { useLang } from '../providers/LanguageProvider'
import { usePrismic } from '../providers/PrismicClientProvider'
import { useQuery } from '@tanstack/react-query'
import {
  CategoryDocument,
  ChapterDocument,
  EndOfVideobookDocument,
  IconsDocument,
  ImprintDocument,
  IntroPageDocument,
  KnowledgeBaseDocument,
  PrivacyPolicyDocument,
  SeoDocument,
  SourcesDocument,
  SubchapterDocument,
  TermsOfUseDocument,
  TooltipDocument,
  UiElementsDocument,
} from '../generated/types/eschertemplate.types'
import { PrismicDocument } from '@prismicio/types'
import {
  ContentItemResponseTypesWithoutScrollymationAndScrollyStory,
  ContentItemTypes,
  ContentItemTypeToResponseTypeMap,
} from './prismic-types'

function usePrismicData() {
  const { lang } = useLang()
  const prismicClient = usePrismic()

  if (!prismicClient) {
    throw new Error('Prismic client not found')
  }

  const useIntroQuery = () => {
    return useQuery(
      ['intro_page', lang],
      async (): Promise<IntroPageDocument> => {
        return prismicClient.getSingle('intro_page', { lang })
      }
    )
  }

  const usePrivacyQuery = () => {
    return useQuery(
      ['privacy_policy', lang],
      async (): Promise<PrivacyPolicyDocument> => {
        return prismicClient.getSingle('privacy_policy', { lang })
      }
    )
  }

  const useTermsOfUseQuery = () => {
    return useQuery(
      ['terms_of_use', lang],
      async (): Promise<TermsOfUseDocument> => {
        return prismicClient.getSingle('terms_of_use', { lang })
      }
    )
  }

  const useSources = () => {
    return useQuery(
      ['sources', lang],
      async (): Promise<SourcesDocument> => {
        return prismicClient.getSingle('sources', { lang })
      }
    )
  }

  const useImprint = () => {
    return useQuery(
      ['imprint', lang],
      async (): Promise<ImprintDocument> => {
        return prismicClient.getSingle('imprint', { lang })
      }
    )
  }

  // Typing here is really ugly because of the way Prismic types are generated
  // But in the end only thing that it does extra is, that after fetching the array of chapters, array is sorted by chapter_number
  const useAllChapters = () => {
    return useQuery(
      ['all-chapters', lang],
      async (): Promise<ChapterDocument[]> => {
        const chapters: Array<
          PrismicDocument<Record<string, any>, string, string>
        > = await prismicClient.getAllByType('chapter', {
          lang,
          fetchLinks: [
            'subchapter.subchapter_title',
            'subchapter.subchapter_lead',
            'subchapter.bg_main',
          ],
        })

        // Type guard to ensure all chapters have a non-null uid
        const filteredChapters = chapters.filter(
          (chapter): chapter is ChapterDocument<string> => chapter.uid !== null
        )

        // Sort the filtered chapters by chapter_number
        filteredChapters.sort((a, b) => {
          return (a.data?.chapter_number ?? 0) - (b.data?.chapter_number ?? 0)
        })

        return filteredChapters
      }
    )
  }

  const useChapter = (chapterUid?: string) => {
    return useQuery(
      [`chapter-${chapterUid}`, lang],
      async (): Promise<ChapterDocument> => {
        if (!chapterUid) {
          throw new Error('Chapter UID is required')
        }
        return prismicClient.getByUID('chapter', chapterUid, {
          lang,
          //TODO: fetchLinks are not included in the response type, find some smart way to include them
          fetchLinks: [
            'subchapter.subchapter_title',
            'subchapter.subchapter_lead',
            'subchapter.bg_main',
          ],
        })
      },
      {
        enabled: !!chapterUid,
      }
    )
  }

  const useSubchapter = (subchapterUid?: string) => {
    return useQuery(
      [`subchapter-${subchapterUid}`, lang],
      async (): Promise<SubchapterDocument> => {
        if (!subchapterUid) {
          throw new Error('subchapter UID is required')
        }
        return prismicClient.getByUID('subchapter', subchapterUid, {
          lang,
        })
      },
      {
        enabled: !!subchapterUid,
      }
    )
  }

  /**
   * The hook is generic, allowing TypeScript to infer the correct response type
   * based on the content item type provided.
   *
   * @param type - The type of the content item (e.g., 'content_item_text').
   * @param contentItemUid - The unique identifier for the content item.
   */
  const useContentItem = <T extends ContentItemTypes>(
    type?: T,
    contentItemUid?: string | null
  ) => {
    return useQuery<ContentItemTypeToResponseTypeMap[T]>(
      [`contentItem-${type}-${contentItemUid}`, lang],
      async (): Promise<ContentItemTypeToResponseTypeMap[T]> => {
        if (!contentItemUid) {
          throw new Error('contentItem UID is required')
        }
        if (!type) {
          throw new Error('contentItem type is required')
        }
        // Fetch the content item from Prismic and cast it to the correct type.
        return (await prismicClient.getByUID(type, contentItemUid, {
          lang,
        })) as ContentItemTypeToResponseTypeMap[T]
      },
      {
        // The query is only enabled (i.e., will run) if a contentItemUid is provided.
        enabled: !!contentItemUid,
      }
    )
  }

  const contentItemsArray = [
    'content_item_video',
    'content_item_image',
    'content_item_text',
    'content_item_gallery',
  ]

  const useKBContentItems = () => {
    return useQuery(['all-content-items', lang], async () => {
      const contentItems: ContentItemResponseTypesWithoutScrollymationAndScrollyStory[] = []
      for (const contentItem of contentItemsArray) {
        const contentItemResponse = await prismicClient.getAllByType(
          contentItem,
          {
            lang,
            fetchLinks: ['category.name'],
          }
        )
        contentItems.push(
          ...(contentItemResponse as ContentItemResponseTypesWithoutScrollymationAndScrollyStory[])
        )
      }
      return contentItems
    })
  }

  const useUIelements = () => {
    return useQuery(
      ['ui_elements', lang],
      async (): Promise<UiElementsDocument> => {
        return prismicClient.getSingle('ui_elements', { lang })
      }
    )
  }

  const useKnowledgebase = () => {
    return useQuery(
      ['knowledge_base', lang],
      async (): Promise<KnowledgeBaseDocument> => {
        return prismicClient.getSingle('knowledge_base', { lang })
      }
    )
  }

  const useIcons = () => {
    return useQuery(
      ['icons', lang],
      async (): Promise<IconsDocument> => {
        return prismicClient.getSingle('icons', { lang })
      }
    )
  }

  const useEndOfVideobook = () => {
    return useQuery(
      ['end_of_videobook', lang],
      async (): Promise<EndOfVideobookDocument> => {
        return prismicClient.getSingle('end_of_videobook', { lang })
      }
    )
  }

  const useKnowledgebaseCategories = () => {
    return useQuery(
      ['category', lang],
      async (): Promise<CategoryDocument[]> => {
        return prismicClient.getAllByType('category', { lang })
      }
    )
  }

  const useTooltip = (tooltipUid?: string) => {
    return useQuery(
      [`tooltip-${tooltipUid}`, lang],
      async (): Promise<TooltipDocument> => {
        if (!tooltipUid) {
          throw new Error('tooltip UID is required')
        }
        return prismicClient.getByUID('tooltip', tooltipUid, {
          lang,
        })
      },
      {
        enabled: !!tooltipUid,
      }
    )
  }

  const useAllTooltips = () => {
    return useQuery(
      ['all-tooltips', lang],
      async (): Promise<TooltipDocument[]> => {
        return prismicClient.getAllByType('tooltip', { lang })
      }
    )
  }

  const useSEO = () => {
    return useQuery(
      ['seo', lang],
      async (): Promise<SeoDocument> => {
        return prismicClient.getSingle('seo', { lang })
      }
    )
  }

  return {
    useIntroQuery,
    usePrivacyQuery,
    useTermsOfUseQuery,
    useSources,
    useImprint,
    useAllChapters,
    useChapter,
    useSubchapter,
    useContentItem,
    useUIelements,
    useKnowledgebase,
    useIcons,
    useEndOfVideobook,
    useKnowledgebaseCategories,
    useKBContentItems,
    useTooltip,
    useSEO,
    useAllTooltips,
  }
}

export default usePrismicData
