import styled from 'styled-components/macro'
import * as React from 'react'
import usePrismicData from '../../../data/usePrismicData'
import { IconTypes } from '../../../data/prismic-enums'
import { zIndex } from '../../../utils/zIndex'
import Button from '../../atoms/Button'
import { ReactNode } from 'react'

const LegalContainer = ({
  onClose,
  children,
}: {
  onClose: () => void
  children: ReactNode
}) => {
  const { useIcons } = usePrismicData()
  const icons = useIcons()

  const closeIcon = icons.data?.data[IconTypes.CLOSE]?.url ?? ''

  return (
    <LegalWrapper>
      <StyledButton
        size={'large'}
        style={{ paddingTop: 25 }}
        $scaleOnHover={true}
        onClick={onClose}
      >
        <img src={closeIcon} width={20} height={20} alt={'close-button'} />
      </StyledButton>

      {children}
    </LegalWrapper>
  )
}

export default LegalContainer

const LegalWrapper = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.legalWrapper};
  position: fixed;
  background-color: black;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 80px;
`

const StyledButton = styled(Button)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${zIndex.legalWrapper + 1};
`
