import React from 'react'
import InteractiveBackground from 'components/Subcontent/InteractiveBackground'
import { ContentItemVideoDocument } from '../../../generated/types/eschertemplate.types'
import BottomBar from '../../Subcontent/BottomBar'
import { getFirstFrameUrl } from '../../../utils/videoUtils'
import { FilledLinkToMediaField } from '@prismicio/types'
import useAppStateStore from '../../../hooks/useAppStateStore'
import { breakpoints } from '../../../utils/breakpoints'

interface VideoBackgroundProps {
  contentItemVideo: ContentItemVideoDocument
  isTextPositionLeft?: boolean
}

const VideoStillBackground = ({
  contentItemVideo,
  isTextPositionLeft,
}: VideoBackgroundProps) => {
  const { updateState } = useAppStateStore()
  const { openedModalType } = useAppStateStore()

  const activateFullScreen = () => {
    updateState('openedModalUid', contentItemVideo.uid)
    updateState('openedModalType', contentItemVideo.type)
  }

  const videoUrl = breakpoints.phone
    ? (contentItemVideo.data.desktop_url_video as FilledLinkToMediaField)?.url
    : (contentItemVideo.data.mobile_url_video as FilledLinkToMediaField)?.url

  const thumbnailUrl =
    contentItemVideo.data.poster.url ?? getFirstFrameUrl(videoUrl)

  return (
    <InteractiveBackground
      activateCallback={activateFullScreen}
      showBackgroundOverlay={true}
    >
      <img
        src={thumbnailUrl}
        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        alt={
          (contentItemVideo.data.desktop_url_video as FilledLinkToMediaField)
            .name ?? 'video-thumbnail'
        }
        loading="lazy"
      />
      <BottomBar
        contentItem={contentItemVideo}
        handleClick={activateFullScreen}
        hide={!!openedModalType}
        desktopTextPositionLeft={isTextPositionLeft}
      />
    </InteractiveBackground>
  )
}

export default VideoStillBackground
