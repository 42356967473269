import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  ReactNode,
} from 'react'
import { appConfig } from '../config'

interface LangContextProps {
  lang: string
  setLang: React.Dispatch<React.SetStateAction<string>>
}

const LangContext = createContext<LangContextProps | undefined>(undefined)

export const useLang = () => {
  const context = useContext(LangContext)
  if (!context) {
    throw new Error('useLang must be used within a LangProvider')
  }
  return context
}

interface LangProviderProps {
  children: ReactNode
}

export const LangProvider: React.FC<LangProviderProps> = ({ children }) => {
  const getInitialLang = () => {
    const storedLang = localStorage.getItem('userLang')

    // Use stored language if available
    if (storedLang) return storedLang

    // Get full browser primary language code
    const browserLang = navigator.language.split('-')[0]

    // Check if browser language is supported
    const matchingLang = appConfig.languages.find(
      (lang) =>
        lang.code.split('-')[0].toLowerCase() === browserLang.toLowerCase()
    )

    // If supported, return it, otherwise return first language in config
    return matchingLang ? matchingLang.code : appConfig.languages[0].code
  }

  const [lang, setLang] = useState(getInitialLang)

  useEffect(() => {
    localStorage.setItem('userLang', lang)
  }, [lang])

  const contextValue = useMemo(() => ({ lang, setLang }), [lang])

  return (
    <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>
  )
}
