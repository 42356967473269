import { JSXMapSerializer, PrismicRichText } from '@prismicio/react'
import Tooltips from './Tooltip'
import { FilledContentRelationshipField, RichTextField } from '@prismicio/types'
import styled from 'styled-components/macro'

interface Props {
  field: RichTextField
}

export const PrismicRichTextWithTooltips = ({ field }: Props) => {
  const components: JSXMapSerializer = {
    hyperlink: ({ node, children }) => {
      return (
        <Tooltips
          tooltipUid={(node.data as FilledContentRelationshipField).uid ?? ''}
        >
          {children}
        </Tooltips>
      )
    },
  }

  return (
    <RichTextWrapper data-cy="richTextWrapper">
      <PrismicRichText field={field} components={components} />
    </RichTextWrapper>
  )
}

const RichTextWrapper = styled.div`
  h1 {
    ${(p) => p.theme.typographyRichTextPaddings.head1}
  }
  h2 {
    ${(p) => p.theme.typographyRichTextPaddings.head2}
  }
  h3 {
    ${(p) => p.theme.typographyRichTextPaddings.head3}
  }
  h4 {
    ${(p) => p.theme.typographyRichTextPaddings.head4}
  }
  h5 {
    ${(p) => p.theme.typographyRichTextPaddings.head5}
  }
  p {
    ${(p) => p.theme.typographyRichTextPaddings.body}
  }
`
