import { ContentItemTypes, LegalTypes } from 'data/prismic-types'
import { create } from 'zustand'

export type OpenedModalType = ContentItemTypes | LegalTypes | null

interface AppState {
  openedModalUid: string | null
  openedModalType: OpenedModalType | null
  openedModalId: string | null
  detailsOpen: boolean
  chaptersBarOpen: boolean
  muted: boolean
  menuOpen: boolean
  updateState: <K extends keyof AppState>(key: K, val: AppState[K]) => void
}

const getUrlParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search)
  const value = urlParams.get(param)
  return value === '' ? null : value
}

const useAppStateStore = create<AppState>((set) => ({
  openedModalUid: getUrlParam('contentItemUid'),
  openedModalType: getUrlParam('openModalType') as OpenedModalType,
  openedModalId: getUrlParam('subcontentId'),
  detailsOpen: false, // Default value, can be dynamically set later
  chaptersBarOpen: false,
  muted: false,
  menuOpen: false,

  updateState: (key, val) =>
    set((state) => ({
      ...state,
      [key]: val,
    })),
}))

export default useAppStateStore
