import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { safeHtml } from 'utils/validation'
import german from 'hyphenation.de'
import Hypher from 'hypher'
import { devices } from 'utils/styles'
import { ThemeType } from 'theme'
import { appConfig } from '../../config'
import { ChapterDocument } from '../../generated/types/eschertemplate.types'
import useAppStateStore from '../../hooks/useAppStateStore'

const h = new Hypher(german)

export interface ChaptersBarProps {
  chapters: ChapterDocument[]
  activeChapterUid?: string
}

export const ChaptersBar: React.FC<ChaptersBarProps> = ({
  chapters,
  activeChapterUid,
}) => {
  const { chaptersBarOpen } = useAppStateStore()
  const bars = chapters.map((chapter) => {
    const active = chapter.uid === activeChapterUid

    function getChapterNumber() {
      const baseChapterNumber: number = appConfig.chaptersBar
        .startChaptersBarFromZero
        ? Number(chapter.data.chapter_number) - 1
        : Number(chapter.data.chapter_number)

      return baseChapterNumber < 10
        ? `0${baseChapterNumber}`
        : baseChapterNumber
    }

    const text = (
      <Text $active={active}>
        {appConfig.chaptersBar.showChapterNumberInChapterBar && (
          <ChapterIdx>{getChapterNumber()}</ChapterIdx>
        )}
        <TitleText
          {...safeHtml(h.hyphenateText(chapter.data.chapter_title ?? ''))}
        />
      </Text>
    )

    return (
      <BarItem
        data-cy="chaptersBarItem"
        $onlyShowCurrentChapter={false}
        to={`/${appConfig.contentUnitUrl}/${chapter.uid}`}
        key={chapter.id}
        $active={active}
      >
        {text}
      </BarItem>
    )
  })

  return (
    <Container $shouldHideChaptersBar={false} $isOpen={chaptersBarOpen}>
      <ChapterBarContainer
        className={chaptersBarOpen ? 'open' : 'closed'}
        data-cy="chaptersBarContainer"
      >
        {bars}
      </ChapterBarContainer>
    </Container>
  )
}

const Container = styled.div<{
  $shouldHideChaptersBar: boolean
  $isOpen: boolean
}>`
  pointer-events: ${(props) => (props.$isOpen ? 'all' : 'none')};
  display: ${(props) => (props.$shouldHideChaptersBar ? 'none' : 'flex')};
  position: fixed;
  width: 100%;
  min-height: 80px;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 21;
`

const ChapterBarContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 32px 8px 32px;
  background-color: ${(p) => p.theme.palette.black(0.65)};
  backdrop-filter: blur(8px);
  opacity: 0;
  transform: translateY(200%);
  transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s,
    opacity 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  will-change: transform;
  -webkit-backface-visibility: hidden;
  gap: 28px;

  &.open {
    opacity: 1;
    transform: translateY(0);
  }

  &.closed {
    opacity: 0;
    transform: translateY(200%);
  }

  bottom: auto;
  top: 0px;

  @media ${devices.mobile} {
    display: none;
  }
`

const BarItem = styled(Link)<{
  $active: boolean
  $onlyShowCurrentChapter: boolean
}>`
  color: ${(props) =>
    props.$active
      ? (props.theme as ThemeType).palette.white(1)
      : (props.theme as ThemeType).palette.white(0.24)};
  flex: 1;
  height: ${(props) => props.theme.constants.sizes.desktop.bottomBarHeight};
  cursor: pointer;
  text-decoration: none;
  display: ${(props) =>
    props.$onlyShowCurrentChapter && !props.$active ? 'none' : 'flex'};
  flex-direction: row;
  transition: color 0.3s cubic-bezier(0.5, 1, 0.89, 1);

  &:hover {
    transition: color 0.5s cubic-bezier(0.5, 1, 0.89, 1);
    background-color: unset;
  }
`

const Text = styled.div<{
  $active: boolean
}>`
  font-size: 0.85rem;
  font-family: ${(props) => props.theme.typography.interaction.fontFamily};
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-top: ${(props) =>
    props.$active
      ? `1px solid ${(props.theme as ThemeType).palette.white(1)}`
      : `1px solid ${(props.theme as ThemeType).palette.white(0.24)}`};
  padding-top: 12px;
`

const TitleText = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: ${(props) => props.theme.typography.interaction.fontFamily};
  font-weight: 700;
  font-size: 14px;
  color: inherit;
`

const ChapterIdx = styled.div`
  padding: 0px 7px 0 5px;
  font-weight: 700;
  font-size: 14px;
  font-family: ${(props) => props.theme.typography.interaction.fontFamily};
  position: relative;
  top: auto;
  color: inherit;
`
