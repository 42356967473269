import { QueryObserverResult } from '@tanstack/react-query'
import { KnowledgeBaseDocument } from '../../../generated/types/eschertemplate.types'
import React, { useEffect, useState } from 'react'
import CategoriesSlider from './CategoriesSlider'
import KbContentGallery from './ContentGallery'
import { ContentItemResponseTypesWithoutScrollymationAndScrollyStory } from '../../../data/prismic-types'

interface KbSubchaptersProps {
  contentItems: QueryObserverResult<
    ContentItemResponseTypesWithoutScrollymationAndScrollyStory[]
  >
  knowledgeBase: QueryObserverResult<KnowledgeBaseDocument>
}

const Articles = ({ knowledgeBase, contentItems }: KbSubchaptersProps) => {
  useEffect(() => {
    setActiveCategory(knowledgeBase.data?.data.all_categories)
  }, [knowledgeBase.data?.data.all_categories])

  function chooseCategory(name?: string | null) {
    setActiveCategory(name)
  }
  const [activeCategory, setActiveCategory] = useState(
    knowledgeBase.data?.data.all_categories
  )

  return (
    <>
      <CategoriesSlider
        activeCategory={activeCategory}
        chooseCategory={chooseCategory}
        allStories={knowledgeBase.data?.data.all_categories}
      />
      <KbContentGallery
        contentItems={contentItems}
        activeCategory={activeCategory}
        allStories={knowledgeBase.data?.data.all_categories}
      />
    </>
  )
}

export default Articles
