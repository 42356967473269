import Typography from 'components/atoms/Typography'
import styled from 'styled-components/macro'
import { devices } from 'utils/styles'

import { zIndex } from '../../../utils/zIndex'
import Hypher from 'hypher'
import german from 'hyphenation.de'
import { PrismicRichTextWithTooltips } from '../../SectionItems/PrismicRichTextWithTooltips'
import { RichTextField } from '@prismicio/types'
const h = new Hypher(german)

interface DetailAreaProps {
  leadText?: string
  title: string
  subtitle?: string
  paragraphs: RichTextField
  caption?: string
  alwaysopen?: boolean
  modalMainRef?: React.RefObject<HTMLDivElement>
  extraPadding?: boolean
  dataCy?: string
}

const DetailArea = (props: DetailAreaProps) => {
  return (
    <>
      <DetailAreaDiv
        $alwaysOpen={!!props.alwaysopen}
        $extraPadding={props.extraPadding}
      >
        <DetailWrapper
          $extraPadding={props.extraPadding}
          data-cy="modalDetailArea"
        >
          {props.leadText && (
            <Typography variant="lead">
              {h.hyphenateText(props.leadText)}
            </Typography>
          )}

          <Typography variant="head3" data-cy="modalTitle">
            {h.hyphenateText(props.title)}
          </Typography>

          {props.subtitle && (
            <Typography variant="head4" data-cy="modalSubtitle">
              {h.hyphenateText(props.subtitle)}
            </Typography>
          )}
          <PrismicRichTextWithTooltips field={props.paragraphs} />
          {props.caption && (
            <Typography style={{ marginTop: '20px' }} variant="caption">
              {props.caption}
            </Typography>
          )}
        </DetailWrapper>
      </DetailAreaDiv>
    </>
  )
}

export default DetailArea

export const detailAreaWidth = 'max(25vw, 300px)'
export const DetailAreaDiv = styled.div<{
  $alwaysOpen: boolean
  $extraPadding?: boolean
}>`
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.modalDetail};
  background-color: ${(p) => p.theme.palette.background};
  width: ${({ $alwaysOpen }) => ($alwaysOpen ? '100%' : '0px')};
  min-width: 0;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 16px;
  transition: min-width 0.2s ease-in-out;
  
  .details-open & {
    min-width: ${detailAreaWidth};
    transition: min-width 0.2s ease-in-out;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  h4 {
    padding-bottom: 16px;
  }
  
  @media ${devices.mobile} {
    width:100%;
    flex: 1 1 auto;
    padding: 24px 16px;
    padding-top: ${({ $extraPadding }) => ($extraPadding ? '100px' : '24px')};
    
    //extra space for mobile browser control panel
    padding-bottom: 112px;
    overflow: visible;
  },
`

const DetailWrapper = styled.div<{
  $extraPadding?: boolean
}>`
  padding: 48px 24px;
  padding-bottom: 144px;
  padding-top: ${({ $extraPadding }) => ($extraPadding ? '100px' : '48px')};
  min-width: ${detailAreaWidth};

  @media ${devices.mobile} {
    padding: 0;
  },
`
