export const giveColor = (r: number, g: number, b: number, o?: number) => {
  const red = r < 0 ? 0 : r > 255 ? 255 : r
  const green = g < 0 ? 0 : g > 255 ? 255 : g
  const blue = b < 0 ? 0 : b > 255 ? 255 : b
  const opacity = o === undefined || o > 1.0 ? 1.0 : o < 0 ? 0 : o
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

// new values
export const screenWidth = {
  mobile: 478,
  tablet: 991,
  desktop: 1440,
}

export const devices = {
  mobile: `(max-width: ${screenWidth.mobile}px)`,
  tablet: `(min-width: ${screenWidth.mobile}px) and (max-width: ${screenWidth.tablet}px)`,
  desktop: `(min-width: ${screenWidth.tablet}px) `,
  bigDesktop: `(min-width: ${screenWidth.desktop}px)`,
  orientationPortrait: {
    mobile: `(max-width: ${screenWidth.mobile}px) and (orientation: portrait)`,
    tablet: `(min-width: ${screenWidth.mobile}px) and (max-width: ${screenWidth.tablet}px) and (orientation: portrait)`,
  },
  orientationLandscape: {
    mobile: `(max-width: ${screenWidth.tablet}px) and (orientation: landscape)`,
    tablet: `((min-width: ${screenWidth.tablet}px) and (max-width: ${screenWidth.desktop}px) and (orientation: landscape)`,
  },
}
