import React, { useMemo } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { withStyles, WithStyles } from '@mui/styles'
import { appConfig } from 'config'
import { AppStyles } from './App.style'
import Modal from 'components/Modals/Modal'
import usePrismicData from './data/usePrismicData'
import KnowledgeBase from 'components/KnowledgeBase'
import Slide from './components/ContentSlider/Slide'
import NavBar from './components/NavBar'
import { ChaptersBar } from './components/ChaptersBar'
import { Helmet } from 'react-helmet'
import LoadingSpinner from './components/atoms/loadingSpinner'
import Preview from './components/common/prismicPreview'
import Intro from './components/Intro'
import SeoFavicons from './components/common/SeoFavicons'
import useLoadingStatus from './hooks/useLoadingStatus'
import SideNav from './components/SideNav'
import LockedScreen from './components/ScreenLock'
import Menu from './components/Menu'
import { isTouchDevice } from './utils/breakpoints'

type StyleProps = WithStyles<typeof AppStyles>

const App: React.FC<StyleProps> = () => {
  const { useAllChapters } = usePrismicData()
  const chaptersData = useAllChapters()
  const { loadedCount, totalCount } = useLoadingStatus()

  const slides = useMemo(() => {
    return chaptersData.data?.map((chapter, index: number) => (
      <Route
        key={`Route-${chapter.uid}`}
        exact
        path={`/${appConfig.contentUnitUrl}/${chapter.uid}/:sectionUid?`}
        render={() => (
          <>
            <Helmet>
              <title>{chapter.data.chapter_title ?? 'Docmine videobook'}</title>
              <meta
                name="description"
                content={chapter.data.chapter_subtitle ?? 'Docmine videobook'}
              />
              <link
                rel="canonical"
                href={`${window.location.origin}/${appConfig.contentUnitUrl}/${chapter.uid}`}
              />
            </Helmet>
            <ChaptersBar
              chapters={chaptersData.data}
              activeChapterUid={chapter.uid}
            />
            <Slide
              chapter={chapter}
              nextChapter={chaptersData.data[index + 1]}
            />
            {appConfig.showSideBar && !isTouchDevice && (
              <SideNav chapter={chapter} />
            )}
            <NavBar />
            <Menu chapter={chapter} />
            <LockedScreen />
          </>
        )}
      />
    ))
  }, [chaptersData.data])

  if (chaptersData.isLoading) {
    return (
      <LoadingSpinner
        loadedCount={loadedCount}
        totalCount={totalCount}
        showLoadingBar
      />
    )
  }

  if (chaptersData.isError) {
    return <div>Error</div>
  }

  return (
    <>
      <SeoFavicons />
      <Switch>
        {slides}
        <Route exact path="/intro" render={() => <Intro />} />
        {appConfig.allowKnowledgeBase && (
          <Route
            exact
            path="/knowledgeBase"
            render={() => (
              <>
                <Menu />
                <LockedScreen />
                <KnowledgeBase />
              </>
            )}
          />
        )}
        <Route exact path="/">
          {appConfig.landingPageRoute !== '/' && (
            <Redirect to={appConfig.landingPageRoute} />
          )}
        </Route>
        <Route exact path="/preview">
          <Preview />
        </Route>
        <Redirect
          from={`/${appConfig.contentUnitUrl}`}
          to={`/${appConfig.contentUnitUrl}/${chaptersData.data[0]?.uid}`}
        />
        <Redirect from="*" to={appConfig.notFoundRoute} />
      </Switch>
      <Modal />
    </>
  )
}

export default withStyles((theme) => {
  const showScrollbar = !appConfig.showSideBar || isTouchDevice
  return AppStyles({ theme, showScrollbar })
})(App)
