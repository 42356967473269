// react component named Quote with typescript which accepts in props a quoteText, author, and author description
import Typography from '../atoms/Typography'
import styled from 'styled-components/macro'

export interface QuoteProps {
  quoteText?: string
  author?: string
  authorDescription?: string
}

const Quote = ({ quoteText, author, authorDescription }: QuoteProps) => {
  return (
    <QuoteWrapper>
      <Typography
        variant={'quote'}
        textAlign={'center'}
        style={{ paddingBottom: 16 }}
      >
        {quoteText}
      </Typography>
      <Typography variant={'head4'} textAlign={'center'}>
        {author}
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <Typography variant={'caption'}>{authorDescription}</Typography>
      </div>
    </QuoteWrapper>
  )
}

export default Quote

const QuoteWrapper = styled.div`
  padding: 24px 0;
`
