export interface ILanguageType {
  code: string
  name: string
  loadingSpinnerConfig: LoadingSpinnerType
}

export interface LoadingSpinnerType {
  title: string
  subtitle: string
}

export interface ExtraRoute {
  path: string
  component: string
}

export interface VideoCustomizationConfig {
  showMeta?: boolean
}

export enum Devices {
  desktop = 'desktop',
  phone = 'phone',
  tablet = 'tablet',
}

export enum Orientation {
  portrait = 'portrait',
  landscape = 'landscape',
}

export enum ComparisonRestriction {
  greaterThan = 'greaterThan',
  smallerThan = 'smallerThan',
}

type SizesHeight = {
  restriction: ComparisonRestriction
  height: number
} & { width?: number }

type SizesWidth = {
  restriction: ComparisonRestriction
  width: number
} & { height?: number }

export type SizeRestriction = SizesHeight | SizesWidth

export interface DeviceRestriction {
  device: Devices
  orientation?: Orientation
}
export interface RestrictedScreens {
  sizes?: SizeRestriction[]
  devices?: DeviceRestriction[]
}

export interface TemplateConfig {
  ScrollSideBar?: {
    active: boolean
  }
  NextChapterCover?: {
    version: 'Default'
  }

  VideoBookEnd?: {
    version: 'Default'
  }
  ChaptersBar?: {
    variant: 'Default'
  }
  Cover?: {
    version?: 'LeftAligned' | 'RightAligned'
    showChapterNumber?: boolean
  }
  Intro?: {
    version?: 'CenterAligned' | 'RightAligned'
    startButtonGoToUrl: string // by default use 'chapter'
  }
  SubcontentModule?: {
    text: {
      supportingMaterial: {
        titleVersion: string
        secondaryTitleVersion: string
        text: {
          version: 'Default'
          color?: string
        }
        video: {
          version: 'Default'
        }
        image: {
          version: 'Default'
        }
      }
    }
  }
  Legal?: {
    imprint: {
      version: 'Default'
    }
    privacyPolicy: {
      version: 'Default'
    }
    sources: {
      version: 'Default'
    }
    termsOfUse: {
      version: 'Default'
    }
  }
  Footer?: {
    version: 'Default'
    showCookieButton?: boolean
  }
}

export interface ConfigType {
  showThumbnailUnderIcon: boolean
  templateConfig?: TemplateConfig
  allowKnowledgeBase: boolean
  prismicPath: string
  languages: ILanguageType[]
  landingPageRoute: string
  notFoundRoute: string
  prismicModelsNamesMap: {
    page: string
    section: string
    legal?: string
    impressumSlug?: string
    quellenSlug?: string
    dataPrivacySlug?: string
    subcontentNamesArray: string[]
    extraChapters?: string[]
  }
  contentUnitUrl: string
  extraRoutes: ExtraRoute[]
  videoCustomization: VideoCustomizationConfig
  restrictedScreens: RestrictedScreens
  contentItemDetailsOpenByDefault: boolean
  globalVolume: boolean
  chaptersBar: {
    showChapterNumberInChapterBar: boolean
    startChaptersBarFromZero: boolean
  }
  showSideBar: boolean
}
