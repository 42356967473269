import DetailArea from './components/ModalDetailArea'
import { ModalMainArea } from './components/ModalMainArea'
import ButtonBar from './components/ModalButtons'
import { useRef } from 'react'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'
import { ContentItemImageDocument } from '../../generated/types/eschertemplate.types'
import ImageLoader from '../common/imageLoader'

interface ImageModalProps {
  contentItem: ContentItemImageDocument
  onClose: () => void
}

const ImageModal = ({ contentItem, onClose }: ImageModalProps) => {
  const modalMainRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <ModalMainArea variant="row">
        <div style={{ height: 1 }} ref={modalMainRef}></div>
        <ButtonBar
          title={contentItem.data.title ?? ''}
          onClose={onClose}
        ></ButtonBar>

        <ImageContainer>
          <Image
            src={contentItem.data.image.url ?? ''}
            alt={contentItem.data.image.alt ?? contentItem.data.title ?? ''}
            loading={'lazy'}
            data-cy="image"
          />
        </ImageContainer>
        <DetailArea
          modalMainRef={modalMainRef}
          leadText={contentItem.data.lead_text ?? ''}
          title={contentItem.data.title ?? ''}
          subtitle={contentItem.data.subtitle ?? ''}
          paragraphs={contentItem.data.text ?? []}
          caption={contentItem.data.caption ?? ''}
        ></DetailArea>
      </ModalMainArea>
    </>
  )
}
export default ImageModal

const Image = styled(ImageLoader)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;

  @media ${devices.mobile} {
    object-fit: contain;
  }
`

const ImageContainer = styled.div`
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
  background-color: ${(p) => p.theme.palette.background};
  @media ${devices.mobile} {
    max-height: 80vh;
    min-height: 75vh;
  }
`
