//list of all z-indexes used in the app
export const zIndex = {
  closeMenu: 99,
  menu: 100,
  legalWrapper: 2591,
  loadingSpinner: 2000,
  burger: 2590,
  modal: 9999,
  modalDetail: 1000,
  screenLock: 10001,
}
