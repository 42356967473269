import { CSSProperties, ImgHTMLAttributes, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'

type ImageSize = 'xs' | 'sm' | 'md' | 'l' | 'xl'

interface OwnProps {
  size?: ImageSize
  $imagePosition?: CSSProperties['position']
}

type ImageLoaderProps = ImgHTMLAttributes<HTMLImageElement> & OwnProps

const ImageLoader = ({
  size,
  $imagePosition = 'static',
  ...props
}: ImageLoaderProps) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const getDimension = (size: ImageSize | undefined) => {
    switch (size) {
      case 'xs':
        return { previewSize: 10, fullSize: 100 }
      case 'sm':
        return { previewSize: 15, fullSize: 200 }
      case 'md':
        return { previewSize: 20, fullSize: 400 }
      case 'l':
        return { previewSize: 40, fullSize: 800 }
      case 'xl':
        return { previewSize: 80, fullSize: 1600 }
      default:
        return { previewSize: 40, fullSize: 800 }
    }
  }

  const { previewSize, fullSize } = getDimension(size)

  const fullSrc = size ? `${props.src}?w=${fullSize}&h=${fullSize}` : props.src

  return (
    <>
      {!isLoaded && (
        <>
          <link
            rel="preload"
            as="image"
            href={`${props.src}?w=${previewSize}&h=${previewSize}`}
          />
          <PreviewImage
            {...props}
            src={`${props.src}?w=${previewSize}&h=${previewSize}`}
            alt={props.alt ?? 'Preview image loading...'}
          />
        </>
      )}
      <FullImage
        {...props}
        src={fullSrc}
        alt={props.alt ?? 'Image'}
        onLoad={() => setIsLoaded(true)}
        $isLoaded={isLoaded}
        $imagePosition={$imagePosition}
      />
    </>
  )
}

export default ImageLoader

export function resizedImageUrl(
  maxDimension: number,
  imageSrc?: string
): string {
  if (!imageSrc) {
    return ''
  }

  return `${imageSrc}?w=${maxDimension}&h=${maxDimension}`
}

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const PreviewImage = styled.img`
  filter: blur(5px);
  animation: ${pulse} 2s infinite ease-in-out;
`

interface FullImageProps {
  $isLoaded: boolean
  $imagePosition: string
}

const FullImage = styled.img<FullImageProps>`
  position: ${({ $isLoaded, $imagePosition }) =>
    $isLoaded ? $imagePosition : 'absolute'};
  visibility: ${({ $isLoaded }) => ($isLoaded ? 'visible' : 'hidden')};
`
