import { defaultTheme, ThemeType } from 'theme'
import { ConfigType } from 'config-type'

export const defaultConfig: ConfigType = {
  prismicPath: '',
  allowKnowledgeBase: false,
  languages: [
    {
      code: 'de-ch',
      name: 'Deutsch',
      loadingSpinnerConfig: {
        title: 'German Title',
        subtitle: 'German subtitle',
      },
    },
  ],
  showThumbnailUnderIcon: true,
  landingPageRoute: '/',
  notFoundRoute: '/',
  prismicModelsNamesMap: {
    page: 'chapter',
    section: 'subchapter',
    legal: 'imprint-sources-rights',
    impressumSlug: 'impressum',
    quellenSlug: 'text--und-bildquellenverzeichnis',
    dataPrivacySlug: 'datenschutz',
    subcontentNamesArray: [
      'content_item_video',
      'content_item_image',
      'content_item_text',
      'content_item_gallery',
      'content_item_scrollymation',
    ],
  },
  contentUnitUrl: 'chapter',
  extraRoutes: [],
  videoCustomization: {},
  restrictedScreens: {},
  contentItemDetailsOpenByDefault: false,
  globalVolume: false,
  chaptersBar: {
    showChapterNumberInChapterBar: false,
    startChaptersBarFromZero: false,
  },
  showSideBar: true,
}

export const appConfig: ConfigType = defaultConfig

export const fullTheme: ThemeType = defaultTheme

export async function loadConfig() {
  const configModule = await import(
    `./projects/${process.env.REACT_APP_PROJECT_NAME}/config.ts`
  )
  const config: ConfigType | null = configModule.default

  if (config) {
    Object.assign(appConfig, config)
  }

  const projectThemeModule = await import(
    `./projects/${process.env.REACT_APP_PROJECT_NAME}/theme.ts`
  )
  const projectTheme: ThemeType | null = projectThemeModule.default

  if (projectTheme) {
    Object.assign(fullTheme, projectTheme)
  }

  return config
}
