import { SectionTextTypes } from 'data/prismic-enums'
import { InlineSubcontentDivider } from './InlineSubcontentDivider'
import { LinkToContentItem } from './LinkToContentItem'
import {
  FilledContentRelationshipField,
  FilledLinkToWebField,
} from '@prismicio/types'
import { LinkToWeb } from './LinkToWeb'
import {
  ContentItemResponseTypes,
  SubchapterDocumentDataBodySlices,
} from '../../data/prismic-types'
import { PrismicRichTextWithTooltips } from './PrismicRichTextWithTooltips'
import Quote from './Quote'
import useAppStateStore from '../../hooks/useAppStateStore'

interface Props {
  sectionItemData: SubchapterDocumentDataBodySlices
  contentItem?: ContentItemResponseTypes
}

const SubcontentMainComponent = (props: Props) => {
  const { updateState } = useAppStateStore()
  const { sectionItemData, contentItem } = props

  const openFullscreenSubcontent = () => {
    if (contentItem) {
      updateState('openedModalUid', contentItem.uid)
    }
  }

  if (sectionItemData.slice_type === SectionTextTypes.paragraph) {
    return (
      <>
        {sectionItemData.items.map((item, index) => (
          <PrismicRichTextWithTooltips
            key={index}
            field={item.paragraph_rich_text}
          />
        ))}
      </>
    )
  }

  if (sectionItemData.slice_type === SectionTextTypes.divider) {
    return (
      <InlineSubcontentDivider type={sectionItemData.primary.divider_type} />
    )
  }

  if (sectionItemData.slice_type === SectionTextTypes.quote) {
    const {
      primary: { quote_text, author, author_description },
    } = sectionItemData

    return (
      <Quote
        quoteText={quote_text ?? ''}
        author={author ?? ''}
        authorDescription={author_description ?? ''}
      />
    )
  }

  if (sectionItemData.slice_type === SectionTextTypes.linkToContentItem) {
    return (
      <LinkToContentItem
        sectionItemData={
          sectionItemData.primary
            .link_to_content_item as FilledContentRelationshipField
        }
        onClick={openFullscreenSubcontent}
      />
    )
  }

  if (sectionItemData.slice_type === SectionTextTypes.linkToWeb) {
    return (
      <LinkToWeb
        linkTitle={sectionItemData.primary.title}
        onClick={() =>
          window.open(
            (sectionItemData.primary.link as FilledLinkToWebField).url,
            '_blank'
          )
        }
      />
    )
  }
  return <></>
}

export default SubcontentMainComponent
