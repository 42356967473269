import React from 'react'
import usePrismicData from '../../data/usePrismicData'
import { LegalMainArea } from './components/LegalMainArea'

const Sources = () => {
  const { useSources } = usePrismicData()
  const sources = useSources()

  if (sources.isLoading || !sources.data) return null
  return <LegalMainArea legalData={sources.data} />
}

export default Sources
