import styled from 'styled-components/macro'
import { devices } from '../../../utils/styles'
import Typography from 'components/atoms/Typography'
import { appConfig } from '../../../config'
import usePrismicData from '../../../data/usePrismicData'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../../data/prismic-helper'
import { useLocation } from 'react-router-dom'
import {
  UiElementsDocumentDataBodyLegalMenuSlice,
  UiElementsDocumentDataBodySocialMenuSlice,
} from '../../../generated/types/eschertemplate.types'
import { FilledLinkToWebField } from '@prismicio/types'
import { openCookieBanner } from '../../../utils/openCookieBanner'
import qs from 'query-string'
import useAppStateStore from '../../../hooks/useAppStateStore'

const LegalItems = ({
  legalMenu,
}: {
  legalMenu?: UiElementsDocumentDataBodyLegalMenuSlice
}) => {
  const location = useLocation()
  const params = qs.parse(location.search)
  const { updateState } = useAppStateStore()

  const items = legalMenu?.items.map((item) => {
    const { name, link, legal_type } = item
    if (!legal_type && !link) return null

    const handleClick = () => {
      updateState('openedModalType', legal_type)
    }
    const isActive = params?.legalSection === legal_type

    return (
      <LegalItem key={name}>
        {legal_type ? (
          <LegalItemLink $active={isActive} onClick={handleClick}>
            <Typography variant="caption">{name}</Typography>
          </LegalItemLink>
        ) : (
          <a href={link ?? ''} target="_blank" rel="noreferrer">
            <Typography variant="caption">{name}</Typography>
          </a>
        )}
      </LegalItem>
    )
  })

  if (appConfig.templateConfig?.Footer?.showCookieButton) {
    items?.push(
      <LegalItem key={'cookies'} onClick={openCookieBanner}>
        <Typography variant="caption">Cookies</Typography>
      </LegalItem>
    )
  }
  return <>{items}</>
}

const SocialItems = ({
  socialData,
}: {
  socialData?: UiElementsDocumentDataBodySocialMenuSlice
}) => {
  const items = socialData?.items.map((item) => (
    <SocialItem key={item.name}>
      <SocialItemLink
        href={(item.link as FilledLinkToWebField).url ?? ''}
        target="_blank"
        rel="noreferrer"
      >
        <SocialImage
          src={item.social_icon.url ?? ''}
          alt={item.social_icon.alt ?? 'socials icon'}
          loading="lazy"
          height={item.social_icon.dimensions?.height ?? 16}
          width={item.social_icon.dimensions?.width ?? 16}
        />
      </SocialItemLink>
    </SocialItem>
  ))
  return <>{items}</>
}

interface Props {
  isFixedAtBottom?: boolean
}

const Footer = ({ isFixedAtBottom }: Props) => {
  const { useUIelements } = usePrismicData()
  const uiElements = useUIelements()

  const legalMenu = getUiElementsSliceData(
    SliceTypeEnum.LEGAL_MENU,
    uiElements.data
  )

  const socialMenu = getUiElementsSliceData(
    SliceTypeEnum.SOCIAL_MENU,
    uiElements.data
  )

  return (
    <FooterWrapper $isFixedAtBottom={isFixedAtBottom}>
      <SocialList>
        <SocialItems socialData={socialMenu} />
      </SocialList>
      <LegalList>
        <LegalItems legalMenu={legalMenu} />
      </LegalList>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.footer<{ $isFixedAtBottom?: boolean }>`
  position: ${(p) => (p.$isFixedAtBottom ? 'fixed' : 'static')};
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.palette.black()};
  height: 50px;
  @media ${devices.mobile} {
    height: fit-content;
    position: relative;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 60px;
  }
`

const SocialList = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  flex: 1;

  @media ${devices.mobile} {
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 32px;
  }
`

const SocialItem = styled.li`
  padding: 0 20px;
  display: flex;
  @media ${devices.mobile} {
    padding: 10px 20px;
  }
`

const SocialItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SocialImage = styled.img`
  height: 16px;
`

const LegalList = styled.ul`
  list-style-type: none;
  height: 100%;
  align-items: center;
  flex: 2;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media ${devices.mobile} {
    flex-direction: column;
    justify-items: center;
  }
`

const LegalItem = styled.li`
  cursor: pointer;
  padding: 0 20px;
  @media ${devices.mobile} {
    padding: 10px 20px;
  }
`

const LegalItemLink = styled.div<{ $active: boolean }>`
  ${(props) =>
    props.$active &&
    `
    color: ${props.theme.palette.secondary.light};
  `}
`
