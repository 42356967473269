export function replaceFilename(
  path: string | null | undefined,
  newFile: string
) {
  if (path === null || path === undefined) {
    return newFile
  }
  const lastSlash = path.lastIndexOf('/')
  if (lastSlash > 0) {
    return path.substr(0, lastSlash) + '/' + newFile
  } else {
    return newFile
  }
}

const shyDashRegex = /\?-\?/g
export const replaceRegexWithShyDashes = (str: string) => {
  return str.replace(shyDashRegex, '\u00AD')
}
