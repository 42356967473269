import styled from 'styled-components/macro'
import ImageLoader from '../../common/imageLoader'
import { ImageFieldImage } from '@prismicio/types'
interface ImageContainerProps {
  img?: ImageFieldImage
}

export const ImageContainer = ({ img }: ImageContainerProps) => {
  return (
    <>
      <ImageWrapper>
        <ImageLoader
          src={img?.url ?? ''}
          $imagePosition="absolute"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            objectPosition: 'center',
          }}
          alt={img?.alt ?? 'Image'}
          loading="lazy"
        />
      </ImageWrapper>
    </>
  )
}

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
`
