import React, { useRef } from 'react'
import Typography from 'components/atoms/Typography'
import {
  ChapterDocument,
  UiElementsDocumentDataBodyMenuItemsSlice,
} from '../../generated/types/eschertemplate.types'
import ImageLoader from '../common/imageLoader'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'
import { fullTheme } from '../../config'

interface IMenuChapterProps {
  chapter: ChapterDocument
  isActive?: boolean
  selectChapter: (Chapter: ChapterDocument) => void
  menuItems?: UiElementsDocumentDataBodyMenuItemsSlice
}

const ChaptersSection: React.FC<IMenuChapterProps> = ({
  chapter,
  isActive,
  selectChapter,
  menuItems,
}) => {
  const imageRef = useRef<HTMLDivElement>(null)

  const noImageFallback = menuItems?.primary.placeholder_image.url ?? ''

  const handleChapterSelect = () => {
    selectChapter(chapter)
    imageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    })
  }

  return (
    <Section data-cy="chapterSection" isActive={isActive}>
      <div onClick={handleChapterSelect}>
        <ImageWrapper>
          <ChapterPreviewImage
            src={chapter?.data.thumbnail?.url ?? noImageFallback}
            alt={
              chapter?.data.thumbnail.alt ?? chapter?.data.thumbnail.url ?? ''
            }
            size={'sm'}
            loading="lazy"
          />
          <ImageScrollPoint ref={imageRef} />
          <ChapterNumber
            style={{
              color: isActive
                ? fullTheme.palette.white()
                : fullTheme.typography.head2.color,
            }}
            variant="head2"
            isActive={isActive}
          >
            {chapter.data.chapter_number}
          </ChapterNumber>
        </ImageWrapper>
        <MenuChapterTitle data-cy="menuChapterTitle">
          <Typography
            variant="quote"
            //TODO: remove static style
            style={{ fontStyle: 'italic', fontSize: 22 }}
          >
            {chapter?.data.chapter_title}
          </Typography>
        </MenuChapterTitle>
      </div>
    </Section>
  )
}

export default ChaptersSection

const MenuChapterTitle = styled.div`
  position: relative;
  transition: 100ms;
  width: 100%;
  margin: 8px 0 0;
  top: 0;
`

const Section = styled.div<{ isActive?: boolean }>`
  width: 20%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 1rem 26px 1rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
  transition: 0.2s;
  margin-right: 2px;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;

  &:hover ${MenuChapterTitle} {
    top: 4px;
  }

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media ${devices.mobile} {
    width: 66%;
    padding: 1rem;
    min-width: 70vw;
  }

  @media ${devices.tablet} {
    min-width: 180px;
  }
`

const ChapterNumber = styled(Typography)<{ isActive?: boolean }>`
  position: absolute;
  border-bottom: ${({ isActive }) =>
    isActive ? '3px solid #fff' : '3px solid transparent'};
  left: 5px;
  bottom: -5px;
`

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    position: relative;
    padding-top: 100%; // This creates a square aspect ratio (1:1) by making the height equal to the width.
  }
`

const ChapterPreviewImage = styled(ImageLoader)`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  bottom: 0;
  border-radius: 50%;
`

const ImageScrollPoint = styled.div`
  position: absolute;
  top: 50%;
  // used as a reference to where the app should scroll to when chapter is clicked
  // after the click, the point is aligned with the left side of the screen.
  // to center whole (circular) image, we need to substract 1/4 of the image width plus the padding between the images
  left: calc(-25% - 2rem);
`
