import { ContentItemScrollymationDocument } from '../../../generated/types/eschertemplate.types'
import { ILottie } from '@docmineproductions/ilottie'
import { useLang } from '../../../providers/LanguageProvider'
import useAppStateStore from '../../../hooks/useAppStateStore'
import { getLinkFieldUrl } from '../../../data/prismic-helper'

interface ScrollytellingBackgroundProps {
  contentItemScrollymation: ContentItemScrollymationDocument
  interpolatedScrollPosition: number
}

function ScrollytellingBackgroundNEW({
  contentItemScrollymation,
  interpolatedScrollPosition,
}: ScrollytellingBackgroundProps) {
  const { lang } = useLang()

  const { muted } = useAppStateStore()

  const lottieFileByScreenSize =
    window.innerWidth < 600
      ? getLinkFieldUrl(contentItemScrollymation.data?.portrait_yaml)
      : getLinkFieldUrl(contentItemScrollymation.data?.landscape_yaml)
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
      }}
    >
      <ILottie
        configSrc={lottieFileByScreenSize}
        externalValue={interpolatedScrollPosition}
        name={contentItemScrollymation.data.title ?? ''}
        muted={muted}
        externalValueIsNormalized={true}
        developmentMode={false}
        language={lang}
      />
    </div>
  )
}

export default ScrollytellingBackgroundNEW
