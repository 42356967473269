import { TypographyProps } from '@mui/material/Typography'
import { TypographyVariant } from '@mui/material/styles'
import { Typography as TypographyMui } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import styled from 'styled-components/macro'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { ElementType } from 'react'

export interface ITypography extends Omit<TypographyProps, 'variant'> {
  children?: React.ReactNode
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >
  align?: 'center' | 'inherit' | 'left' | 'right'
  fontWeight?: number | string
  component?: string | ElementType
}

const Typography = (props: ITypography) => {
  // @ts-ignore
  return <StyledTypography {...props} />
}

const StyledTypography = styled(TypographyMui)<ITypography>(
  ({ theme, variant }) => {
    // @ts-ignore
    // eslint-disable-next-line
    const themeConst = theme as any
    const typedVariant = variant as TypographyVariant

    return {
      ...themeConst.typography[typedVariant],
    }
  }
)

export default Typography
