import styled from 'styled-components'
import React from 'react'
import { darken } from '@mui/material/styles'
import Typography from './Typography'

export interface IToggleItem {
  value: string
  displayName: string
}

export interface IToggle {
  items: IToggleItem[]
  $selectedValue: string
  label?: string
  onToggle(selected: IToggleItem): void
}

const Toggle = (props: IToggle) => {
  const [selectedValue, setSelectedValue] = React.useState<string>(
    props.$selectedValue
  )

  const selectItem = (item: IToggleItem) => {
    if (item.value !== selectedValue) {
      setSelectedValue(item.value)
      props.onToggle(item)
    }
  }

  return (
    <StyledButton {...props}>
      <Typography variant="interaction">{props?.label}</Typography>

      <ToggleContainer>
        {props.items.map((item: IToggleItem) => {
          return (
            <ToggleButton
              key={item.value}
              selected={selectedValue === item.value}
              onClick={() => selectItem(item)}
              data-cy={`toggle${item.displayName}${
                selectedValue === item.value ? 'Active' : 'Inactive'
              }`}
            >
              <Typography variant="interaction">{item.displayName}</Typography>
            </ToggleButton>
          )
        })}
      </ToggleContainer>
    </StyledButton>
  )
}

const StyledButton = styled.div<IToggle>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  pointer-events: initial;
`

const ToggleButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 8px 22px;
  border-radius: ${(p) => p.theme.templates?.button?.radius ?? '2px'};
  background-color: ${(p) =>
    p.selected ? p.theme.palette.primary.dark : 'transparent'};
  transition: 0.3s background;
  margin: 4px;

  &:hover {
    background-color: ${(p) =>
      p.selected ? darken(p.theme.palette.primary.dark, 0.1) : 'transparent'};
  }
`

const ToggleContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  border-radius: ${(p) => p.theme.templates?.button?.radius ?? '2px'};
  width: fit-content;
  background: rgba(0, 0, 0, 0.5);
`

export default Toggle
