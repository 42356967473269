import React, { ReactNode } from 'react'
import { ClickAwayListener, Tooltip } from '@mui/material'
import Typography from '../atoms/Typography'
import styled from 'styled-components/macro'
import usePrismicData from '../../data/usePrismicData'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../data/prismic-helper'
import { isTouchDevice } from '../../utils/breakpoints'

interface TooltipProps {
  tooltipUid: string
  children: ReactNode[]
}

const Tooltips: React.FC<TooltipProps> = ({ tooltipUid, children }) => {
  const [open, setOpen] = React.useState(false)
  const { useTooltip, useUIelements } = usePrismicData()
  const uiElementsQuery = useUIelements()
  const tooltipQuery = useTooltip(tooltipUid)

  const staticText = getUiElementsSliceData(
    SliceTypeEnum.STATIC_UI_TEXTS,
    uiElementsQuery.data
  )

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  if (tooltipQuery.isLoading) {
    console.warn(`Could not render tooltip ${tooltipUid}`)
    return <>{children}</>
  }

  const tooltipContentFragment = (
    <>
      <TitleSpan>
        <Typography variant={'head5'}>
          {' '}
          {tooltipQuery.data?.data.title}{' '}
        </Typography>
      </TitleSpan>
      <DescriptionSpan>
        <Typography variant={'body'}>
          {' '}
          {tooltipQuery.data?.data.description}{' '}
        </Typography>
      </DescriptionSpan>
      {isTouchDevice && (
        <CloseButton>
          <Typography variant={'body'}>
            {staticText?.primary.close_tooltip}
          </Typography>
        </CloseButton>
      )}
    </>
  )

  const tooltipLink = (
    <TooltipWordSpan className={tooltipUid} onClick={handleTooltipOpen}>
      {children}
    </TooltipWordSpan>
  )

  if (!isTouchDevice) {
    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        disableFocusListener
        disableTouchListener
        title={tooltipContentFragment}
        leaveDelay={250}
      >
        {tooltipLink}
      </Tooltip>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={tooltipContentFragment}
      >
        {tooltipLink}
      </Tooltip>
    </ClickAwayListener>
  )
}

export default Tooltips

const CloseButton = styled.div`
  margin-top: 16px;
  padding: 8px;
`
const DefaultSpan = styled.span`
  width: 100%;
  display: block;
  padding: 8px;
`
const DescriptionSpan = styled(DefaultSpan)`
  max-height: 200px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const TitleSpan = styled(DefaultSpan)``

const TooltipWordSpan = styled.span`
  text-decoration: underline;
  text-decoration-color: inherit;
  text-decoration-style: wavy;
  text-underline-offset: 4px;
  cursor: pointer;
`
