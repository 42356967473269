export enum IconTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  GALLERY = 'gallery',
  TEXT = 'text',
  LINKTOWEB = 'linkToWeb',
  DOUBLE_ARROW_RIGHT = 'doubleArrowRight',
  DOUBLE_ARROW_LEFT = 'doubleArrowLeft',
  CLOSE = 'close',
  LEFT_ARROW = 'leftArrow',
  RIGHT_ARROW = 'rightArrow',
  CONTROL_ARROW_RIGHT = 'controlArrowRight',
  CONTROL_ARROW_LEFT = 'controlArrowLeft',
  KNOWLEDGE = 'knowledge',
  ARROW_DOWN = 'arrowDown',
}

export const contentItemTypeToIconType = {
  content_item_image: IconTypes.IMAGE,
  content_item_video: IconTypes.VIDEO,
  content_item_gallery: IconTypes.GALLERY,
  content_item_text: IconTypes.TEXT,
}

export enum SectionTextTypes {
  paragraph = 'paragraph',
  linkToContentItem = 'link_to_content_item',
  divider = 'divider',
  linkToWeb = 'link_to_web',
  quote = 'quote',
}

export enum VideoMode {
  SUBCHAPTER_VIDEO_STILL = 'subchapter_video_still',
  SUBCHAPTER_VIDEO_ENDLESS_MUTED_LOOP = 'subchapter_video_endless_muted_loop',
  SUBCHAPTER_VIDEO_FULLSCREEN_VIDEO = 'subchapter_video_fullscreen_video',
  SUBCHAPTER_VIDEO_INLINE = 'subchapter_video_inline',
}
