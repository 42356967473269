import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'
import { appConfig } from '../../config'
import { ChapterDocument } from '../../generated/types/eschertemplate.types'
import Typography from '../atoms/Typography'
import Hypher from 'hypher'
import german from 'hyphenation.de'
import { ThemeType } from '../../theme'
import { FilledContentRelationshipField } from '@prismicio/types'
import { useSubchapterScrollPosition } from '../../hooks/useSubchapterScrollPosition'

const h = new Hypher(german)

interface SideNavProps {
  chapter: ChapterDocument
}

const SideNav = ({ chapter }: SideNavProps) => {
  const { positions } = useSubchapterScrollPosition()
  const history = useHistory()
  const { sectionUid } = useParams<{ sectionUid?: string }>()

  const [activeSubchapterIndex, setActiveSubchapterIndex] = useState<
    number | null
  >(null)
  const [prevPositions, setPrevPositions] = useState<number[]>([...positions])
  const [isVisible, setIsVisible] = useState<boolean>(!!sectionUid)

  useEffect(() => {
    // 1. Find the first position that has changed since the last render
    const changedIndex = positions.findIndex(
      (pos, index) => pos !== prevPositions[index]
    )

    // 2. If a changed position is found, update `activeSubchapterIndex` with that position's index
    if (changedIndex !== -1) {
      setActiveSubchapterIndex(changedIndex)
    }

    // 3. Update `prevPositions` to the current `positions` for comparison in the next render
    setPrevPositions([...positions])

    // 4. Determine if all `positions` are the same,
    // this basically means that we are at the cover or at the end of the chapter (for ex. [0,0,0,0] is cover)
    const allSame = positions.every((pos) => pos === positions[0])

    // 5. Create a timeout to toggle the visibility of the navigation based on whether all positions are the same
    setIsVisible(!allSame)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions])

  const navigateToSubchapter = (index: number) => {
    history.push(
      `/${appConfig.contentUnitUrl}/${chapter.uid}/${
        (chapter.data?.subchapters[index]
          ?.subchapter as FilledContentRelationshipField)?.uid
      }`
    )

    const subchapterElement = document.getElementById(
      (chapter.data?.subchapters[index]
        ?.subchapter as FilledContentRelationshipField)?.id
    )
    if (subchapterElement) {
      subchapterElement.scrollIntoView({ behavior: 'smooth' })
    } else {
      console.error(
        `Didn't find subchapter with id ${
          (chapter.data?.subchapters[index]
            ?.subchapter as FilledContentRelationshipField)?.uid
        }, this should not happen!`
      )
    }

    // After scrolling to the subchapters, the lastChangedIndex would still be the previous chapter,
    // which means that the previous bar would be full and the current chapter bar is still a dot.
    // make current chapter active after some time
    setTimeout(() => {
      setActiveSubchapterIndex(index)
    }, 750)
  }

  const navItems = positions.map((position, index) => {
    const isLastChanged = index === activeSubchapterIndex
    const isAdjacentDot =
      activeSubchapterIndex !== null &&
      (index === activeSubchapterIndex - 1 ||
        index === activeSubchapterIndex + 1)

    return (
      <NavBarItemWrapper
        isBar={isLastChanged}
        isComplete={position === 1}
        onClick={() => navigateToSubchapter(index)}
      >
        <NavBarAnimatedDot
          key={index}
          progress={position}
          isBar={isLastChanged}
          isComplete={position === 1}
          isAdjacentDot={isAdjacentDot}
        />
        <NavBarItemName>
          <SubchapterNameContainer>
            <Typography variant={'caption'}>
              {h.hyphenateText(
                // @ts-ignore
                chapter.data.subchapters[index]?.subchapter?.data
                  ?.subchapter_title ?? ''
              )}
            </Typography>
          </SubchapterNameContainer>
        </NavBarItemName>
      </NavBarItemWrapper>
    )
  })

  return (
    <NavWrapper isVisible={isVisible}>
      <Flex>{navItems}</Flex>
    </NavWrapper>
  )
}

export default SideNav

const NavWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ isVisible }) => (isVisible ? '0' : '-250px')};
  bottom: 0;
  z-index: 100;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: left 0.7s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease; // Add width to the transition
  padding-left: 24px;
  overflow: hidden;

  &:hover {
    width: 250px;
  }
`
const NavBarItemName = styled.div`
  position: absolute;
  left: 35px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 150px;
  pointer-events: none;
`

const getBackground = (
  isBar: boolean,
  progress: number,
  isComplete: boolean,
  theme: ThemeType
) => {
  // If the dot is to be displayed as a bar, determine the background based on progress
  if (isBar) {
    // If progress is complete, use the theme's contrast color; otherwise, create a linear gradient
    return progress === 1
      ? theme.palette.contrast
      : `linear-gradient(to bottom, ${theme.palette.contrast} ${
          (progress ?? 0) * 100
        }%, grey ${(progress ?? 0) * 100}%)`
  }
  // For a dot, use the contrast color if complete; otherwise, use grey
  return isComplete ? theme.palette.contrast : 'grey'
}

// - isBar: A boolean indicating if the dot should display as a bar (line).
// - progress: A number representing the progress of the dot/bar.
// - isComplete: A boolean indicating if the dot/bar is in its "complete" state.
const NavBarAnimatedDot = styled.div<{
  isBar: boolean
  isComplete: boolean
  progress: number
  isAdjacentDot?: boolean
}>`
  position: relative;
  z-index: 2;
  transition: height 0.3s ease, width 0.3s ease, border-radius 0.2s ease-in-out,
    transform 0.3s ease;
  height: ${({ isBar, isAdjacentDot }) =>
    isBar ? '25vh' : isAdjacentDot ? '6px' : '3px'};
  width: ${({ isBar, isAdjacentDot }) =>
    isBar ? '1px' : isAdjacentDot ? '6px' : '3px'};
  background: ${({ isBar, progress, isComplete, theme }) =>
    getBackground(isBar, progress, isComplete, theme as ThemeType)};
  border-radius: ${({ isBar }) => (isBar ? '1px' : '5px')};
  margin: 1.5vh 0;
  padding: 2px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border 0.3s ease, top 0.3s ease, left 0.3s ease, right 0.3s ease,
      bottom 0.3s ease;
  }
`

const NavBarItemWrapper = styled.div<{
  isBar: boolean
  isComplete: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  position: relative;

  &:hover ${NavBarItemName} {
    opacity: 1;
  }

  &:hover ${NavBarAnimatedDot}::before {
    ${({ isBar, isComplete, theme }) =>
      !isBar &&
      `
        border: 1px solid ${isComplete ? theme.palette.contrast : 'grey'};
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
      `}
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const SubchapterNameContainer = styled.div`
  padding: 5px;
  background-color: black;
`
