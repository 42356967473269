import usePrismicData from '../../../data/usePrismicData'
import VideobookTopTextContainer from './components/TextContainer'
import { VideoBooksList } from './components/VideoBooks'
import styled from 'styled-components/macro'
import { devices } from '../../../utils/styles'

export const VideobookEnd = () => {
  const { useEndOfVideobook } = usePrismicData()
  const videoBookData = useEndOfVideobook()

  return (
    <Container>
      <ImplementationAndVideobooksContainer>
        <VideobookTopTextContainer
          titleQuote={videoBookData.data?.data.title_quote ?? ''}
          titleQuoteFirst={videoBookData.data?.data.title_quote_first ?? ''}
          implementationTitle={
            videoBookData.data?.data.implementation_title ?? ''
          }
          implementation={videoBookData.data?.data.implementation ?? []}
          posterButtonText={videoBookData.data?.data.poster_button_text ?? ''}
          posterButtonURL={videoBookData.data?.data.poster_button_url ?? ''}
        />
        {
          <VideoBooksList
            videobooksTitle={videoBookData.data?.data.videobooks_title ?? ''}
            videobooks={videoBookData.data?.data.body ?? []}
            readMore={videoBookData.data?.data.read_more ?? ''}
          />
        }
      </ImplementationAndVideobooksContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.palette.background};
  display: flex;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  min-height: 100vh;
`

const ImplementationAndVideobooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  position: relative;
  transition: all 0.4s cubic-bezier(0.5, 1, 0.89, 1);
  width: 90%;
  max-width: 800px;
  padding-top: 150px;
  padding-bottom: 300px;
  right: 0;
  bottom: 0;
  opacity: 1;
  @media ${devices.mobile} {
    padding-bottom: 100px;
  }
`
