import Typography from 'components/atoms/Typography'
import styled from 'styled-components/macro'
import { IconTypes } from 'data/prismic-enums'
import CircleButton from 'components/atoms/CircleButton'
import usePrismicData from '../../data/usePrismicData'
import { FilledContentRelationshipField } from '@prismicio/types'
import {
  ContentItemResponseTypesWithoutScrollymationAndScrollyStory,
  ContentItemTypes,
} from '../../data/prismic-types'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../data/prismic-helper'
import useAppStateStore from '../../hooks/useAppStateStore'

interface Props {
  sectionItemData: FilledContentRelationshipField | undefined
  onClick: () => void
}

export const LinkToContentItem = ({ sectionItemData }: Props) => {
  const { useContentItem, useUIelements } = usePrismicData()
  const uiElements = useUIelements()
  const types = getUiElementsSliceData(
    SliceTypeEnum.CONTENT_ITEM_NAMES,
    uiElements.data
  )

  const { updateState } = useAppStateStore()

  const { useIcons } = usePrismicData()
  const icons = useIcons()

  const contentItem = useContentItem(
    sectionItemData?.type as ContentItemTypes,
    sectionItemData?.uid
  )

  if (contentItem.isLoading) {
    return <div>LOADING...</div>
  }

  const openFullscreenSubcontent = () => {
    if (contentItem) {
      updateState('openedModalUid', contentItem.data?.uid ?? '')
      updateState('openedModalType', contentItem.data?.type ?? null)
    }
  }

  return (
    <Wrapper
      data-cy="subcontentWrapper"
      onClick={openFullscreenSubcontent}
      data-subcontentid={contentItem.data?.id}
    >
      <div style={{ width: '80%' }}>
        <ElipsisTypography variant="body">
          {contentItem.data?.data.title}
        </ElipsisTypography>
        <TextTypography variant="body">
          {types?.primary[
            (contentItem.data as ContentItemResponseTypesWithoutScrollymationAndScrollyStory)
              .type
          ] ?? contentItem.data?.type}
          {contentItem.data?.data.link_caption &&
          contentItem.data?.data.link_caption.length > 0
            ? ` · ${contentItem.data?.data.link_caption}`
            : ''}
        </TextTypography>
      </div>

      <CircleButton data-cy="circleButton">
        <img
          src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
          alt="arrow"
          style={{ marginLeft: 3 }}
          height={
            icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.height ?? 20
          }
          width={
            icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.width ?? 20
          }
          loading="lazy"
        />
      </CircleButton>
    </Wrapper>
  )
}

const ElipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`

const TextTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';
`

const Wrapper = styled.div`
  height: 90px;
  padding: 24px 16px 24px 24px;
  margin: 16px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(132, 248, 235, 0.08);
  border: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: 250ms;

  &:hover {
    opacity: 0.8;
  }
`
