import styled from 'styled-components/macro'
import Button from '../../atoms/Button'
import Typography from '../../atoms/Typography'
import { Skeleton } from '@mui/material'
import { devices } from '../../../utils/styles'
import usePrismicData from '../../../data/usePrismicData'

interface CategoryButtonProps {
  isActive: boolean
  onClick: () => void
  categoryName?: string | null
}

const CategoryButtonComponent = ({
  isActive,
  onClick,
  categoryName,
}: CategoryButtonProps) => {
  return (
    <CategoryButton onClick={onClick} size={'large'} $isActive={isActive}>
      <CategoryName $isActive={isActive} variant={'head5'} fontWeight={600}>
        {categoryName}
      </CategoryName>
    </CategoryButton>
  )
}

interface CategoriesSliderProps {
  activeCategory?: string | null
  chooseCategory: (name?: string | null) => void
  allStories?: string | null
}

const CategoriesSlider = ({
  activeCategory,
  chooseCategory,
  allStories,
}: CategoriesSliderProps) => {
  const { useKnowledgebaseCategories } = usePrismicData()
  const categories = useKnowledgebaseCategories()
  return categories.isLoading ? (
    <Skeleton
      style={{ marginTop: 80 }}
      variant="rectangular"
      width="100%"
      height="60px"
    />
  ) : (
    <CategoriesSliderContainer data-cy="categoriesSliderContainer">
      <CategoryButtonComponent
        onClick={() => chooseCategory(allStories)}
        isActive={activeCategory === allStories}
        categoryName={allStories}
      />
      {categories.data?.map((category) => (
        <CategoryButtonComponent
          key={category.data.name}
          onClick={() => chooseCategory(category.data.name)}
          isActive={activeCategory === category.data.name}
          categoryName={category.data.name}
        />
      ))}
    </CategoriesSliderContainer>
  )
}

const CategoriesSliderContainer = styled.div`
  display: flex;
  width: 90%;
  max-width: 1500px;
  margin: auto;
  margin-top: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  gap: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${devices.tablet} {
  }
  @media ${devices.mobile} {
    width: 100%;
    padding: 0 22px;
    gap: 12px;
  }
`

const CategoryButton = styled(Button)<{ $isActive: boolean }>`
  border: ${({ $isActive }) =>
    $isActive ? '#b8cec9 solid 1px' : 'transparent solid 1px'};
  border-radius: 40px;
  white-space: nowrap;
  background-color: ${({ $isActive }) =>
    $isActive ? 'rgba(255, 255, 255, 0.25)' : 'transparent'};
  transition: background-color 0.3s ease, border-color 0.3s ease;

  @media (hover: hover) {
    transition: background-color 0.3s ease, border-color 0.3s ease;
    &:hover {
      background-color: ${({ $isActive }) =>
        $isActive ? 'rgba(255, 255, 255, 0.25)' : 'transparent'};
      border: #b8cec9 solid 1px;
    }
  }
`

const CategoryName = styled(Typography)<{ $isActive: boolean }>`
  color: white;
  padding: 10px;
  transform: scale(1.05);
  transition: transform 0.3s ease;
  }
`
export default CategoriesSlider
