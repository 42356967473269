import styled from 'styled-components/macro'
import Typography from 'components/atoms/Typography'
import { PrismicRichText } from '@prismicio/react'
import * as React from 'react'
import { RichTextField } from '@prismicio/types'
import usePrismicData from '../../../../data/usePrismicData'

interface Props {
  titleQuote: string
  titleQuoteFirst: string
  implementationTitle: string
  implementation: RichTextField
  posterButtonText: string
  posterButtonURL: string
}
function VideobookTopTextContainer({
  titleQuote,
  titleQuoteFirst,
  implementationTitle,
  implementation,
  posterButtonText,
  posterButtonURL,
}: Props) {
  const { useIcons } = usePrismicData()
  const icons = useIcons()
  return (
    <Container>
      <Typography variant={'head2'} align={'center'}>
        {titleQuote}
      </Typography>
      <Typography variant={'lead'}>{titleQuoteFirst}</Typography>
      <Typography
        variant={'lead'}
        align={'center'}
        style={{ margin: '60px 0 15px 0' }}
      >
        {implementationTitle}
      </Typography>
      <Typography align="center" variant={'body'}>
        <PrismicRichText field={implementation} />
      </Typography>
      <a href={posterButtonURL} target="__blank">
        <ImplementationButton arrow={icons.data?.data.rightArrow.url ?? ''}>
          <span>
            <Typography variant={'interaction'}>{posterButtonText}</Typography>
          </span>
        </ImplementationButton>
      </a>
    </Container>
  )
}

export default VideobookTopTextContainer

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImplementationButton = styled.div<{ arrow: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    content: url(${({ arrow }) => arrow});
    padding-right: 10px;
    @media (max-width: 767px) {
      padding-left: 5px;
    }
  }
  margin-top: 10px;

  &:hover {
    border-bottom: 2px solid white;
  }
`
