import { css, keyframes } from 'styled-components'

export const kenBurnsZoomKeyframes = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.8);
    }
    100% {
        transform: scale(1);
    }
`

// Helper function to apply the animation conditionally
export const kenBurnsZoomAnimation = ($imageEffectType?: string) => {
  return css`
    ${$imageEffectType !== 'none' &&
    css`
      animation: ${kenBurnsZoomKeyframes} 80s linear infinite;
    `}
  `
}
