import { useState, useEffect } from 'react'
import usePrismicData from '../data/usePrismicData'

const useLoadingStatus = () => {
  const [loadingStatus, setLoadingStatus] = useState({
    allChaptersData: true,
    UIelementsData: true,
    iconsData: true,
    endOfVideobookData: true,
  })

  const {
    useAllChapters,
    useUIelements,
    useIcons,
    useEndOfVideobook,
  } = usePrismicData()

  const allChaptersData = useAllChapters()
  const UIelementsData = useUIelements()
  const iconsData = useIcons()
  const endOfVideobookData = useEndOfVideobook()

  useEffect(() => {
    setLoadingStatus({
      allChaptersData: allChaptersData.isLoading,
      UIelementsData: UIelementsData.isLoading,
      iconsData: iconsData.isLoading,
      endOfVideobookData: endOfVideobookData.isLoading,
    })
  }, [
    allChaptersData.isLoading,
    UIelementsData.isLoading,
    iconsData.isLoading,
    endOfVideobookData.isLoading,
  ])

  const loadedCount = Object.values(loadingStatus).filter((status) => !status)
    .length
  const totalCount = Object.keys(loadingStatus).length
  const allLoaded = loadedCount === totalCount

  return { loadingStatus, allLoaded, loadedCount, totalCount }
}

export default useLoadingStatus
