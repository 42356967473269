import React from 'react'
import Collapse from '@mui/material/Collapse'
import { darken, Skeleton } from '@mui/material'
import Typography from '../atoms/Typography'
import CircleButton from '../atoms/CircleButton'
import { useHistory } from 'react-router-dom'
import usePrismicData from '../../data/usePrismicData'
import { appConfig } from '../../config'
import { replaceFilename } from '../../utils/string'
import { safeHtml } from '../../utils/validation'
import { IconTypes } from '../../data/prismic-enums'
import useAppStateStore from '../../hooks/useAppStateStore'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'

import german from 'hyphenation.de'
import Hypher from 'hypher'
import ImageLoader from '../common/imageLoader'
import { breakpoints } from '../../utils/breakpoints'
const h = new Hypher(german)

interface SubchapterMenuSectionProps {
  subchapter: any
  noImageFallback: string
  chapterUid: string
}

const SubchapterMenuSection = ({
  subchapter,
  noImageFallback,
  chapterUid,
}: SubchapterMenuSectionProps) => {
  const history = useHistory()
  const { updateState } = useAppStateStore()
  const { useContentItem, useIcons } = usePrismicData()
  const icons = useIcons()

  const handleSubchapterClick = () => {
    history.push(
      `/${appConfig.contentUnitUrl}/${chapterUid}/${subchapter.subchapter.uid}`
    )
    updateState('menuOpen', false)
    updateState('chaptersBarOpen', false)
    const subchapterElement = document.getElementById(subchapter.subchapter.id)
    if (subchapterElement) {
      subchapterElement.scrollIntoView()
    }
  }

  const subchapterContentItem = useContentItem(
    subchapter.subchapter.data?.bg_main.type,
    subchapter.subchapter.data?.bg_main.uid
  )
  const { data } = subchapterContentItem ?? {}

  const subchapterHyphenatedTitle = h.hyphenateText(
    subchapter.subchapter.data?.subchapter_title ?? ''
  )

  const menuThumbnailSubchapterImage = () => {
    if (
      data.type === 'content_item_video' &&
      !data?.data?.thumbnail_image.url
    ) {
      const firstFrameSrc =
        breakpoints.phone && data?.data?.mobile_url_video?.url
          ? replaceFilename(data?.data?.mobile_url_video?.url, 'firstframe.jpg')
          : replaceFilename(
              data?.data?.desktop_url_video?.url,
              'firstframe.jpg'
            )

      return (
        <ImageLoader
          src={firstFrameSrc}
          width={50}
          height={50}
          style={{ objectFit: 'cover' }}
          alt="subchapter"
          loading="lazy"
        />
      )
    } else {
      return (
        <ImageLoader
          src={data?.data?.thumbnail_image.url ?? noImageFallback}
          size={'xs'}
          width={50}
          height={50}
          alt={data?.data?.thumbnail_image.alt}
          style={{ objectFit: 'cover' }}
        />
      )
    }
  }

  return (
    <Collapse in={true} key={subchapter.uid}>
      <SectionWrapper
        data-cy="subchapterElement"
        onClick={handleSubchapterClick}
      >
        {subchapterContentItem.isLoading ? (
          <Skeleton variant="text" width={'100%'} height={60} />
        ) : (
          <>
            {!breakpoints.phone && menuThumbnailSubchapterImage()}
            <TitleContainer data-cy="sectionTitleContainer">
              <Typography
                variant="head5"
                {...safeHtml(subchapterHyphenatedTitle)}
              />
            </TitleContainer>
            <CircleButton>
              <img
                src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
                alt="arrow"
                style={{ marginLeft: 3 }}
                loading="lazy"
                height={
                  icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.height ??
                  20
                }
                width={
                  icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.width ??
                  20
                }
              />
            </CircleButton>
          </>
        )}
      </SectionWrapper>
    </Collapse>
  )
}

export default SubchapterMenuSection

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.white(0.3)};
  cursor: pointer;
  &:hover {
    background-color: ${(p) => darken(p.theme.palette.background, 0.3)};
  }
`

const TitleContainer = styled.div`
  margin-left: 20px;
  flex: 1;
  @media ${devices.mobile} {
    margin-left: 0;
    padding-right: 20px;
  }
`
