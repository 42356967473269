import React from 'react'
import { Link } from 'react-router-dom'
import { appConfig } from 'config'
import german from 'hyphenation.de'
import Hypher from 'hypher'
import useAppStateStore from '../../hooks/useAppStateStore'
import {
  ChapterDocument,
  UiElementsDocumentDataBodyMenuItemsSlice,
} from '../../generated/types/eschertemplate.types'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'
import Typography from '../atoms/Typography'
import Button from '../atoms/Button'

const h = new Hypher(german)

interface IMenuChapterProps {
  chapter: ChapterDocument
  subchaptersElement?: React.RefObject<HTMLDivElement>
  menuItems?: UiElementsDocumentDataBodyMenuItemsSlice
}

const ChapterDescription: React.FC<IMenuChapterProps> = ({
  chapter,
  menuItems,
}) => {
  const { updateState } = useAppStateStore()
  const { subchapter_summary_title, button_text_start_chapter } =
    menuItems?.primary ?? {}

  return (
    <Section data-cy="chapterDescriptionSection">
      <ShowSectionsButtonContainer>
        <Link
          to={`/${appConfig.contentUnitUrl}/${chapter?.uid}`}
          onClick={() => {
            updateState('menuOpen', false)
            window.scrollTo(0, 0)
          }}
        >
          <ButtonPrimary variant="contained" size="medium">
            {button_text_start_chapter ?? 'Start chapter'}
          </ButtonPrimary>
        </Link>
      </ShowSectionsButtonContainer>

      <TitleSummary>
        <Typography variant="head5">
          {subchapter_summary_title ?? 'Summary'}
        </Typography>
      </TitleSummary>

      <TextContainer>
        <Typography variant="body">
          {h.hyphenateText(chapter.data?.summary ?? '')}
        </Typography>
      </TextContainer>
    </Section>
  )
}

export default ChapterDescription

const Section = styled.div`
  margin-bottom: 100px;
  display: flex;
  width: 400px;
  flex-direction: column;
  padding: 0px 40px;
  flex-shrink: 0;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media ${devices.mobile} {
    padding: 1rem 1.5rem;
    max-width: initial;
    margin-bottom: 0px;
    margin: auto;
    width: 90%;
  }

  @media ${devices.tablet} {
    width: 300px;
    padding: 0px 20px;
  }

  & img {
    max-width: 100%;
    height: auto;
  }
`

const TextContainer = styled.div`
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  min-height: 150px;

  @media ${devices.mobile} {
    min-height: auto;
  }
`

const ShowSectionsButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 12px;

  @media ${devices.orientationPortrait.tablet} {
    margin-bottom: 30px;
  }

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

const TitleSummary = styled.div`
  margin: 16px 0;
`

const ButtonPrimary = styled(Button)`
  @media ${devices.mobile} {
    width: 100%;
  }
`
