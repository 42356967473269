import styled from 'styled-components/macro'
import Typography from '../../atoms/Typography'
import { Skeleton } from '@mui/material'

interface KbHeaderProps {
  isLoading: boolean
  title?: string
  subtitle?: string
}
const KbHeader = ({ isLoading, title, subtitle }: KbHeaderProps) => {
  return (
    <HeaderContainer>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={'80%'} height={100} />
          <Skeleton variant="text" width={'80%'} height={60} />
        </>
      ) : (
        <>
          <Typography data-cy="kbHeader" variant={'head2'}>
            {title}
          </Typography>
          <Typography data-cy="kbSubtitle" variant={'lead'}>
            {subtitle}
          </Typography>
        </>
      )}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  width: 90%;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding-top: 120px;
  gap: 16px;
  background-color: ${(p) => p.theme.palette.background};
`
export default KbHeader
