import DOMPurify, { Config } from 'dompurify'
import { replaceRegexWithShyDashes } from './string'

export function safeHtml(html: string, config?: Config) {
  const htmlWithShyDashes = replaceRegexWithShyDashes(html)
  return {
    dangerouslySetInnerHTML: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __html: DOMPurify.sanitize(htmlWithShyDashes, config ?? {}) as string,
    },
  }
}
