import React from 'react'
import InteractiveBackground from '../../Subcontent/InteractiveBackground'
import { ImageContainer } from './ImageContainer'
import { ContentItemImageDocument } from '../../../generated/types/eschertemplate.types'
import BottomBar from '../../Subcontent/BottomBar'
import useAppStateStore from '../../../hooks/useAppStateStore'
import { getResponsiveImage } from '../../../utils/imageUtils'

interface ImageBackgroundProps {
  contentItemImage: ContentItemImageDocument
  isTextPositionLeft?: boolean
}

const ImageBackground = ({
  contentItemImage,
  isTextPositionLeft,
}: ImageBackgroundProps) => {
  const { updateState } = useAppStateStore()

  const activateFullScreen = () => {
    updateState('openedModalUid', contentItemImage.uid)
    updateState('openedModalType', contentItemImage.type)
  }

  return (
    <InteractiveBackground
      activateCallback={activateFullScreen}
      showBackgroundOverlay={true}
    >
      <ImageContainer
        img={getResponsiveImage(contentItemImage.data.image)}
      ></ImageContainer>
      <BottomBar
        contentItem={contentItemImage}
        handleClick={activateFullScreen}
        showInteractivePanel={true}
        desktopTextPositionLeft={isTextPositionLeft}
      />
    </InteractiveBackground>
  )
}

export default ImageBackground
