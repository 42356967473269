import { createStyles } from '@mui/styles'
import { ThemeType } from 'theme'

export const AppStyles = ({
  theme,
  showScrollbar,
}: {
  theme: ThemeType
  showScrollbar: boolean
}) =>
  createStyles({
    '@global': {
      html: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'visible',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        scrollbarWidth: showScrollbar ? 'auto' : 'none',
      },
      '*::-webkit-scrollbar': {
        display: showScrollbar ? 'auto' : 'none',
      },
      '*:not(input)': {
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none',
      },
      body: {
        'overscroll-behavior': 'none',
        height: '100%',
        width: '100%',
        overflow: 'visible',
        margin: 0,
        padding: 0,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.white(),
        fontFamily: `${theme.typography.body.fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
        '-webkitFontSmoothing': 'antialiased',
        '-mozOsxFontSmoothing': 'grayscale',
      },
      'a, a:active, a:visited, a:hover': {
        textDecoration: 'none',
        color: theme.palette.white(),
      },
      button: {
        '&:focus': {
          outline: 0,
        },
      },
      h1: {
        ...theme.typography.head1,
      },
      h2: {
        ...theme.typography.head2,
      },
      h3: {
        ...theme.typography.head3,
      },
      h4: {
        ...theme.typography.head4,
      },
      p: {
        ...theme.typography.body,
      },
      li: {
        ...theme.typography.body,
      },
    },
  })
