import styled, { keyframes } from 'styled-components/macro'
import Typography from 'components/atoms/Typography'
import Hypher from 'hypher'
import german from 'hyphenation.de'

import {
  ImprintDocument,
  PrivacyPolicyDocument,
  SourcesDocument,
  TermsOfUseDocument,
  TermsOfUseDocumentDataBodySectionSlice,
} from '../../../generated/types/eschertemplate.types'
import { devices } from '../../../utils/styles'

const h = new Hypher(german)

export const LegalMainArea = ({
  legalData,
}: {
  legalData:
    | TermsOfUseDocument
    | PrivacyPolicyDocument
    | SourcesDocument
    | ImprintDocument
}) => {
  return (
    <Wrapper>
      <Typography className="animated" style={{ padding: 25 }} variant="head1">
        {h.hyphenateText(legalData?.data?.title ?? '')}
      </Typography>
      <ContentContainer>
        {/*TODO: For now there is a same structure for all legal items. In a case
        it would change, we need to add a switch/if/conditional render based on type etc... */}
        {(legalData.data.body as TermsOfUseDocumentDataBodySectionSlice[])?.map(
          (section: TermsOfUseDocumentDataBodySectionSlice, index) => (
            <SectionsWrapper key={index} className="animated">
              <Typography align={'center'} variant="head2">
                {h.hyphenateText(section.primary.section_title ?? '')}
              </Typography>
              <SectionEntriesWrapper>
                {section.items.map((sectionEntry, entryIndex) => (
                  <SectionEntry key={entryIndex} className="animated">
                    <Typography
                      align={'center'}
                      variant="head3"
                      style={{ padding: 20 }}
                    >
                      {h.hyphenateText(sectionEntry.section_title ?? '')}
                    </Typography>
                    <Typography align="center" variant="body">
                      {h.hyphenateText(sectionEntry.section_running_text ?? '')}
                    </Typography>
                  </SectionEntry>
                ))}
              </SectionEntriesWrapper>
            </SectionsWrapper>
          )
        )}
      </ContentContainer>
    </Wrapper>
  )
}

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 200px;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .animated {
    opacity: 0;
    animation: ${fadeInUp} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
  }

  .animated:nth-child(1) {
    animation-delay: 0.5s;
  }

  .animated:nth-child(2) {
    animation-delay: 1s;
  }

  .animated:nth-child(3) {
    animation-delay: 1.5s;
  }

  .animated:nth-child(4) {
    animation-delay: 2s;
  }

  .animated:nth-child(5) {
    animation-delay: 2.5s;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: 800px;
`

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px;
  @media ${devices.mobile} {
    padding: 0;
  }
`

const SectionEntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px;
`

const SectionEntry = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`
