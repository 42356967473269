import Typography from 'components/atoms/Typography'
import styled from 'styled-components/macro'
import { IconTypes } from 'data/prismic-enums'
import CircleButton from 'components/atoms/CircleButton'
import usePrismicData from '../../data/usePrismicData'
import { useLang } from '../../providers/LanguageProvider'

interface Props {
  linkTitle?: string | null
  onClick: () => void
}

export const LinkToWeb = ({ linkTitle, onClick }: Props) => {
  const { useIcons } = usePrismicData()
  const { lang } = useLang()
  const icons = useIcons()

  return (
    <Wrapper onClick={onClick} data-subcontentid={`weblink-${linkTitle}`}>
      <div style={{ width: '80%' }}>
        <ElipsisTypography variant="body">{linkTitle}</ElipsisTypography>
        <TextTypography variant="body">
          {lang === 'de-de' ? 'Link' : 'Link'}
        </TextTypography>
      </div>

      <CircleButton>
        <img
          src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
          alt="arrow"
          style={{ marginLeft: 3 }}
          loading="lazy"
          height={
            icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.height ?? 20
          }
          width={
            icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.width ?? 20
          }
        />
      </CircleButton>
    </Wrapper>
  )
}

const ElipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`

const TextTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';
`

const Wrapper = styled.div`
  height: 90px;
  padding: 24px 16px 24px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(132, 248, 235, 0.08);
  border: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 15px;
  transition: 250ms;

  &:hover {
    opacity: 0.8;
  }
`
