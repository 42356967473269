import { ChapterDocument } from '../../generated/types/eschertemplate.types'
import * as React from 'react'
import Subchapter from '../Subchapter/Subchapter'
import { Cover } from '../Cover/Default'
import { NextChapterCover } from '../NextChapterCover/Default'
import { VideobookEnd } from '../VideobookEnd/Default'
import useInterpolatedScrollPosition from '../../hooks/useInterpolatedScrollPosition'
import { useEffect, useRef } from 'react'
import Footer from '../Footer/Default'
import useLoadingStatus from '../../hooks/useLoadingStatus'
import LoadingSpinner from '../atoms/loadingSpinner'
import { useParams } from 'react-router-dom'
import useAppStateStore from '../../hooks/useAppStateStore'
interface SlideProps {
  chapter: ChapterDocument
  nextChapter: ChapterDocument
}

const Slide = ({ chapter, nextChapter }: SlideProps) => {
  const slideRef = useRef<HTMLDivElement>(null)
  const { updateState } = useAppStateStore()
  const interpolatedScrollPosition = useInterpolatedScrollPosition(slideRef)
  const { loadedCount, totalCount, allLoaded } = useLoadingStatus()
  const { sectionUid } = useParams<{ sectionUid?: string }>()
  const [subchaptersLoaded, setSubchaptersLoaded] = React.useState<boolean[]>(
    []
  )

  useEffect(() => {
    if (chapter?.data?.subchapters) {
      setSubchaptersLoaded(
        new Array(chapter.data.subchapters.length).fill(false)
      )
    }
  }, [chapter?.data?.subchapters])

  useEffect(() => {
    if (sectionUid) {
      updateState('chaptersBarOpen', false)
      return
    }

    if (interpolatedScrollPosition < 0.05) {
      updateState('chaptersBarOpen', true)
      return
    }

    updateState('chaptersBarOpen', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpolatedScrollPosition, sectionUid])

  const subchapterLoadedCallback = (index: number) => {
    setSubchaptersLoaded((subchaptersLoaded) =>
      subchaptersLoaded.map((loaded, i) => (i === index ? true : loaded))
    )
  }

  //TODO: Typing here will be a bit tricky, we are getting some extra subchapter data with fetchLinks
  // @ts-ignore
  const Subchapters = chapter?.data?.subchapters.map(
    (subchapter: any, index: number) => {
      if (!subchapter.subchapter) return null

      return (
        <Subchapter
          subchapterPosition={index}
          chapterLength={chapter.data.subchapters.length}
          key={subchapter.subchapter.uid}
          subchapter={subchapter.subchapter}
          chapterUid={chapter.uid}
          subchapterLoadedCallback={subchapterLoadedCallback}
        />
      )
    }
  )

  if (!allLoaded) {
    return (
      <LoadingSpinner
        loadedCount={loadedCount}
        totalCount={totalCount}
        showLoadingBar
      />
    )
  }

  return (
    <div ref={slideRef}>
      <Cover chapter={chapter} subchaptersLoaded={subchaptersLoaded} />
      {Subchapters}
      {nextChapter ? (
        <NextChapterCover chapter={chapter} nextChapter={nextChapter} />
      ) : (
        <VideobookEnd />
      )}
      <Footer />
    </div>
  )
}

export default Slide
