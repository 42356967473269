import React from 'react'
import usePrismicData from '../../data/usePrismicData'
import { LegalMainArea } from './components/LegalMainArea'

const PrivacyPolicy = () => {
  const { usePrivacyQuery } = usePrismicData()
  const privacyPolicy = usePrivacyQuery()

  if (privacyPolicy.isLoading || !privacyPolicy.data) return null
  return <LegalMainArea legalData={privacyPolicy.data} />
}

export default PrivacyPolicy
