import { create } from 'zustand'

interface SubchapterScrollPositionState {
  positions: number[]
  setPosition: (index: number, value: number, length: number) => void
}

export const useSubchapterScrollPosition = create<SubchapterScrollPositionState>(
  (set) => ({
    positions: [],
    setPosition: (index: number, value: number, length: number) =>
      set((state) => {
        // Check if the length has changed. If not, directly update the value if index is within bounds.
        if (length === state.positions.length) {
          // this could happend when user enters the page and didnt interact with it yet
          if (isNaN(value)) {
            value = 0
          }
          state.positions[index] = value
          // we need to spread the array so new array is created and the state is updated, otherwise the component will not re-render
          return { positions: [...state.positions] }
        } else {
          // If the length has changed (chapter change), create a new array with the desired length,
          const updatedPositions = Array(length).fill(0)
          return { positions: updatedPositions }
        }
      }),
  })
)
