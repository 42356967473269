import React from 'react'
import styled from 'styled-components/macro'
import { devices } from '../../../utils/styles'
import Button from '../../atoms/Button'
import Typography from 'components/atoms/Typography'
import { ChapterDocument } from '../../../generated/types/eschertemplate.types'
import usePrismicData from '../../../data/usePrismicData'
import { appConfig } from '../../../config'
import { replaceFilename } from '../../../utils/string'
import { FilledLinkToMediaField } from '@prismicio/types'
import { useHistory } from 'react-router-dom'
import ImageLoader from '../../common/imageLoader'
import { getLinkFieldUrl } from '../../../data/prismic-helper'
import { breakpoints } from '../../../utils/breakpoints'

interface Props {
  chapter: ChapterDocument
  nextChapter: ChapterDocument
}

export const NextChapterCover = ({ chapter, nextChapter }: Props) => {
  const { useIcons } = usePrismicData()
  const icons = useIcons()
  const history = useHistory()

  const nextChapterNumber = nextChapter.data.chapter_number
    ? `0${nextChapter.data.chapter_number}`
    : ''

  const imageSourceCover = () => {
    const videoUrl =
      breakpoints.phone &&
      getLinkFieldUrl(nextChapter.data.cover_background_video_mobile) !== ''
        ? getLinkFieldUrl(nextChapter.data.cover_background_video_mobile)
        : getLinkFieldUrl(nextChapter.data.cover_background_video_desktop)

    const videoFirstFrame =
      videoUrl && replaceFilename(videoUrl, 'firstframe.jpg')

    return (
      chapter.data.next_chapter_background.url ??
      (nextChapter.data.cover_background_image as FilledLinkToMediaField)
        ?.url ??
      videoFirstFrame ??
      nextChapter?.data.thumbnail.url
    )
  }

  return (
    <ContentHolder>
      <StyledImageLoader
        src={imageSourceCover()}
        alt={`${nextChapter.data.chapter_title} chapter background image`}
        size={'l'}
        loading={'lazy'}
      />
      <ChapterDataContainer data-cy="defaultChapterCoverContainer">
        <DataContainerSectionLeft>
          <NextChapterNumber>
            <Typography variant="head3">{nextChapterNumber}</Typography>
          </NextChapterNumber>
          <NextChapterTitle>
            <Typography variant="head2">
              {nextChapter.data.chapter_title}
            </Typography>
          </NextChapterTitle>
          <IconArrow
            src={icons.data?.data.doubleArrowRight.url ?? ''}
            alt={icons.data?.data.doubleArrowRight.alt ?? 'arrow-icon'}
          />
        </DataContainerSectionLeft>
        <DataContainerSectionRight>
          <ChapterIntro>
            <Typography variant="body">
              {nextChapter.data.cover_text}
            </Typography>
          </ChapterIntro>
          <EndOfChapterButton
            variant="contained"
            size="large"
            onClick={() => {
              history.push(`/${appConfig.contentUnitUrl}/${nextChapter.uid}`)
              window.scrollTo(0, 0)
            }}
          >
            {`${chapter.data.next_chapter_button_text} ${nextChapterNumber}`}
          </EndOfChapterButton>
        </DataContainerSectionRight>
      </ChapterDataContainer>
    </ContentHolder>
  )
}

const ContentHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 8rem 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  @media ${devices.mobile} {
    padding: 50px 16px 50px 16px;
    margin-bottom: 0px;
  }
`

const ChapterDataContainer = styled.div`
  display: flex;
  max-height: 90vh;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.palette.overlay};
  opacity: 0.9;
  padding: 56px 48px;
  flex-basis: 900px;
  margin: 0 auto;
  border-radius: 8px;

  @media ${devices.mobile} {
    padding: 48px 16px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`

const DataContainerSectionLeft = styled.div`
  padding-right: 30px;
  flex-basis: 300px;
  flex: 2;
  height: 100%;
  @media ${devices.mobile} {
    padding-right: 0;
    padding-bottom: 20px;
  }
`
const DataContainerSectionRight = styled.div`
  padding-left: 30px;
  flex-basis: 400px;
  flex: 3;
  border-left: solid 1px ${(props) => props.theme.palette.white(0.4)};
  @media ${devices.mobile} {
    display: block;
    border-left: none;
    padding-left: 0;
  }
  @media ${devices.tablet} {
    width: 100%;
  }
  @media ${devices.desktop} {
    word-break: break-word;
    display: block;
    overflow-y: auto;
    padding: 50px 20px;
  }
`
const ChapterIntro = styled.div`
  text-align: left;
  margin-bottom: 20px;
`

const NextChapterNumber = styled.div``

const NextChapterTitle = styled.div``

const IconArrow = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 16px;
`

const StyledImageLoader = styled(ImageLoader)`
  position: absolute;
  top: -5px;
  left: -5px;
  width: 103%;
  height: 103%;
  object-fit: cover;
  filter: blur(4px);
  z-index: -1;
`

const EndOfChapterButton = styled(Button)`
  @media ${devices.mobile} {
    width: 100%;
    justify-content: center;
  }
`
