import React, { useEffect, useState } from 'react'
import { ReactPlayerProps } from 'react-player'
import styled from 'styled-components/macro'
import screenfull from 'screenfull'
import { devices } from '../../utils/styles'
import { getFirstFrameUrl } from '../../utils/videoUtils'
import CustomReactPlayer from './CustomReactPlayer'
import usePrismicData from '../../data/usePrismicData'

interface CustomPlayerProps extends ReactPlayerProps {
  alt: string
  objectFit?: 'cover' | 'contain' | undefined
  hideControls?: boolean
}

const browserSupportsHls = (): boolean => {
  const video = document.createElement('video')
  return Boolean(
    video.canPlayType('application/vnd.apple.mpegURL') ||
      video.canPlayType('application/x-mpegURL')
  )
}

const isHlsFile = (filePath: string): boolean => {
  return filePath.indexOf('.m3u8') > -1
}

const VideoPlayer: React.FC<CustomPlayerProps> = (props) => {
  const { useIcons } = usePrismicData()
  const icons = useIcons()
  const { play, stop, volume, mutedVolume, fullscreen } = icons.data?.data ?? {}

  const [videoBuffered, setVideoBuffered] = useState(false)
  const [showPoster, setShowPoster] = useState(true)
  const [playErrorOccured, setPlayErrorOccurred] = useState(false)

  const videoUrl =
    typeof props.url === 'string' && props.url.trim() !== '' ? props.url : ''

  const posterUrl = videoUrl ? getFirstFrameUrl(videoUrl) : ''

  useEffect(() => {
    setShowPoster(!videoBuffered || playErrorOccured)
  }, [videoBuffered, playErrorOccured])

  return (
    <VideoContainer
      $objectFit={props.objectFit}
      $isFullscreen={screenfull.isFullscreen}
    >
      <Poster $showPoster={showPoster} $posterUrl={posterUrl} />
      <CustomReactPlayer
        {...props}
        playing={playErrorOccured ? false : props.playing}
        videoLoadedCallback={() => setVideoBuffered(true)}
        onPlay={() => setPlayErrorOccurred(false)}
        onError={() => setPlayErrorOccurred(true)}
        icons={{
          play: play?.url ?? '',
          stop: stop?.url ?? '',
          volume: volume?.url ?? '',
          mutedVolume: mutedVolume?.url ?? '',
          fullscreen: fullscreen?.url ?? '',
        }}
        config={{
          file: {
            // force to use hls.js library only if the browser does not support it already
            forceHLS:
              isHlsFile((props.url as string) ?? '') && !browserSupportsHls(),
            hlsVersion: '1.4.12',
          },
        }}
      />
    </VideoContainer>
  )
}

export default VideoPlayer

const VideoContainer = styled.div<{
  $objectFit: 'cover' | 'contain' | undefined
  $isFullscreen: boolean
}>`
  width: 100%;

  //When object-fit is cover. The video tends to be bigger than the viewport
  max-height: 100vh;
  background-color: ${({ theme }) => theme?.palette.background};
  display: flex;
  align-items: center;
  justify-content: center;

  .video-player {
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    > video {
      //If the video is fullscreen, always use contain, otherwise respect the object-fit prop
      object-fit: ${({ $objectFit, $isFullscreen }) =>
        $isFullscreen ? 'contain' : $objectFit ?? 'cover'}
  }
`

interface PosterProps {
  $showPoster: boolean
  $posterUrl: string
}

const Poster = styled.div<PosterProps>`
  position: absolute;
  z-index: 3;
  opacity: ${({ $showPoster }) => ($showPoster ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-image: url(${({ $posterUrl }) => $posterUrl});
  background-position: center;
  background-size: cover;

  @media ${devices.mobile} {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
