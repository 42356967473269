import React from 'react'
import styled from 'styled-components'

interface ISubcontentDividerProps {
  type?: string | 'Line' | 'White Space'
}

export const InlineSubcontentDivider: React.FC<ISubcontentDividerProps> = ({
  type,
}) => {
  return <Container>{type === 'Line' && <Divider />}</Container>
}

const Container = styled.div`
  width: 100%;
  margin: 24px 0;
`

const Divider = styled.div`
  height: 0;
  border: 1px white solid;
`
