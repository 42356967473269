import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import { appConfig } from '../config'
import * as prismic from '@prismicio/client'

type PrismicClientType = prismic.Client | null

const PrismicContext = createContext<PrismicClientType>(null)

export const usePrismic = () => {
  return useContext(PrismicContext)
}

interface PrismicClientProviderProps {
  children: ReactNode
}

export function PrismicProvider({ children }: PrismicClientProviderProps) {
  const [client, setClient] = useState<PrismicClientType>(null)

  useEffect(() => {
    const prismicClient = prismic.createClient(appConfig.prismicPath)
    setClient(prismicClient)
  }, [])

  //TODO: some sort of loading screen if client is not ready?? Probably not needed, its really fast
  return (
    <PrismicContext.Provider value={client}>
      {client && children}
    </PrismicContext.Provider>
  )
}
