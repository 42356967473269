import React, { RefObject, Suspense, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { devices } from '../../../utils/styles'
import { CoverBackground } from '../components/CoverBackground'
import { ChapterDocument } from '../../../generated/types/eschertemplate.types'
import { FilledLinkToWebField } from '@prismicio/types'
import { ChapterCoverText } from '../components/ChapterCoverText'
import { breakpoints } from '../../../utils/breakpoints'
import { appConfig } from '../../../config'
import { IconDown } from '../../atoms/IconDown/IconDown'
import { InView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'
import useAppStateStore from '../../../hooks/useAppStateStore'

export interface IDefaultCoverContentProps {
  chapter: ChapterDocument
  subchaptersLoaded: boolean[]
}

const ChapterCoverTitleAlignedLeft = React.lazy(
  async () => import('../components/ChapterCoverTitleAlignedLeft')
)
const ChapterCoverTitleAlignedRight = React.lazy(
  async () => import('../components/ChapterCoverTitleAlignedRight')
)

export const Cover = ({
  chapter,
  subchaptersLoaded,
}: IDefaultCoverContentProps) => {
  const [isIconVisible, setIconVisibility] = useState(true)
  const bottomElement = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const { openedModalType } = useAppStateStore()

  const scrollIntoView = (element: RefObject<HTMLDivElement>) => {
    element.current?.scrollIntoView({
      block: 'start',
      inline: 'center',
      behavior: 'smooth',
    })
  }

  const allSubchaptersAreLoaded = subchaptersLoaded.every((loaded) => loaded)

  const ChapterCoverTitle =
    appConfig.templateConfig?.Cover?.version === 'RightAligned'
      ? ChapterCoverTitleAlignedRight
      : ChapterCoverTitleAlignedLeft

  const handleInView = (inView: boolean) => {
    // If all subchapters are loaded and no modal is open, replace the URL with the chapter URL

    if (inView && allSubchaptersAreLoaded && !openedModalType) {
      history.replace(`/${appConfig.contentUnitUrl}/${chapter.uid}`)
    }
  }

  return (
    <CoverContainer>
      <InView as="div" onChange={handleInView}></InView>
      <WrapperContainer>
        <CoverBackground
          coverBackgroundVideoDesktop={
            (chapter.data
              .cover_background_video_desktop as FilledLinkToWebField).url
          }
          coverBackgroundVideoMobile={
            (chapter.data.cover_background_video_mobile as FilledLinkToWebField)
              .url
          }
          coverBackgroundImage={chapter.data.cover_background_image}
          isMobile={!!breakpoints.phone}
          thumbnail={chapter.data.thumbnail}
          imageEffectType={chapter.data.image_effect_type ?? 'none'}
        />
        <StyledOverlay />
      </WrapperContainer>
      <TextWrapper>
        <Suspense fallback={<div />}>
          <ChapterCoverTitle
            chapterNumber={chapter.data.chapter_number ?? 0}
            chapterLead={chapter.data.chapter_lead ?? ''}
            chapterTitle={chapter.data.chapter_title ?? ''}
            chapterSubtitle={chapter.data.chapter_subtitle ?? ''}
            showChapterNumber={
              !!appConfig.templateConfig?.Cover?.showChapterNumber
            }
          />
        </Suspense>
        <InView
          as="div"
          onChange={(inView) => setIconVisibility(inView)}
        ></InView>
        <ChapterCoverText chapterCoverText={chapter.data.cover_text ?? ''} />
        <StyledIconDown $isVisible={isIconVisible}>
          <IconDown onClick={() => scrollIntoView(bottomElement)} />
        </StyledIconDown>
      </TextWrapper>
      <BottomTextRef ref={bottomElement} />
    </CoverContainer>
  )
}

const RefHolder = styled.div`
  position: absolute;
`

const BottomTextRef = styled(RefHolder)`
  bottom: -1px;
`

// eslint-disable-next-line prettier/prettier
const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: ${({ theme }) => theme.templates.cover.desktop.overlay};
  will-change: background;
  height: 100vh;

  @media ${devices.mobile} {
    background: ${({ theme }) => theme.templates.cover.phone.overlay};
  }
`

const CoverContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200vh;
`

const WrapperContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;

  & video {
    @media ${devices.orientationPortrait.mobile} {
      object-fit: cover !important;
    }
  }
`

const TextWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: -70vh;
`

const StyledIconDown = styled.div<{ $isVisible: boolean }>`
  transition: opacity 0.3s ease, transition 0.3s ease;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
`
