import { breakpoints } from './breakpoints'
import { ImageField, ImageFieldImage } from '@prismicio/types'

export function getResponsiveImage(
  image: ImageField<'desktop' | 'mobile'> | undefined
): ImageFieldImage | undefined {
  if (breakpoints.phone || breakpoints.tabletPortrait) {
    if (image?.mobile?.url) {
      return image.mobile ?? undefined
    } else {
      return image ?? undefined
    }
  } else {
    if (image?.desktop?.url) {
      return image.desktop ?? undefined
    } else {
      return image ?? undefined
    }
  }
}
