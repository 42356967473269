import React from 'react'
import styled from 'styled-components'

interface ImageCarouselProps {
  images: string[]
  activeIndex: number
  setIndex: (index: number) => void
}

const ImageCarousel = ({
  images,
  activeIndex,
  setIndex,
}: ImageCarouselProps) => {
  const touchStartXRef = React.useRef(0)
  const touchEndXRef = React.useRef(0)

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartXRef.current = e.touches[0].clientX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndXRef.current = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const swipeDistance = touchStartXRef.current - touchEndXRef.current
    const isSwipeToLeft = swipeDistance > 75
    const isSwipeToRight = swipeDistance < -75

    if (isSwipeToLeft && activeIndex < images.length - 1) {
      // Swipe Left - Show Next, if not last
      setIndex(activeIndex + 1)
    } else if (isSwipeToRight && activeIndex > 0) {
      // Swipe Right - Show Previous, if not first
      setIndex(activeIndex - 1)
    }
  }

  return (
    <CarouselContainer
      data-cy="imageGalleryContainer"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {images.map((item, index) => (
        <CarouselItem
          data-cy={`galleryImage${index + 1}`}
          key={index}
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          <Image src={item} />
        </CarouselItem>
      ))}
    </CarouselContainer>
  )
}

export default ImageCarousel

const CarouselContainer = styled.div`
  background: ${(p) => p.theme.palette.background};
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  height: 100%;

  @media (max-width: 768px) {
    height: 75%;
    flex: 1 0 auto;
  }
`

const CarouselItem = styled.h1`
  height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
