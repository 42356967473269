import React from 'react'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'

interface Props {
  children?: React.ReactNode
  activateCallback?: () => void
  showBackgroundOverlay: boolean
}

const InteractiveBackground = ({
  children,
  showBackgroundOverlay,
  activateCallback,
}: Props) => {
  return (
    <Container>
      <StickyBackground>
        {children}
        {showBackgroundOverlay && (
          <BackgroundOverlay onClick={activateCallback} />
        )}
      </StickyBackground>
    </Container>
  )
}

export default InteractiveBackground

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
`

const StickyBackground = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overscroll-behavior: none;
  transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;

  @media ${devices.mobile} {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: transparent;
  }
`

const BackgroundOverlay = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;

  @media ${devices.desktop} {
    background: transparent;
  }

  @media ${devices.mobile} {
    z-index: 109;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 60%,
      rgba(0, 0, 0, 0.7)
    );
  }
`
