import styled from 'styled-components/macro'
import TextModal from './TextModal'
import ImageModal from './ImageModal'
import GalleryModal from './GalleryModal'
import { useEffect, useState } from 'react'
import { devices } from 'utils/styles'
import { zIndex } from '../../utils/zIndex'
import VideoModal from './VideoModal'
import { Fade } from '@mui/material'
import usePrismicData from '../../data/usePrismicData'
import {
  ContentItemResponseTypes,
  ContentItemTypes,
  LegalType,
  LegalTypes,
} from '../../data/prismic-types'
import { useHistory } from 'react-router-dom'
import useAppStateStore, { OpenedModalType } from '../../hooks/useAppStateStore'
import { appConfig } from '../../config'
import LegalContainer from './components/LegalContainer'
import TermsOfUse from './TermsOfUseModal'
import Imprint from './ImprintModal'
import PrivacyPolicy from './PrivacyPolicyModal'
import Sources from './SourcesModal'

const validContentItemTypes: ContentItemTypes[] = [
  'content_item_gallery',
  'content_item_image',
  'content_item_text',
  'content_item_video',
]

function isLegalType(type: OpenedModalType): type is LegalType {
  return Object.values(LegalType).includes(type as LegalType)
}

function isContentType(type: OpenedModalType): boolean {
  return validContentItemTypes.includes(type as ContentItemTypes)
}

const Modal = () => {
  const {
    detailsOpen,
    openedModalUid,
    openedModalType,
    updateState,
  } = useAppStateStore()

  const history = useHistory()
  const { useContentItem } = usePrismicData()
  const contentItem = useContentItem(
    openedModalType as ContentItemTypes,
    openedModalUid
  )

  const [isUnmounting, setIsUnmounting] = useState(false)

  const ENTER_DURATION = 400
  const EXIT_DURATION = 300

  const modalIsOpen = !!openedModalType

  useEffect(() => {
    if (modalIsOpen) {
      // Disable scroll when modal is open
      document.body.style.overflow = 'hidden'
    } else {
      // Enable scroll when modal is closed
      document.body.style.overflow = 'auto'
    }
  }, [modalIsOpen])

  const updateUrl = (urlParams: Record<string, string>) => {
    const params = new URLSearchParams(urlParams)

    if (detailsOpen) {
      params.append('detailsOpen', 'true')
    }

    history.replace(`${history.location.pathname}?${params.toString()}`)
  }

  useEffect(() => {
    if (isLegalType(openedModalType)) {
      updateUrl({
        openModalType: openedModalType ?? '',
      })
    } else if (isContentType(openedModalType)) {
      updateUrl({
        contentItemUid: openedModalUid ?? '',
        openModalType: openedModalType ?? '',
      })
    } else {
      // in a case that openModalType is not valid, close the modal
      onClose()
    }

    const handleEscape = (event: KeyboardEvent) => {
      if (modalIsOpen && event.key === 'Escape') {
        onClose()
      }
    }

    // removes modal when user navigates back
    const handlePopStateChange = () => {
      if (modalIsOpen) {
        onClose()
      }
    }

    window.addEventListener('keydown', handleEscape)
    window.addEventListener('popstate', handlePopStateChange)

    return () => {
      window.removeEventListener('keydown', handleEscape)
      window.removeEventListener('popstate', handlePopStateChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedModalUid, openedModalType, detailsOpen])

  const onClose = () => {
    setIsUnmounting(true)

    setTimeout(() => {
      updateState('openedModalUid', null)
      updateState('openedModalType', null)
      // Reset to default state.
      updateState('detailsOpen', appConfig.contentItemDetailsOpenByDefault)

      const currentUrl = new URL(window.location.href)
      const pathWithoutQuery = currentUrl.pathname
      window.history.replaceState({}, '', pathWithoutQuery)

      setIsUnmounting(false)
    }, EXIT_DURATION)
  }

  const LegalModal = (legalItem: LegalTypes | undefined) => {
    switch (legalItem) {
      case LegalType.TermsOfUse:
        return <TermsOfUse />
      case LegalType.Imprint:
        return <Imprint />
      case LegalType.PrivacyPolicy:
        return <PrivacyPolicy />
      case LegalType.Sources:
        return <Sources />
      default:
        return null
    }
  }
  const ContentModal = (
    contentItem: ContentItemResponseTypes | undefined,
    onClose: () => void
  ) => {
    switch (contentItem?.type) {
      case 'content_item_video':
        return <VideoModal contentItem={contentItem} onClose={onClose} />
      case 'content_item_text':
        return <TextModal contentItem={contentItem} onClose={onClose} />
      case 'content_item_image':
        return <ImageModal contentItem={contentItem} onClose={onClose} />
      case 'content_item_gallery':
        return <GalleryModal contentItem={contentItem} onClose={onClose} />
      default:
        return null
    }
  }

  if (!modalIsOpen) {
    return null
  }

  return (
    <Fade
      in={modalIsOpen && !isUnmounting}
      timeout={{ enter: ENTER_DURATION, exit: EXIT_DURATION }}
    >
      <ModalWrapper>
        {isLegalType(openedModalType) && (
          <LegalContainer onClose={onClose}>
            {LegalModal(openedModalType)}
          </LegalContainer>
        )}

        {isContentType(openedModalType) && (
          <ContentItemContainer
            className={`${detailsOpen ? 'details-open' : ''}`}
            data-cy={`contentContainer${detailsOpen ? 'Open' : 'Closed'}`}
          >
            {ContentModal(contentItem.data, onClose)}
          </ContentItemContainer>
        )}
      </ModalWrapper>
    </Fade>
  )
}

export default Modal

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${zIndex.modal};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  ul {
    padding-left: 20px;
  }

  @media ${devices.mobile} {
    height: 100vh;
    flex-direction: column;
    overflow: visible;
  }
`

const ContentItemContainer = styled.div`
  width: 100%;
  height: 100%;
`
