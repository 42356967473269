import React from 'react'
import usePrismicData from '../../data/usePrismicData'
import { LegalMainArea } from './components/LegalMainArea'

const Imprint = () => {
  const { useImprint } = usePrismicData()
  const imprint = useImprint()

  if (imprint.isLoading || !imprint.data) return null
  return <LegalMainArea legalData={imprint.data} />
}

export default Imprint
