import styled, { keyframes } from 'styled-components'
import Typography from './Typography'
import { useEffect, useState } from 'react'
import { useLang } from '../../providers/LanguageProvider'
import { appConfig } from '../../config'
import { zIndex } from '../../utils/zIndex'

export interface LoadingSpinnerProps {
  showLoadingBar?: boolean
  loadedCount?: number
  totalCount?: number
}

const LoadingSpinner = ({
  showLoadingBar = false,
  loadedCount = 0,
  totalCount = 1,
}: LoadingSpinnerProps) => {
  const { lang } = useLang()
  const [loadingIcon, setLoadingIcon] = useState<string>('')

  const currentLangConfig = appConfig.languages.find(
    (language) => language.code === lang
  )

  useEffect(() => {
    let isMounted = true

    const fetchIcon = async () => {
      try {
        const loadingIcon = await import(
          `../../projects/${process.env.REACT_APP_PROJECT_NAME}/loadingIcon.svg`
        )
        if (isMounted && loadingIcon) {
          setLoadingIcon(loadingIcon.default)
        }
      } catch (err) {
        console.error(err)
      }
    }

    fetchIcon().catch((err) => console.error(err))

    return () => {
      isMounted = false
    }
  }, [])

  const progress = (loadedCount / totalCount) * 100

  return (
    <SpinnerWrapper>
      <ImageContainer>
        {loadingIcon && (
          <AnimatedImage src={loadingIcon} alt={'loading-spinner'} />
        )}
      </ImageContainer>
      <LoadingBar $progress={progress} $show={showLoadingBar} />
      <Typography variant={'head4'} style={{ textTransform: 'uppercase' }}>
        {currentLangConfig?.loadingSpinnerConfig.title}
      </Typography>
      <Typography variant={'body'}>
        {currentLangConfig?.loadingSpinnerConfig.subtitle}
      </Typography>
    </SpinnerWrapper>
  )
}

export default LoadingSpinner

const pulsate = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: ${zIndex.loadingSpinner};
  background-color: ${(p) => p.theme.palette.background};
  gap: 24px;
`

interface LoadingBarProps {
  $progress: number
  $show: boolean
}

const LoadingBar = styled.div<LoadingBarProps>`
  width: 400px;
  max-width: 90%;
  height: 4px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
  overflow: hidden;
  opacity: ${(props) => (props.$show ? 1 : 0)}; // Control visibility
  transition: opacity 0.3s ease-in;

  &::after {
    content: '';
    display: block;
    height: 100%;
    background: ${({ theme }) => theme.palette.contrast};
    width: ${(props) => props.$progress}%;
    transition: width 0.3s ease-in-out;
  }
`

const AnimatedImage = styled.img`
  animation: ${pulsate} 1.5s ease-in-out infinite;
  padding-bottom: 24px;
  max-width: 80vw;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50vh;
`
