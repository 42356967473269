import {
  UiElementsDocument,
  UiElementsDocumentDataBodyContentItemsNamesSlice,
  UiElementsDocumentDataBodyLegalMenuSlice,
  UiElementsDocumentDataBodyMenuItemsSlice,
  UiElementsDocumentDataBodySocialMenuSlice,
  UiElementsDocumentDataBodyStaticUiTextsSlice,
} from '../generated/types/eschertemplate.types'
import {
  FilledLinkToDocumentField,
  FilledLinkToMediaField,
  FilledLinkToWebField,
  LinkField,
  LinkType,
} from '@prismicio/types'

enum SliceTypeEnum {
  MENU_ITEMS = 'menu_items',
  STATIC_UI_TEXTS = 'static_ui_texts',
  LEGAL_MENU = 'legal_menu',
  SOCIAL_MENU = 'social_menu',
  CONTENT_ITEM_NAMES = 'content_items_names',
}

type SliceType =
  | 'menu_items'
  | 'static_ui_texts'
  | 'legal_menu'
  | 'social_menu'
  | 'content_items_names'

interface UiElementsReturnTypeMap {
  menu_items: UiElementsDocumentDataBodyMenuItemsSlice
  static_ui_texts: UiElementsDocumentDataBodyStaticUiTextsSlice
  legal_menu: UiElementsDocumentDataBodyLegalMenuSlice
  social_menu: UiElementsDocumentDataBodySocialMenuSlice
  content_items_names: UiElementsDocumentDataBodyContentItemsNamesSlice
}

/*
 * Function to retrieve the data for a given UI element slice
 * T is a generic type that extends SliceType, allowing TypeScript to infer the return type
 *
 * In human language this means that the function will return the correct type of data for the sliceType provided
 * For example if slice type is 'menu_items' the function will return the data typed as UiElementsDocumentDataBodyMenuItemsSlice
 */

const getUiElementsSliceData = <T extends SliceType>(
  sliceType: T,
  uiElements: UiElementsDocument | undefined
): UiElementsReturnTypeMap[T] | undefined => {
  // Find the slice in the document that matches the provided sliceType
  return uiElements?.data?.body.find(
    (item) => item.slice_type === sliceType
  ) as UiElementsReturnTypeMap[T] | undefined
}

export { getUiElementsSliceData, SliceTypeEnum }

export const getLinkFieldUrl: (link?: LinkField) => string = (link) => {
  const isFilledLinkWithUrl = (
    link: LinkField
  ): link is
    | FilledLinkToWebField
    | FilledLinkToDocumentField
    | FilledLinkToMediaField => {
    return link.link_type !== LinkType.Any
  }

  if (link && isFilledLinkWithUrl(link)) {
    return link.url ?? ''
  }

  return ''
}
