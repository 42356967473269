import { screenWidth } from './styles'

export interface IBreakpoints {
  phone: boolean
  tablet: boolean
  desktop: boolean
  tabletPortrait: boolean
}

export const breakpoints: IBreakpoints = {
  phone: window.innerWidth <= screenWidth.mobile,
  tablet: window.innerWidth <= screenWidth.tablet,
  tabletPortrait:
    window.innerWidth <= screenWidth.tablet &&
    window.innerHeight > window.innerWidth,
  desktop: window.innerWidth >= screenWidth.tablet,
}

export const isTouchDevice =
  'ontouchstart' in window || navigator.maxTouchPoints > 0
