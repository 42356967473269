import styled from 'styled-components/macro'
import React, { useMemo, useState } from 'react'
import NavBar from '../NavBar'
import usePrismicData from '../../data/usePrismicData'
import Footer from '../Footer/Default'
import Glossary from './components/Glossary'
import Toggle, { IToggleItem } from '../atoms/Toggle'
import Articles from './components/Articles'
import KbHeader from './components/KbHeader'
import { ContentItemResponseTypesWithoutScrollymationAndScrollyStory } from '../../data/prismic-types'

enum KnowledgebaseItem {
  ARTICLES = 'articles',
  GLOSSARY = 'glossary',
}

const Knowledgebase = () => {
  const {
    useKnowledgebase,
    useKBContentItems,
    useAllTooltips,
  } = usePrismicData()

  const tooltips = useAllTooltips()
  const knowledgeBase = useKnowledgebase()
  const contentItems = useKBContentItems()

  const [isGlossaryVisible, setIsGlossaryVisible] = useState(false)

  function someContentItemGotCategoryName(
    contentItems:
      | ContentItemResponseTypesWithoutScrollymationAndScrollyStory[]
      | undefined
  ) {
    //TODO: fetchlinks ts...
    // @ts-ignore
    return contentItems?.some((item) => item.data.category?.data?.name)
  }

  const handleToggle = (items: IToggleItem) => {
    setIsGlossaryVisible(items.value === 'glossary')
  }

  const displayErrorMessage =
    tooltips?.data &&
    tooltips?.data?.length === 0 &&
    !someContentItemGotCategoryName(contentItems.data)

  const tooltipsOrContentItemsLoading =
    tooltips.isLoading || contentItems.isLoading

  const displayToggle =
    tooltips?.data &&
    tooltips?.data?.length > 0 &&
    someContentItemGotCategoryName(contentItems.data)

  const displayGlossary =
    isGlossaryVisible || !someContentItemGotCategoryName(contentItems.data)

  const ErrorMessage = () => {
    return (
      <ErrorText>
        There are no data to display. Please mark some content items with
        category (for knowledge base) or add tooltips (for glossary)
      </ErrorText>
    )
  }

  const KnowledgebaseContent = useMemo(() => {
    const toggleItems = [
      {
        value: KnowledgebaseItem.ARTICLES,
        displayName: knowledgeBase.data?.data?.articles ?? 'articles',
      },
      {
        value: KnowledgebaseItem.GLOSSARY,
        displayName: knowledgeBase.data?.data?.glossary ?? 'glossary',
      },
    ]

    const ToggleComponent = () => {
      if (displayToggle) {
        return (
          <ToggleWrapper>
            <Toggle
              items={toggleItems}
              $selectedValue={
                isGlossaryVisible
                  ? KnowledgebaseItem.GLOSSARY
                  : KnowledgebaseItem.ARTICLES
              }
              onToggle={handleToggle}
            />
          </ToggleWrapper>
        )
      }
      return null
    }

    if (tooltipsOrContentItemsLoading) {
      return null
    }

    if (displayErrorMessage) {
      return <ErrorMessage />
    }

    return (
      <>
        <ToggleComponent />
        {displayGlossary ? (
          <Glossary />
        ) : (
          <Articles contentItems={contentItems} knowledgeBase={knowledgeBase} />
        )}
        <Footer />
      </>
    )
  }, [
    tooltipsOrContentItemsLoading,
    displayErrorMessage,
    displayGlossary,
    contentItems,
    knowledgeBase,
    displayToggle,
    isGlossaryVisible,
  ])

  return (
    <MainContainer>
      <NavBar />
      <KbHeader
        isLoading={knowledgeBase.isLoading}
        title={knowledgeBase.data?.data.title ?? ''}
        subtitle={knowledgeBase.data?.data.subtitle ?? ''}
      />
      {KnowledgebaseContent}
    </MainContainer>
  )
}

export default Knowledgebase

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: ${(p) => p.theme.palette.background};
  white-space: pre-wrap;
`

const ToggleWrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 1500px;
  justify-content: flex-start;
  margin: 32px auto 0 auto;
`

const ErrorText = styled.div`
  width: 90%;
  margin: 24px auto;
`
