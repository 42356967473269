import React from 'react'
import SectionTitle from './SectionTitle'
import SubcontentMainComponent from 'components/SectionItems/SubcontentMainComponent'
import styled from 'styled-components/macro'
import { devices } from 'utils/styles'
import { QueryObserverResult } from '@tanstack/react-query'
import {
  ContentItemGalleryDocument,
  ContentItemImageDocument,
  ContentItemScrollymationDocument,
  ContentItemTextDocument,
  ContentItemVideoDocument,
  SubchapterDocument,
} from '../../generated/types/eschertemplate.types'
import { SubchapterDocumentDataBodySlices } from '../../data/prismic-types'

export interface SectionProps {
  subchapterQuery: QueryObserverResult<SubchapterDocument<string>, unknown>
  contentItem?:
    | ContentItemImageDocument
    | ContentItemTextDocument
    | ContentItemGalleryDocument
    | ContentItemVideoDocument
    | ContentItemScrollymationDocument
  customTextBoxStyles?: React.CSSProperties
}

const SectionTextContent = ({ subchapterQuery, contentItem }: SectionProps) => {
  if (!contentItem || !subchapterQuery.data) {
    return null
  }

  const textPositionLeft =
    subchapterQuery.data.data.text_position_variant === 'left'

  if (
    subchapterQuery.data.data.video_mode === 'subchapter_video_fullscreen_video'
  ) {
    return null
  }

  return (
    <ContentContainer
      $textPositionLeft={textPositionLeft}
      data-cy="sectionContainer"
    >
      <InnerWrapper>
        <SectionTitle
          key={subchapterQuery.data.data.subchapter_title}
          desktopTitle={subchapterQuery.data.data.subchapter_title ?? ''}
          lead={subchapterQuery.data.data.subchapter_lead ?? ''}
        />
        {(subchapterQuery.data.data
          .body as SubchapterDocumentDataBodySlices[]).map(
          (sectionItem: SubchapterDocumentDataBodySlices) => (
            <SubcontentMainComponent
              key={`SubcontentMainComponent-${sectionItem.id}`}
              contentItem={contentItem}
              sectionItemData={sectionItem}
            />
          )
        )}
      </InnerWrapper>
    </ContentContainer>
  )
}
const ContentContainer = styled.div<{ $textPositionLeft: boolean }>`
  position: relative;
  z-index: 1;
  height: fit-content;
  min-width: unset;

  @media ${devices.desktop} {
    min-height: 100vh;
    width: 35%;
    min-width: 450px;
    margin: -80vh auto 200px auto;
    ${({ $textPositionLeft }) =>
      $textPositionLeft ? 'margin-left: 100px;' : 'margin-right: 100px;'}
  }
`

const InnerWrapper = styled.div`
  background: ${(p) => `${p.theme.palette.overlay}E5`};

  @media ${devices.desktop} {
    padding: 40px;
  }

  @media ${devices.tablet} {
    padding: 56px 15% 40px 85px;
  }

  @media ${devices.mobile} {
    background: ${(p) => p.theme.palette.background};
    padding: 56px 16px 40px 16px;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }
`

export default SectionTextContent
