import CircleButton from 'components/atoms/CircleButton'
import Typography from 'components/atoms/Typography'
import * as React from 'react'
import { Link } from 'react-router-dom'
import ChaptersSection from './chaptersSection'
import { useEffect, useRef, useState } from 'react'
import ChapterDescription from './chaptersDescription'
import MenuSection from './menuSection'
import { Skeleton } from '@mui/material'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../data/prismic-helper'
import { ChapterDocument } from '../../generated/types/eschertemplate.types'
import { IconTypes } from '../../data/prismic-enums'
import Footer from '../Footer/Default'
import usePrismicData from '../../data/usePrismicData'
import { appConfig } from '../../config'
import useAppStateStore from '../../hooks/useAppStateStore'
import { devices } from '../../utils/styles'
import styled from 'styled-components/macro'
import { zIndex } from '../../utils/zIndex'

interface IMenuProps {
  chapter?: ChapterDocument
}

const Menu = ({ chapter }: IMenuProps) => {
  const { useAllChapters, useUIelements, useIcons } = usePrismicData()
  const chaptersData = useAllChapters()
  const uiElements = useUIelements()
  const icons = useIcons()
  const { menuOpen, updateState } = useAppStateStore()

  useEffect(() => {
    if (menuOpen) {
      // Disable scroll when menu is open
      document.body.style.overflow = 'hidden'
    } else {
      // Enable scroll when menu is closed
      document.body.style.overflow = 'auto'
    }
  }, [menuOpen])

  const menuItems = getUiElementsSliceData(
    SliceTypeEnum.MENU_ITEMS,
    uiElements.data
  )

  const [selectedChapter, setSelectedChapter] = useState<
    ChapterDocument | undefined
  >(chapter)

  const subchaptersElement = useRef<HTMLDivElement>(null)

  const selectChapter = (chapter: ChapterDocument) => {
    setSelectedChapter(chapter)
  }

  const knowledgeBase = appConfig.allowKnowledgeBase && (
    <KnowledgeBaseBoxContainer data-cy="knowledgeBaseWrap">
      <Typography variant="head4">
        {menuItems?.primary.knowledge_base_link_title ?? 'KNOWLEDGE BASE'}
      </Typography>
      <Typography variant="body">
        {menuItems?.primary.knowledge_base_link_desc ??
          'Description placeholder'}
      </Typography>
      <ArrowWrap
        to="/knowledgebase"
        onClick={() => updateState('menuOpen', !menuOpen)}
      >
        <CircleButton>
          <img
            src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
            alt="open knowledge base"
            height={
              icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.height ?? 16
            }
            width={
              icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.width ?? 16
            }
          />
        </CircleButton>
      </ArrowWrap>
    </KnowledgeBaseBoxContainer>
  )

  return (
    <>
      <MenuContainer
        minHeight={window.innerHeight}
        menuOpen={menuOpen}
        data-cy={`overlayMenu${menuOpen ? 'Open' : 'Closed'}`}
      >
        <BodyWrap>
          <ChapterSectionWrapper data-cy="menuChapterSectionWrapper">
            {chaptersData.isLoading ? (
              <Skeleton variant="text" width="100%" height={300} />
            ) : (
              chaptersData?.data?.map((chapter) => (
                <ChaptersSection
                  chapter={chapter}
                  selectChapter={selectChapter}
                  isActive={selectedChapter?.uid === chapter.uid}
                  key={`ChapterSection-${chapter.id}`}
                  menuItems={menuItems}
                />
              ))
            )}
          </ChapterSectionWrapper>

          <MiddleWrap data-cy="menuMiddleWrap">
            {
              <MiddleWrapLeftSide>
                <MiddleWrapControls>
                  {selectedChapter && (
                    <ChapterDescription
                      chapter={selectedChapter}
                      subchaptersElement={subchaptersElement}
                      menuItems={menuItems}
                    />
                  )}
                </MiddleWrapControls>
                {selectedChapter && (
                  <MenuSection
                    chapter={selectedChapter}
                    key={selectedChapter?.id}
                    subchaptersElement={subchaptersElement}
                    menuItems={menuItems}
                  />
                )}
              </MiddleWrapLeftSide>
            }

            {knowledgeBase}
          </MiddleWrap>
          <FooterWrapper>
            <Footer isFixedAtBottom={true} />
          </FooterWrapper>
        </BodyWrap>
      </MenuContainer>
    </>
  )
}

export default Menu

const KnowledgeBaseBoxContainer = styled.div`
  width: 300px;
  height: fit-content;
  border-radius: 8px;
  margin-right: 40px;
  padding: 30px;
  border: solid 1px #ebfcfa;
  background-color: rgba(156, 192, 187, 0.05);

  @media ${devices.tablet} {
    margin-left: 40px;
  }

  @media ${devices.mobile} {
    width: 90%;
    margin: 20px;
    margin-bottom: 50px;
  }
`

const ArrowWrap = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  margin-left: auto;
  width: fit-content;
`

interface MenuContainerProps {
  minHeight: number
  menuOpen: boolean
}

const MenuContainer = styled.div<MenuContainerProps>`
  overflow: scroll;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.menu};
  background-color: ${(p) => p.theme.palette.background};
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight}px;

  ${(props) =>
    props.menuOpen
      ? `
    transform: translate(0, 0);
    left: 0;
    opacity: 1;
    transition: transform 0.5s, opacity 1s;
  `
      : `
    transition: opacity 1s, transform 0.5s;
    transform: translate(-200vw, 0);
    opacity: 0;
  `}

  @media ${devices.desktop} {
    overflow: hidden;
    align-items: center;
    padding-top: 80px;
  }
  @media ${devices.mobile} {
    overflow: scroll;
  }
`

const BodyWrap = styled.div`
  flex: 1;
  padding-top: 32px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${devices.tablet} {
    position: relative;
    width: 100%;
  }

  @media ${devices.mobile} {
    overflow: initial;
    position: relative;
  }
`

const ChapterSectionWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 12px;
  overflow: scroll;
  padding-top: 80px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${devices.mobile} {
    padding-top: 0;
  }
`

const MiddleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media ${devices.tablet}, ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const MiddleWrapLeftSide = styled.div`
  display: flex;
  flex: 1;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`

const MiddleWrapControls = styled.div`
  @media ${devices.tablet} {
    display: flex;
    flex-direction: row;
  }
`

const FooterWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  @media ${devices.tablet} {
    margin-top: 10%;
  }

  @media ${devices.mobile} {
    margin-top: 5%;
  }
`
