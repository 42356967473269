import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { fullTheme, loadConfig } from 'config'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter } from 'react-router-dom'
import App from './App'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { LangProvider } from './providers/LanguageProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PrismicProvider } from './providers/PrismicClientProvider'
import useAppStateStore from './hooks/useAppStateStore'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
})

const getConfigAndSetState = async () => {
  const config = await loadConfig()
  useAppStateStore
    .getState()
    .updateState(
      'detailsOpen',
      config?.contentItemDetailsOpenByDefault ?? false
    )
}

async function startApp() {
  await getConfigAndSetState()

  const theme = createTheme((fullTheme as unknown) as ThemeOptions)

  ReactDOM.render(
    <BrowserRouter>
      <LangProvider>
        <PrismicProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <ThemeProviderStyled theme={theme}>
                <Route component={App} props />
              </ThemeProviderStyled>
            </ThemeProvider>
          </QueryClientProvider>
        </PrismicProvider>
      </LangProvider>
    </BrowserRouter>,
    document.getElementById('root')
  )
}

startApp().catch((err) => console.error(err))
