import React from 'react'
import styled from 'styled-components/macro'
import ThumbnailsWithIcon from '../ThumbnailsWithIcon'
import { contentItemTypeToIconType, IconTypes } from 'data/prismic-enums'
import Typography from 'components/atoms/Typography'
import { breakpoints } from 'utils/breakpoints'
import CircleButton from 'components/atoms/CircleButton'
import {
  ContentItemGalleryDocument,
  ContentItemImageDocument,
  ContentItemTextDocument,
  ContentItemVideoDocument,
} from '../../generated/types/eschertemplate.types'
import usePrismicData from '../../data/usePrismicData'
import { devices } from '../../utils/styles'
import useAppStateStore from '../../hooks/useAppStateStore'

interface IBottomBarProps {
  contentItem:
    | ContentItemImageDocument
    | ContentItemTextDocument
    | ContentItemGalleryDocument
    | ContentItemVideoDocument

  handleClick: (() => void) | undefined
  children?: React.ReactNode
  showInteractivePanel?: boolean
  desktopTextPositionLeft?: boolean
  hide?: boolean
}

const BottomBar = (props: IBottomBarProps) => {
  const { contentItem } = props
  const { updateState, openedModalType } = useAppStateStore()
  const { useIcons } = usePrismicData()
  const icons = useIcons()

  function openContentItemFullscreen() {
    updateState('openedModalType', contentItem.type)
    updateState('openedModalUid', contentItem.uid)
  }

  const { url = '', alt = '' } =
    icons.data?.data[contentItemTypeToIconType[contentItem.type]] ?? {}
  return (
    <>
      <BottomBarContainer
        $hide={props.hide}
        onClick={() => openContentItemFullscreen()}
      >
        <Wrapper
          $positionLeft={props.desktopTextPositionLeft}
          $subcontentFullScreen={!!openedModalType}
          data-cy={contentItem.type}
        >
          <ThumbnailsWithIcon
            thumbnail={contentItem.data.thumbnail_image?.url ?? ''}
            iconUrl={url}
            iconAlt={alt}
          />

          <TextContainer data-cy="bottomBarTextContainer">
            <TitleWrapper>
              <ElipsisTypography variant="interaction">
                {contentItem.data.title}
              </ElipsisTypography>
            </TitleWrapper>

            <Description variant="body">
              {contentItem.data.link_caption &&
              contentItem.data.link_caption.length > 0
                ? contentItem.data.link_caption
                : ''}
            </Description>
          </TextContainer>

          {!openedModalType && (
            <CircleButton
              data-cy="bottomBarCircleButton"
              style={{
                marginRight: 20,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <img
                src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
                alt="arrow"
                style={{ marginLeft: 3 }}
                loading="lazy"
                height={
                  icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.height ??
                  20
                }
                width={
                  icons.data?.data[IconTypes.RIGHT_ARROW]?.dimensions?.width ??
                  20
                }
              />
            </CircleButton>
          )}
          {props.children}
        </Wrapper>
      </BottomBarContainer>
    </>
  )
}

export default BottomBar

const TextContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  transition: 200ms;
  width: 60%;

  &:hover {
    transition: 400ms;
    padding-left: 10px;
  }
`

const ElipsisTypography = styled(Typography)`
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`

const Description = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: 'ellipsis';
`

const BottomBarContainer = styled.div<{ $hide?: boolean }>`
  opacity: ${({ $hide }) => ($hide ? '0' : '1')};
  transition: 100ms;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
`

const Wrapper = styled.div<{
  $positionLeft: boolean | undefined
  $subcontentFullScreen: boolean
}>`
  width: ${({ $subcontentFullScreen }) =>
    $subcontentFullScreen ? '100%' : breakpoints.desktop ? '40%' : '100%'};
  transition: 400ms cubic-bezier(0.09, 1.1, 0.63, 1.05);
  cursor: pointer;
  display: flex;
  margin-left: ${({ $positionLeft }) => ($positionLeft ? 'auto' : '0')};
  background-color: ${(p) => `${p.theme.palette.overlay}70`};
  height: 88px;
  border-top-right-radius: ${({ $positionLeft }) =>
    $positionLeft ? '0' : '8px'};
  border-top-left-radius: ${({ $positionLeft }) =>
    $positionLeft ? '8px' : '0'};
  @media ${devices.mobile} {
    width: 100%;
  }
  @media ${devices.mobile}, ${devices.tablet} {
    width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
