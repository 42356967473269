import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components/macro'
import Typography from '../../atoms/Typography'
import { Fade } from '@mui/material'
import Hypher from 'hypher'
import german from 'hyphenation.de'
import { devices } from '../../../utils/styles'
import usePrismicData from '../../../data/usePrismicData'

const h = new Hypher(german)

interface GlossaryRefs {
  [key: string]: React.RefObject<HTMLDivElement>
}

const Glossary = () => {
  const { useAllTooltips } = usePrismicData()
  const tooltips = useAllTooltips()
  const [selectedLetter, setSelectedLetter] = useState('')
  const glossaryRefs = useRef<GlossaryRefs>({})

  useEffect(() => {
    if (!tooltips.isLoading && selectedLetter) {
      const ref = glossaryRefs.current[selectedLetter]
      if (ref?.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [selectedLetter, tooltips.isLoading])

  const firstInstanceOfLetter: { [key: string]: boolean } = {}

  const sortedTooltips = tooltips.data
    // Filter out any tooltips without a title
    ?.filter((tooltip) => tooltip.data.title)
    // Sort them alphabetically
    .sort((a, b) => {
      const titleA = a.data.title?.toUpperCase() ?? ''
      const titleB = b.data.title?.toUpperCase() ?? ''
      return titleA.localeCompare(titleB)
    })
    // Add a flag to the first instance of each letter
    .map((tooltip) => {
      const firstLetter = tooltip.data.title?.[0].toUpperCase() ?? ''
      if (!firstInstanceOfLetter[firstLetter]) {
        firstInstanceOfLetter[firstLetter] = true
        // Allocate a ref for the first instance
        glossaryRefs.current[firstLetter] = React.createRef()
        return { ...tooltip, isFirst: true }
      }
      return { ...tooltip, isFirst: false }
    })

  const handleLetterClick = (letter: string) => {
    if (!tooltips.isLoading) {
      setSelectedLetter(letter)
    }
  }

  const allFirstLetters = tooltips.data?.map((tooltip) =>
    tooltip.data.title?.[0].toUpperCase()
  )

  const availableLetters = allFirstLetters?.filter(
    (letter, index, self) => letter && self.indexOf(letter) === index
  )

  return (
    <GlossaryWrapper>
      <AlphabetPicker>
        {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => (
          <Letter
            key={letter}
            isSelected={selectedLetter === letter}
            isDisabled={!availableLetters?.includes(letter)}
            onClick={() =>
              availableLetters?.includes(letter) && handleLetterClick(letter)
            }
          >
            <Typography fontWeight={600} variant={'head5'}>
              {letter}
            </Typography>
          </Letter>
        ))}
      </AlphabetPicker>
      <GlossaryContent>
        {sortedTooltips?.map((tooltip, index) => (
          <Fade in={true} timeout={500 + index * 200} key={tooltip.id}>
            <GlossaryEntry
              ref={
                tooltip.isFirst
                  ? glossaryRefs.current[
                      tooltip.data.title?.[0].toUpperCase() ?? ''
                    ]
                  : null
              }
              key={tooltip.id}
            >
              <StyledTitle variant={'head4'}>
                {h.hyphenateText(tooltip.data?.title ?? '')}
              </StyledTitle>
              <GlossaryDescription>
                <Typography variant={'body'}>
                  {tooltip.data?.description}
                </Typography>
              </GlossaryDescription>
            </GlossaryEntry>
          </Fade>
        ))}
      </GlossaryContent>
    </GlossaryWrapper>
  )
}

export default Glossary

const GlossaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const AlphabetPicker = styled.div`
  background-color: ${(p) => p.theme.palette.background};
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  flex-wrap: wrap;
  max-width: 90%;
  padding: 10px 0;
`
interface LetterProps {
  isSelected: boolean
  isDisabled: boolean
}

const Letter = styled.span<LetterProps>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 255, 255, 0.25)' : 'transparent'};
  border: solid 1px
    ${({ isSelected }) => (isSelected ? '#a4bfbb' : 'transparent')};
  color: ${({ isDisabled }) => (isDisabled ? '#555' : '#fff')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  border-radius: 50%;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    border-color: ${({ isDisabled }) =>
      isDisabled ? 'transparent' : '#a4bfbb'};
    transform: scale(1.05);
    transition: background-color 0.3s ease, border-color 0.3s ease,
      transform 0.3s ease;
  }
`

const GlossaryContent = styled.div`
  width: 90%;
  max-width: 1500px;
  margin: auto;
  min-height: 80vh;
  padding-bottom: 200px;
`

const GlossaryEntry = styled.div`
  background-color: rgba(164, 191, 187, 0.05);
  padding: 32px;
  margin-top: 24px;
  border-radius: 10px;
  border: solid 1px #a4bfbb;

  @media ${devices.desktop} {
    display: flex;
    justify-content: flex-end;
  }
`
const GlossaryDescription = styled.div`
  @media ${devices.desktop} {
    width: 80ch;
    padding: 0 64px;
  }
`

const StyledTitle = styled(Typography)`
  @media ${devices.desktop} {
    min-width: 200px;
    text-align: end;
  }
`
