import React from 'react'
import Typography from 'components/atoms/Typography'
import german from 'hyphenation.de'
import Hypher from 'hypher'
import styled from 'styled-components/macro'

const h = new Hypher(german)

interface SectionTitleProps {
  desktopTitle: string
  lead?: string
}

const SectionTitle = ({ desktopTitle, lead }: SectionTitleProps) => {
  return (
    <TitleContainer>
      {lead && (
        <StyledTypography variant="lead" data-cy="sectionLead">
          {h.hyphenateText(lead)}
        </StyledTypography>
      )}

      <StyledTypography variant="head2" data-cy="sectionTitle">
        {h.hyphenateText(desktopTitle)}
      </StyledTypography>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const StyledTypography = styled(Typography)`
  text-align: ${({ theme }) =>
    theme.templates?.section?.title?.textAlign ?? 'left'};
`

export default SectionTitle
