import React from 'react'
import styled from 'styled-components/macro'
import { breakpoints } from 'utils/breakpoints'
import Typography from 'components/atoms/Typography'
import {
  deviceOrientationIsAllowed,
  screenSizeIsAllowed,
} from './screenLock.service'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../data/prismic-helper'
import { appConfig } from '../../config'
import { devices } from '../../utils/styles'
import usePrismicData from '../../data/usePrismicData'
import LoadingSpinner from '../atoms/loadingSpinner'
import { zIndex } from '../../utils/zIndex'

const LockedScreen = () => {
  const { useUIelements } = usePrismicData()
  const uiElements = useUIelements()

  const staticText = getUiElementsSliceData(
    SliceTypeEnum.STATIC_UI_TEXTS,
    uiElements.data
  )

  const configuration = {
    phone: {
      orientationText:
        staticText?.primary.orientation_portrait_notice_text ?? '',
      image: uiElements.data?.data.rotate_screen_image_phone.url ?? '',
    },
    tablet: {
      orientationText:
        staticText?.primary.orientation_horizontal_notice_text ?? '',
      image: uiElements.data?.data.rotate_screen_image_tablet.url ?? '',
    },
  }
  const { orientationText, image } = breakpoints.phone
    ? configuration.phone
    : configuration.tablet

  const isVisible = () =>
    screenSizeIsAllowed(
      appConfig.restrictedScreens.sizes,
      window.innerHeight,
      window.innerWidth
    ) &&
    deviceOrientationIsAllowed(
      appConfig.restrictedScreens.devices,
      breakpoints,
      window.innerHeight,
      window.innerWidth
    )

  if (Object.keys(appConfig.restrictedScreens).length === 0 || isVisible()) {
    return null
  }

  if (uiElements.isLoading) {
    return <LoadingSpinner />
  }

  return (
    <StyledContainer>
      <div>
        <img src={image} alt="Turn device to portrait mode" />
      </div>

      <StyledTextContainer>
        <Typography variant="head3">
          {staticText?.primary.rotate_device}
        </Typography>

        <Typography variant="body" align="center">
          {orientationText}
        </Typography>
      </StyledTextContainer>
    </StyledContainer>
  )
}
export default LockedScreen

const StyledContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
  top: 0;
  z-index: ${zIndex.screenLock};
  align-items: center;
  white-space: pre;
  gap: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media ${devices.orientationLandscape.mobile} {
    flex-direction: row;
    padding-left: 50px;
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  white-space: break-spaces;
  align-items: center;
  max-width: 560px;
`
