import {
  ContentItemGalleryDocument,
  ContentItemImageDocument,
  ContentItemScrollymationDocument,
  ContentItemScrollystoryDocument,
  ContentItemTextDocument,
  ContentItemVideoDocument,
  SubchapterDocumentDataBodyDividerSlice,
  SubchapterDocumentDataBodyLinkToContentItemSlice,
  SubchapterDocumentDataBodyLinkToWebSlice,
  SubchapterDocumentDataBodyParagraphSlice,
  SubchapterDocumentDataBodyQuoteSlice,
} from '../generated/types/eschertemplate.types'

export type ContentItemTypes =
  | 'content_item_gallery'
  | 'content_item_image'
  | 'content_item_text'
  | 'content_item_video'
  | 'content_item_scrollymation'
  | 'content_item_scrollystory'

export type ContentItemResponseTypes =
  | ContentItemImageDocument
  | ContentItemTextDocument
  | ContentItemGalleryDocument
  | ContentItemVideoDocument
  | ContentItemScrollymationDocument
  | ContentItemScrollystoryDocument

export type ContentItemResponseTypesWithoutScrollymation = Exclude<
  ContentItemResponseTypes,
  ContentItemScrollymationDocument
>

export type ContentItemResponseTypesWithoutScrollymationAndScrollyStory = Exclude<
  ContentItemResponseTypesWithoutScrollymation,
  ContentItemScrollystoryDocument
>

export type SubchapterDocumentDataBodySlices =
  | SubchapterDocumentDataBodyDividerSlice
  | SubchapterDocumentDataBodyLinkToContentItemSlice
  | SubchapterDocumentDataBodyLinkToWebSlice
  | SubchapterDocumentDataBodyParagraphSlice
  | SubchapterDocumentDataBodyQuoteSlice

export interface ContentItemTypeToResponseTypeMap {
  content_item_gallery: ContentItemGalleryDocument
  content_item_image: ContentItemImageDocument
  content_item_text: ContentItemTextDocument
  content_item_video: ContentItemVideoDocument
  content_item_scrollymation: ContentItemScrollymationDocument
  content_item_scrollystory: ContentItemScrollystoryDocument
}

export enum LegalType {
  Imprint = 'Imprint',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  Sources = 'Sources',
}

export type LegalTypes = keyof typeof LegalType
