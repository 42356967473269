import styled from 'styled-components/macro'
import { zIndex } from '../../utils/zIndex'
import Typography from '../atoms/Typography'
import { appConfig } from '../../config'
import { useLang } from '../../providers/LanguageProvider'
import { Link, useLocation, useParams } from 'react-router-dom'
import { devices } from '../../utils/styles'
import { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import usePrismicData from '../../data/usePrismicData'
import {
  getUiElementsSliceData,
  SliceTypeEnum,
} from '../../data/prismic-helper'
import useAppStateStore from '../../hooks/useAppStateStore'

const NAV_BAR_HEIGHT = 80

const NavBar = () => {
  const { menuOpen, muted, updateState } = useAppStateStore()
  const { lang, setLang } = useLang()
  const location = useLocation()
  const { useUIelements, useIcons, useKnowledgebase } = usePrismicData()
  const { sectionUid } = useParams<{ sectionUid?: string }>()
  const { openedModalType } = useAppStateStore()

  const uiElements = useUIelements()
  const icons = useIcons()
  const knowledgebaseQuery = useKnowledgebase()

  // If sectionUid is set, we got a deeplink to a subchapter -> hide nav bar
  const [topPosition, setTopPosition] = useState(
    sectionUid ? -NAV_BAR_HEIGHT : 0
  )

  const [modalOpen, setModalOpen] = useState(false)

  const staticUiTexts = getUiElementsSliceData(
    SliceTypeEnum.STATIC_UI_TEXTS,
    uiElements.data
  )
  const isKnowledgeBase = location.pathname === '/knowledgebase'
  const nextLang = appConfig.languages.find(
    (language) => language.code !== lang
  )
  useEffect(() => {
    setTimeout(() => {
      setTopPosition(0)
    }, 1)
  }, [lang])

  useEffect(() => {
    if (menuOpen) {
      setTopPosition(0)
      return
    }

    let lastScrollPosition = window.scrollY

    const showOrHideNavBar = () => {
      const currentScrollPosition = window.scrollY

      // Determine if at the top to reset navbar position.
      const atTop = currentScrollPosition <= 0
      // Determine if at the bottom to fully hide the navbar.
      const atBottom =
        currentScrollPosition + window.innerHeight >= document.body.scrollHeight

      // Adjust top position based on the scroll direction.
      // If at top, reset position; if at bottom, fully hide.
      if (atTop) {
        setTopPosition(0)
      } else if (atBottom) {
        setTopPosition(-NAV_BAR_HEIGHT)
      } else {
        setTopPosition((prevTopPosition) => {
          // Calculate difference between the current scroll position and the last scroll position.
          const scrollDifference = currentScrollPosition - lastScrollPosition

          // Calculate new position by subtracting the scroll difference from the previous top position.
          // This calculation moves the navbar in relation to the scroll direction.
          let newTopPosition = prevTopPosition - scrollDifference

          // Constrain the new position between 0 and -NAV_BAR_HEIGHT to prevent the navbar from moving too far in either direction.
          newTopPosition = Math.max(
            -NAV_BAR_HEIGHT,
            Math.min(newTopPosition, 0)
          )

          return newTopPosition
        })
      }

      // Update the last scroll position for the next event.
      lastScrollPosition = currentScrollPosition
    }

    window.addEventListener('scroll', showOrHideNavBar)
    return () => window.removeEventListener('scroll', showOrHideNavBar)
  }, [menuOpen])

  const switchLanguage = () => nextLang?.code && setLang(nextLang.code)
  const toggleOpenModal = () => setModalOpen((prev) => !prev)

  if (openedModalType) return null

  const showMobileSettings =
    appConfig.globalVolume || appConfig.languages.length > 1

  return (
    <TopBarWrapper $topPosition={topPosition} data-cy="tobBarWrapper">
      <Modal open={modalOpen} onClose={toggleOpenModal}>
        <ModalContainer>
          <Typography
            onClick={() => updateState('muted', !muted)}
            variant={'body'}
            textAlign={'center'}
          >
            {staticUiTexts?.primary?.sound_state_text}{' '}
            <strong>{muted ? 'OFF' : 'ON'}</strong>
          </Typography>{' '}
          {appConfig.languages.length > 1 && (
            <Typography
              onClick={switchLanguage}
              variant={'body'}
              textAlign={'center'}
            >
              {staticUiTexts?.primary?.language_text}{' '}
              <strong>{nextLang?.name.toUpperCase()}</strong>
            </Typography>
          )}
        </ModalContainer>
      </Modal>
      <Link to="/intro" onClick={() => updateState('menuOpen', false)}>
        <LogoContainer>
          <img
            src={uiElements.data?.data.videobook_logo?.url ?? ''}
            data-cy="vbLogo"
            aria-label="Intro Link"
            width={
              uiElements.data?.data.videobook_logo?.dimensions?.width ?? 80
            }
            height={
              uiElements.data?.data.videobook_logo?.dimensions?.height ?? 80
            }
            alt={uiElements.data?.data.videobook_logo?.alt ?? 'Logo'}
          />{' '}
        </LogoContainer>
      </Link>

      <ControlsContainer>
        {appConfig.globalVolume && (
          <ElementContainer onClick={() => updateState('muted', !muted)}>
            <img
              src={
                muted
                  ? icons.data?.data.mutedVolume?.url ?? ''
                  : icons.data?.data.volume?.url ?? ''
              }
              alt={
                muted
                  ? icons.data?.data.mutedVolume?.alt ?? 'muted-icon'
                  : icons.data?.data.volume?.alt ?? 'sound-icon'
              }
              width={
                muted
                  ? icons.data?.data.mutedVolume?.dimensions?.width ?? 16
                  : icons.data?.data.volume?.dimensions?.width ?? 16
              }
              height={
                muted
                  ? icons.data?.data.mutedVolume?.dimensions?.height ?? 16
                  : icons.data?.data.volume?.dimensions?.height ?? 16
              }
            />
          </ElementContainer>
        )}

        {appConfig.languages.length > 1 && (
          <ElementContainer onClick={switchLanguage}>
            <img
              src={icons.data?.data.language?.url ?? ''}
              alt={icons.data?.data.language.alt ?? 'lang-icon'}
              width={icons.data?.data.language?.dimensions?.width ?? 16}
              height={icons.data?.data.language?.dimensions?.height ?? 16}
            />
            <Typography variant={'interaction'}>
              {nextLang?.name.substring(0, 2).toUpperCase()}
            </Typography>
          </ElementContainer>
        )}
        {appConfig.allowKnowledgeBase && (
          <StyledLink
            to={isKnowledgeBase ? '/chapter' : '/knowledgebase'}
            onClick={() => updateState('menuOpen', false)}
            $isKnowledgebase={isKnowledgeBase}
            data-cy={isKnowledgeBase ? 'backToVb' : 'knowledgeBase'}
          >
            <img
              width={icons.data?.data.knowledge?.dimensions?.width ?? 16}
              height={icons.data?.data.knowledge?.dimensions?.height ?? 16}
              src={icons.data?.data.knowledge.url ?? ''}
              alt={icons.data?.data.knowledge.alt ?? 'knowledgebase-icon'}
            />
            <Typography variant={'interaction'}>
              {knowledgebaseQuery.data?.data.button_text_to_main}
            </Typography>
            {isKnowledgeBase ? (
              <img
                width={icons.data?.data.close?.dimensions?.width ?? 16}
                height={icons.data?.data.close?.dimensions?.height ?? 16}
                src={icons.data?.data.close.url ?? ''}
                alt={icons.data?.data.close.url ?? 'close-kb'}
              />
            ) : (
              <div
                style={{
                  height: 12,
                  width: icons.data?.data.close?.dimensions?.width,
                }}
              ></div>
            )}
          </StyledLink>
        )}
        {showMobileSettings && (
          <MobileSettingsContainer
            onClick={toggleOpenModal}
            $modalOpen={modalOpen}
          >
            <img
              src={icons.data?.data?.settings?.url ?? ''}
              alt={icons.data?.data?.settings?.alt ?? 'open-modal'}
              height={icons.data?.data.settings?.dimensions?.height ?? 16}
              width={icons.data?.data.settings?.dimensions?.width ?? 16}
            />
          </MobileSettingsContainer>
        )}
        <MenuContainer
          onClick={() => {
            setModalOpen(false)
            updateState('menuOpen', !menuOpen)
          }}
          data-cy="menuButton"
        >
          <img
            width={24}
            height={
              menuOpen
                ? icons.data?.data.close?.dimensions?.height ?? 24
                : icons.data?.data.menu?.dimensions?.height ?? 24
            }
            src={
              menuOpen
                ? icons.data?.data.close?.url ?? ''
                : icons.data?.data.menu?.url ?? ''
            }
            alt={
              menuOpen
                ? icons.data?.data.close?.alt ?? 'close-icon'
                : icons.data?.data.menu?.alt ?? 'menu-icon'
            }
          />
        </MenuContainer>
      </ControlsContainer>
    </TopBarWrapper>
  )
}

export default NavBar

const TopBarWrapper = styled.div<{ $topPosition: number }>`
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${NAV_BAR_HEIGHT}px;
  top: ${(p) => p.$topPosition}px;
  left: 0;
  bottom: auto;
  background-color: ${(p) => p.theme.palette.black(0.65)};
  backdrop-filter: blur(8px);
  z-index: ${zIndex.burger};

  @media ${devices.mobile} {
    bottom: 0;
    top: unset;
    height: 60px;
  }
`

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled(FlexCenter)`
  height: ${NAV_BAR_HEIGHT}px;

  @media ${devices.mobile} {
    height: 60px;
  }

  img {
    width: auto;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }
`

const ControlsContainer = styled(FlexCenter)`
  height: 100%;
  cursor: pointer;
`

const MenuContainer = styled(FlexCenter)`
  width: ${NAV_BAR_HEIGHT}px;
  height: 100%;
  background-color: ${(p) => p.theme.palette.black()};

  img {
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
  }

  &:hover img {
    transform: scale(1.1);
  }

  @media ${devices.mobile} {
    width: 60px;
  }
`
const ElementContainer = styled(FlexCenter)`
  height: 100%;
  padding: 0 32px;
  gap: 12px;
  border-left: 1px solid ${(p) => p.theme.palette.white(0.2)};
  transition: background-color 0.2s ease-in-out;

  &:nth-child(1) {
    border-left: transparent;
  }

  &:hover {
    background-color: ${(p) => p.theme.palette.white(0.2)};
  }

  @media ${devices.mobile} {
    display: none;
  }
`

const StyledLink = styled(Link)<{ $isKnowledgebase: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding: 0 32px;
  gap: 12px;
  border-left: 1px solid ${(p) => p.theme.palette.white(0.2)};
  transition: background-color 0.2s ease-in-out;
  background-color: ${(p) => p.$isKnowledgebase && p.theme.palette.contrast};

  &:hover {
    background-color: ${(p) => p.theme.palette.white(0.2)};
  }

  @media ${devices.mobile} {
    display: none;
  }
`

const MobileSettingsContainer = styled(FlexCenter)<{ $modalOpen: boolean }>`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
  border-left: 1px solid ${(p) => p.theme.palette.white(0.2)};
  height: 100%;
  background-color: ${(p) =>
    p.$modalOpen ? p.theme.palette.contrast : 'transparent'};

  @media ${devices.mobile} {
    display: flex;
  }
`

const ModalContainer = styled.div`
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: ${NAV_BAR_HEIGHT}px;
  transform: translateX(-50%);
  width: 80%;
  height: 200px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.palette.overlay};
  border: transparent;
  gap: 24px;

  &:focus {
    outline: none;
  }
`
