import Typography from 'components/atoms/Typography'
import styled from 'styled-components/macro'
import { devices } from 'utils/styles'
import { detailAreaWidth } from './ModalDetailArea'
import { IconTypes } from 'data/prismic-enums'
import usePrismicData from '../../../data/usePrismicData'
import useAppStateStore from '../../../hooks/useAppStateStore'

interface ButtonBarProps {
  hideDescriptionAndDetails?: boolean
  title: string
  onClose: () => void
}

const ButtonBar = (props: ButtonBarProps) => {
  const { useIcons } = usePrismicData()
  const icons = useIcons()
  const { updateState, detailsOpen } = useAppStateStore()

  const onDetails = () => {
    updateState('detailsOpen', !detailsOpen)
  }

  return (
    <Container>
      <OverlayIcon onClick={props.onClose}>
        <img
          src={icons.data?.data[IconTypes.CONTROL_ARROW_LEFT]?.url ?? ''}
          alt="back"
          data-cy="buttonBarBackButton"
        />
      </OverlayIcon>

      {!props.hideDescriptionAndDetails && (
        <RightButtonsContainer>
          <ModalDescription>
            <Typography data-cy="modalDescription" variant="interaction">
              {props.title}
            </Typography>
          </ModalDescription>
          <OpenDetailsIcon>
            <OverlayIcon onClick={onDetails}>
              <img
                src={icons.data?.data[IconTypes.DOUBLE_ARROW_LEFT]?.url ?? ''}
                alt="open"
                data-cy="buttonBarOpenModalDetailsButton"
              />
            </OverlayIcon>
          </OpenDetailsIcon>
        </RightButtonsContainer>
      )}
    </Container>
  )
}

export default ButtonBar

const Container = styled.div`
  position: absolute;
  top: 0px;
  left:0px;
  width: 100%;
  transition: width 0.2s ease-in-out;

  .details-open & {
    transition: width 0.2s ease-in-out;
    width: calc(100% - ${detailAreaWidth});

    @media ${devices.mobile} {
      width: 100%;
    },
  }


  padding: 32px;
  z-index: 1001;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${devices.mobile} {
    height: 72px;
    padding: 16px;
  },
`

export const OverlayIcon = styled.div`
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.overlay};

  &:hover {
    transform: scale(1.1);
  }

  z-index: 2;
  width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 50%;
  img {
    width: 48px;
    height: 48px;
  }

  @media ${devices.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
    }
  }
`

const OpenDetailsIcon = styled.div`
    transition: transform 0.2s ease-in-out;
  
  .details-open & {
    transition: transform 0.2s ease-in-out;
    transform: rotate(180deg);
  }

  @media ${devices.mobile} {
    display:none;
  },
`

export const ModalDescription = styled.div`
  z-index: 2;
  background-color: ${(p) => p.theme.palette.overlay};
  padding: 13px 32px;
  border-radius: 24px;
  margin-right: 13px;
  height: 48px;
  display: flex;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

  .details-open & {
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  > * {
    max-width: 350px !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  @media ${devices.mobile} {
    display:none;
  },
`

const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
