import * as React from 'react'
import { useEffect, useRef } from 'react'
import usePrismicData from '../../data/usePrismicData'
import SectionStickyBackground from './SectionStickyBackground'
import SectionTextContent from './SectionTextContent'
import styled from 'styled-components/macro'
import useInterpolatedScrollPosition from '../../hooks/useInterpolatedScrollPosition'
import { InView } from 'react-intersection-observer'
import { useHistory, useParams } from 'react-router-dom'
import { appConfig } from '../../config'
import { SubchapterDocument } from '../../generated/types/eschertemplate.types'
import { devices } from '../../utils/styles'
import { useSubchapterScrollPosition } from '../../hooks/useSubchapterScrollPosition'
import useAppStateStore from '../../hooks/useAppStateStore'

interface SubchaperProps {
  subchapter: SubchapterDocument
  chapterUid: string
  subchapterLoadedCallback: (index: number) => void
  subchapterPosition: number
  chapterLength: number
}

const Subchapter = ({
  subchapter,
  chapterUid,
  subchapterPosition,
  chapterLength,
  subchapterLoadedCallback,
}: SubchaperProps) => {
  const subchapterWrapperRef = useRef<HTMLDivElement>(null)
  const { useContentItem, useSubchapter } = usePrismicData()
  const subchapterQuery = useSubchapter(subchapter.uid)
  const history = useHistory()
  const { sectionUid } = useParams<{
    sectionUid?: string
    legalSection?: string
  }>()
  const { setPosition } = useSubchapterScrollPosition()
  const { openedModalType } = useAppStateStore()

  /**
   * We are using here subchapter data.bg_main fetched in Chapter with fetchLinks!
   * This way its a bit faster,because we dont need to wait for subchapterQuery to load to get contentItem ID
   */
  //TODO: BUT TYPING IS TRICKY :/
  const subchapterContentItem = useContentItem(
    // @ts-ignore
    subchapter.data?.bg_main.type,
    // @ts-ignore
    subchapter.data?.bg_main.uid
  )

  useEffect(() => {
    if (!subchapterContentItem.isLoading) {
      subchapterLoadedCallback(subchapterPosition)
    }

    if (subchapter.uid === sectionUid && !subchapterContentItem.isLoading) {
      subchapterWrapperRef.current?.scrollIntoView()
    }

    if (subchapterWrapperRef.current) {
      subchapterWrapperRef.current.setAttribute(
        'data-cy',
        `subchapter-${subchapter.uid}`
      )
    }
    // Comment out, this completely breaks intended behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subchapterWrapperRef.current, subchapterContentItem.isLoading])

  const isScrollymationSection =
    subchapterContentItem.data?.type === 'content_item_scrollymation'

  const isScrollystorySection =
    subchapterContentItem.data?.type === 'content_item_scrollystory'

  const interpolatedScrollPosition = useInterpolatedScrollPosition(
    subchapterWrapperRef
  )

  useEffect(() => {
    setPosition(subchapterPosition, interpolatedScrollPosition, chapterLength)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpolatedScrollPosition])

  const handleInView = (inView: boolean) => {
    // if is in view and neither content item modal nor legal section is open, replace URL
    // this prevent URL from changing (deleting query params) when modal is open
    // and makes sure that URL stays same even after page refresh or direct link to subchapter content/legal section
    if (inView && !openedModalType) {
      const newUrl = `/${appConfig.contentUnitUrl}/${chapterUid}/${subchapter.uid}`
      history.replace(newUrl)
    }
  }
  const heightOfSectionMobile =
    subchapterContentItem.data?.data?.height_of_section_mobile ?? 1
  const heightOfSection =
    subchapterContentItem.data?.data?.height_of_section ?? 1

  return (
    <SubchapterWrapper
      $heightOfSectionMobile={heightOfSectionMobile ?? 1}
      $heightOfSection={heightOfSection ?? 1}
      id={subchapter.id}
      ref={subchapterWrapperRef}
    >
      <InView
        as="div"
        onChange={handleInView}
        threshold={0.5}
        // has to be styled here, because of the way InView works
        // without height, URL is set to following subchapter, probably because of hydration/layout shift during rendering?
        style={{
          height: '100px',
          width: '100%',
          backgroundColor: 'transparent',
          position: 'absolute',
          top: '0',
        }}
      />
      {subchapterContentItem.isLoading ? (
        <div>Loading</div>
      ) : (
        <>
          <SectionStickyBackground
            subchapterQuery={subchapterQuery}
            contentItem={subchapterContentItem.data}
            interpolatedScrollPosition={interpolatedScrollPosition}
          />
          {!isScrollymationSection && !isScrollystorySection && (
            <SectionTextContent
              subchapterQuery={subchapterQuery}
              contentItem={subchapterContentItem.data}
            />
          )}
        </>
      )}
    </SubchapterWrapper>
  )
}

export default Subchapter

interface SubchapterWrapperProps {
  $heightOfSectionMobile: number
  $heightOfSection: number
}
const SubchapterWrapper = styled.div<SubchapterWrapperProps>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background: ${(p) => p.theme.palette.background};
  min-height: ${({ $heightOfSection }) => $heightOfSection * 100}vh;

  @media ${devices.mobile} {
    min-height: ${({ $heightOfSectionMobile }) =>
      $heightOfSectionMobile * 100}vh;
  }
`
