import React from 'react'
import InteractiveBackground from 'components/Subcontent/InteractiveBackground'
import { ImageContainer } from './ImageContainer'
import { ContentItemTextDocument } from '../../../generated/types/eschertemplate.types'
import BottomBar from '../../Subcontent/BottomBar'
import useAppStateStore from '../../../hooks/useAppStateStore'
import { getResponsiveImage } from '../../../utils/imageUtils'

interface TextBackgroundProps {
  contentItemText: ContentItemTextDocument
  isTextPositionLeft?: boolean
}

const TextBackground = ({
  contentItemText,
  isTextPositionLeft,
}: TextBackgroundProps) => {
  const { updateState } = useAppStateStore()

  const activateFullScreen = () => {
    updateState('openedModalUid', contentItemText.uid)
    updateState('openedModalType', contentItemText.type)
  }

  return (
    <InteractiveBackground
      activateCallback={activateFullScreen}
      showBackgroundOverlay={true}
    >
      <ImageContainer
        img={getResponsiveImage(contentItemText.data.background_image)}
      ></ImageContainer>
      <BottomBar
        contentItem={contentItemText}
        handleClick={activateFullScreen}
        desktopTextPositionLeft={isTextPositionLeft}
      />
    </InteractiveBackground>
  )
}

export default TextBackground
