import styled from 'styled-components/macro'
import { useRef, useState } from 'react'
import Typography from 'components/atoms/Typography'
import DetailArea, { detailAreaWidth } from './components/ModalDetailArea'
import { devices } from 'utils/styles'
import { ModalMainArea } from './components/ModalMainArea'
import { IconTypes } from '../../data/prismic-enums'
import ButtonBar, {
  ModalDescription,
  OverlayIcon,
} from './components/ModalButtons'
import usePrismicData from '../../data/usePrismicData'
import { ContentItemGalleryDocument } from '../../generated/types/eschertemplate.types'
import ImageCarousel from '../ImageCarousel'

interface GalleryProps {
  contentItem: ContentItemGalleryDocument
  onClose: () => void
}

const GalleryModal = ({ contentItem, onClose }: GalleryProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const modalMainRef = useRef<HTMLDivElement>(null)

  const { useIcons } = usePrismicData()
  const icons = useIcons()

  const setNewIndex = (newIndex: number) => {
    const imageCount = contentItem.data.body[0]?.items.length ?? 0

    newIndex = ((newIndex % imageCount) + imageCount) % imageCount

    setActiveIndex(newIndex)
  }

  const imageCount = contentItem.data.body[0]?.items.length ?? 0
  const index = Math.abs(activeIndex) % imageCount

  const getImageCounter = (): string => {
    return `${index + 1} / ${imageCount}`
  }
  const currentImage = contentItem.data.body[0]?.items[index]
  const itemsLength = contentItem.data.body[0]?.items?.length ?? 0

  return (
    <ModalMainArea variant="row">
      <div style={{ height: 1 }} ref={modalMainRef}></div>
      <ButtonBar
        title={currentImage?.title ?? ''}
        onClose={onClose}
      ></ButtonBar>
      <ImageCarousel
        images={
          (contentItem.data.body[0]?.items
            .map((item) => item.image.url)
            .filter(
              (galleryImages) => typeof galleryImages === 'string'
            ) as string[]) ?? []
        }
        activeIndex={activeIndex}
        setIndex={setNewIndex}
      />
      <DetailArea
        modalMainRef={modalMainRef}
        leadText={currentImage?.lead_text ?? ''}
        title={currentImage?.title ?? ''}
        subtitle={currentImage?.subtitle ?? ''}
        paragraphs={currentImage?.paragraph ?? []}
        caption={currentImage?.caption ?? ''}
      ></DetailArea>

      <GalleryButtonContainer>
        <GalleryButton
          data-cy="gallerySwipeLeftButton"
          onClick={() => activeIndex > 0 && setNewIndex(activeIndex - 1)}
          $isActive={activeIndex > 0}
        >
          <GalleryOverlayIcon>
            <img
              className={'left'}
              src={icons.data?.data[IconTypes.LEFT_ARROW]?.url ?? ''}
              alt="back"
            />
          </GalleryOverlayIcon>
        </GalleryButton>

        <GalleryButton
          data-cy="gallerySwipeRightButton"
          onClick={() =>
            activeIndex < itemsLength - 1 && setNewIndex(activeIndex + 1)
          }
          $isActive={activeIndex < itemsLength - 1}
        >
          <GalleryOverlayIcon>
            <img
              className={'right'}
              src={icons.data?.data[IconTypes.RIGHT_ARROW]?.url ?? ''}
              alt="back"
            />
          </GalleryOverlayIcon>
        </GalleryButton>
      </GalleryButtonContainer>

      <ImageCounter>
        <ModalDescription>
          <Typography variant="body">{getImageCounter()}</Typography>
        </ModalDescription>
      </ImageCounter>
    </ModalMainArea>
  )
}

const GalleryButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: 0px 32px;
  top: calc(50% - (48px / 2));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: padding-right 0.2s ease-in-out;

  @media ${devices.mobile} {
    top: 0px;
    justify-content: flex-end;
    padding: 16px;
    right: 0;
    z-index: 1002;
    width: auto;
  }
  ,
  .details-open & {
    transition: padding-right 0.2s ease-in-out;
    padding-right: calc(32px + ${detailAreaWidth});
    @media ${devices.mobile} {
      padding-right: 16px;
    }
  }
`

const GalleryButton = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  z-index: 2;
  opacity: ${(props) => (props.$isActive ? 1 : 0.5)};
  pointer-events: ${(props) => (props.$isActive ? 'auto' : 'none')};

  @media ${devices.mobile} {
    margin-left: 8px;
  }
`

const ImageCounter = styled.div`
  position: absolute;
  left: 32px;
  bottom: 46px;
`

const GalleryOverlayIcon = styled(OverlayIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
  }
  img.left {
    padding-right: 6px;
  }
  img.right {
    padding-left: 6px;
  }
`

export default GalleryModal
