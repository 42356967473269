import styled from 'styled-components/macro'
import { replaceFilename } from '../../../utils/string'
import React from 'react'
import VideoPlayer from '../../video/VideoPlayer'
import { breakpoints } from '../../../utils/breakpoints'
import { kenBurnsZoomAnimation } from '../../../utils/imageAnimations'
import ImageLoader from '../../common/imageLoader'
import { ImageField, LinkField } from '@prismicio/types'
import { getLinkFieldUrl } from '../../../data/prismic-helper'

export interface Props {
  coverBackgroundVideoMobile: string | undefined
  coverBackgroundVideoDesktop: string | undefined
  coverBackgroundImage: LinkField | undefined
  isMobile: boolean
  thumbnail: ImageField | undefined
  imageEffectType?: string
}

export const CoverBackground = ({
  isMobile,
  coverBackgroundVideoMobile = '',
  coverBackgroundVideoDesktop = '',
  coverBackgroundImage,
  thumbnail,
  imageEffectType,
}: Props) => {
  let background: JSX.Element | null = null
  let imgBackground: JSX.Element | null = null
  let videoSource = ''

  function getImageSourceCover(
    video: string | undefined,
    thumbnailImg: string | null | undefined,
    coverBGImage: string
  ): string {
    // If there is an actual cover background image it got the highest priority
    if (coverBGImage !== '') {
      return coverBGImage
    }

    // If there is a video setup first frame of video
    if (video !== '') {
      return replaceFilename(videoSource, 'firstframe.jpg')
    }

    // return thumbnail image as a fallback
    return thumbnailImg ?? ''
  }

  //select video source based on screen size
  videoSource = isMobile
    ? coverBackgroundVideoMobile
    : coverBackgroundVideoDesktop

  const imgSource = getImageSourceCover(
    videoSource,
    thumbnail?.url,
    getLinkFieldUrl(coverBackgroundImage)
  )
  imgBackground = (
    <BackgroundImage
      src={imgSource}
      $imageEffectType={imageEffectType}
      size={'xl'}
      loading={'eager'}
      alt={thumbnail?.alt ?? 'cover-image'}
    />
  )

  const videoUrl = breakpoints.phone
    ? coverBackgroundVideoMobile
    : coverBackgroundVideoDesktop

  const videoElem = (
    <VideoPlayer
      url={videoUrl}
      alt={'cover-video'}
      playing={true}
      objectFit={'cover'}
      loop={true}
      hideControls={true}
    />
  )

  if (videoSource) {
    background = videoElem
  }

  return (
    <>
      <BackgroundImageWrapper>{imgBackground}</BackgroundImageWrapper>
      {background}
    </>
  )
}

const BackgroundImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
`

const BackgroundImage = styled(ImageLoader)<{
  $imageEffectType?: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  ${({ $imageEffectType }) => kenBurnsZoomAnimation($imageEffectType)};
`
