import { giveColor } from 'utils/styles'

export const white = (opacity?: number) => giveColor(255, 255, 255, opacity)
export const black = (opacity?: number) => giveColor(0, 0, 0, opacity)
const primary = {
  main: '#ffffff',
  dark: 'rgba(255, 255, 255, 0.7)',
  light: 'rgba(255, 255, 255, 0.3)',
}
const secondary = {
  main: 'rgb(185, 31, 31)',
  dark: 'rgba(185, 31, 31, 0.7)',
  light: 'rgba(185, 31, 31, 0.3)',
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    head1: React.CSSProperties
    head2: React.CSSProperties
    head3: React.CSSProperties
    head4: React.CSSProperties
    head5: React.CSSProperties
    body: React.CSSProperties
    lead: React.CSSProperties
    quote: React.CSSProperties
    interaction: React.CSSProperties
  }
}

const emptyCSSObject: React.CSSProperties = {}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    head1: true
    head2: true
    head3: true
    head4: true
    head5: true
    body: true
    lead: true
    quote: true
    interaction: true
    caption: true
    // disabling default variants
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    overline: false
    button: false
  }
}

export const defaultTemplateConfig = {
  section: {
    title: {
      textAlign: 'left',
    },
  },
  cover: {
    desktop: {
      overlay: 'transparent',
    },
    phone: {
      overlay: 'transparent',
    },
  },
  button: {
    radius: '2px',
  },
  intro: {
    titleColor: 'white',
  },
}

export type TemplateThemeConfig = typeof defaultTemplateConfig

export const defaultTheme = {
  constants: {
    sizes: {
      desktop: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      tablet: {
        topBarHeight: 80,
        sideBarWidth: 80,
        bottomBarHeight: 80,
        standardButtonSize: 80,
      },
      phone: {
        topBarHeight: 50,
        sideBarWidth: 15,
        bottomBarHeight: 46,
        standardButtonSize: 46,
        menuButtonSize: 54,
      },
    },
  },
  typography: {
    head1: emptyCSSObject,
    head2: emptyCSSObject,
    head3: emptyCSSObject,
    head4: emptyCSSObject,
    head5: emptyCSSObject,
    quote: emptyCSSObject,
    lead: emptyCSSObject,
    body: emptyCSSObject,
    interaction: emptyCSSObject,
    caption: emptyCSSObject,
  },
  typographyRichTextPaddings: {
    head1: emptyCSSObject,
    head2: emptyCSSObject,
    head3: emptyCSSObject,
    head4: emptyCSSObject,
    head5: emptyCSSObject,
    body: emptyCSSObject,
  },
  // Semantic mappings for Typography
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          head1: 'h1',
          head2: 'h2',
          head3: 'h3',
          head4: 'h4',
          head5: 'h5',
          quote: 'p',
          lead: 'p',
          body: 'p',
          interaction: 'p',
        },
      },
    },
  },
  palette: {
    white: white,
    black: black,
    primary,
    secondary,
    background: '',
    overlay: '',
    contrast: '',
  },
  templates: defaultTemplateConfig,
}

export type ThemeType = Omit<typeof defaultTheme, 'components'>
