import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { ContentItemVideoDocument } from '../../../generated/types/eschertemplate.types'
import { FilledLinkToWebField } from '@prismicio/types'
import VideoPlayer from '../../video/VideoPlayer'
import { breakpoints } from '../../../utils/breakpoints'

interface VideoBackgroundProps {
  contentItemVideo: ContentItemVideoDocument
}

const VideoBackgroundInline = ({ contentItemVideo }: VideoBackgroundProps) => {
  const videoUrl = breakpoints.phone
    ? (contentItemVideo.data.mobile_url_video as FilledLinkToWebField).url
    : (contentItemVideo.data.desktop_url_video as FilledLinkToWebField).url

  const [inView, setInView] = useState(false)

  return (
    <InView threshold={0.3} onChange={(view) => setInView(view)}>
      <VideoPlayer
        loop
        url={videoUrl}
        alt={`${contentItemVideo.data.title}-video`}
        playing={inView}
        objectFit={'cover'}
      />
    </InView>
  )
}

export default VideoBackgroundInline
