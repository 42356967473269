export const getFirstFrameUrl = (videoUrl?: string): string => {
  if (!videoUrl) return ''
  return getImageUrl(videoUrl, 'firstframe.jpg')
}

const getImageUrl = (videoUrl: string, imageUrl: string): string => {
  const parsedVideoUrl = new URL(videoUrl)
  const pathnameElements = parsedVideoUrl.pathname.split('/')
  const newPathElements = parsedVideoUrl.pathname
    .split('/')
    .splice(0, pathnameElements.length - 1)
  newPathElements.push(imageUrl)
  const newPath = newPathElements.join('/')
  parsedVideoUrl.pathname = newPath
  return parsedVideoUrl.href
}
