import React from 'react'
import SubchapterMenuSection from './SubchapterMenuSection'
import {
  ChapterDocument,
  UiElementsDocumentDataBodyMenuItemsSlice,
} from '../../generated/types/eschertemplate.types'
import styled from 'styled-components/macro'
import { devices } from '../../utils/styles'

interface IMenuSectionProps {
  chapter: ChapterDocument
  subchaptersElement?: React.RefObject<HTMLDivElement>
  menuItems?: UiElementsDocumentDataBodyMenuItemsSlice
}

const MenuSection = ({
  chapter,
  subchaptersElement,
  menuItems,
}: IMenuSectionProps) => {
  if (!chapter || !chapter.data.subchapters.length) {
    return null
  }

  //TODO: remove ts-ignore, some PRISMIC BS...
  // @ts-ignore
  const sections = chapter.data.subchapters.map(
    // @ts-ignore
    (subchapter) =>
      subchapter !== undefined && (
        <SubchapterMenuSection
          key={subchapter.subchapter.uid}
          subchapter={subchapter}
          chapterUid={chapter.uid}
          noImageFallback={menuItems?.primary.placeholder_image.url ?? ''}
        />
      )
  )

  return (
    <ChapterColumn data-cy="MenuSectionKapitelColumn" key={chapter.id}>
      <SubchaptersRef ref={subchaptersElement} />
      {sections}
    </ChapterColumn>
  )
}

export default MenuSection

const ChapterColumn = styled.div`
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  padding-left: 32px;
  margin-right: 8px;
  padding-bottom: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${devices.desktop} {
    min-width: 400px;
    max-width: 70%;
  }

  @media ${devices.tablet} {
    padding-left: 40px;
    border-left: none;
    max-width: calc(100% - 363px);
  }

  @media ${devices.mobile} {
    padding-left: 20px;
    border-left: none;
    padding-right: 20px;
    margin-right: 0;
  }
`

const SubchaptersRef = styled.div`
  @media ${devices.mobile} {
    position: absolute;
    top: -75px; // Adjust based on your header's height
  }
`
